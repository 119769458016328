<template>
  <VRow>
    <VCol
      cols="12"
      md="7"
    >
      <app-card class="pa-4 mt-2" elevation="2">
        <div class="text-subtitle-1 font-weight-negrita">
          Afiliados
        </div>
        <component
          :is="form.columns[0].type"
          :form="form.columns[0]"
          validate
          click-row
          @search="secondFormData=false"
          @clickRow="handleClickRow"
        />
      </app-card>
      <app-card v-if="simuladorData && simuladorData.length" class="pa-6 mt-4" elevation="2">
        <VRow>
          <VCol
            cols="12"
            md="11"
            lg="9"
            xl="8"
          >
            <div class="text-subtitle-1 font-weight-negrita">
              {{ form.columns[2].title }}
            </div>
            <component
              :is="form.columns[2].type"
              :items-per-page="12"
              :form="tableMapper(form.columns[2])"
              :items="simuladorData"
            />
          </VCol>
        </VRow>
      </app-card>
    </VCol>
    <VCol
      cols="12"
      md="5"
      xl="4"
      class="p-relative"
    >
      <app-card class="pa-2 pt-4 mt-2" :color="secondFormData?'white':'transparent'" :elevation="secondFormData?2:0">
        <custom-loading :loading="loading" absolute opacity="0.1" />
        <component
          :is="form.columns[1].type"
          v-show="!loading"
          :form="form.columns[1]"
          validate
          :form-data="secondFormData"
          @changeAmount="handleChangeAmount"
        />
      </app-card>
    </VCol>
  </VRow>
</template>

<script>
import tableMapper from '@/mappers/tables';
import { getConstant } from '@/util/constantsHelpers';
export default {
	name: 'LayoutCasasComerciales',
	components: {

	},
	props: {
		form: {
			type: Object,
			default: function () {
				return { headers: [] };
			}
		}
	},

	data () {
		return {
			tableMapper,
			loading:false,
			secondFormData:false,
			chargeMethodTypes:[],
			simuladorData:[]
		};
	},
	computed: {

	},
	async mounted () {

		const resources = getConstant({field:{ 'charge-methods-types' : null },service:'afiliaciones'});
		this.chargeMethodTypes=resources;
	},

	methods: {
		async handleClickRow (data) {
			this.loading=true;
			this.secondFormData={...data,chargeMethodTypesList:this.chargeMethodTypes};
			this.loading=false;
		},
		async handleChangeAmount(data){
			this.loading=true;
			if(data){
				this.simuladorData=data;
			}else{
				this.simuladorData=[];
			}
			
			this.loading=false;
		}
	}
};
</script>

<style lang="scss">
    .no-icon{
        .v-stepper__step__step{
            display: none !important;
        }
    }
</style>
