<template>
  <svg
    :width="width"
    viewBox="0 0 153 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.68471"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.6933 0H10.1345C4.53735 0 0 4.53735 0 10.1345V28.7776V33.7815V95.3695C0 101.875 0.650238 104.4 1.87443 106.783L2.03043 107.08C3.35531 109.558 5.29952 111.502 7.77683 112.827C10.1551 114.099 12.5148 114.8 18.695 114.854L19.4877 114.857H133.374C139.879 114.857 142.404 114.207 144.787 112.983L145.085 112.827C147.562 111.502 149.506 109.558 150.831 107.08C152.103 104.702 152.804 102.342 152.858 96.1622L152.861 95.3695V28.7776C152.861 22.2724 152.211 19.7476 150.987 17.3642L150.831 17.0668C149.506 14.5894 147.562 12.6452 145.085 11.3204C142.607 9.99547 140.15 9.28992 133.374 9.28992L50.8419 9.29053L39.6933 0Z"
      :fill="fill"
    />
    <path
      opacity="0.58471"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.000488281 58.4932C9.54565 55.0477 21.9728 49.6674 37.7396 39.1574C46.3708 33.404 46.4425 24.1292 46.525 13.4796C46.544 11.0214 46.5636 8.48993 46.6891 5.91162L50.8424 9.3727L133.374 9.37209C140.15 9.37209 142.608 10.0776 145.085 11.4025C147.562 12.7274 149.507 14.6716 150.831 17.1489L150.987 17.4464C152.212 19.8297 152.862 22.3545 152.862 28.8598V43.034C150.59 48.7083 147.659 54.1668 144.733 59.3244C143.828 60.9189 142.586 62.349 141.349 63.7735L141.349 63.7736C139.334 66.0926 137.333 68.3967 136.822 71.3709C135.995 76.1813 136.631 81.0789 137.268 85.9863C137.813 90.1865 138.359 94.3938 137.989 98.5597C137.349 105.781 128.214 111.186 118.908 114.939H19.4882L18.6955 114.936C12.5153 114.882 10.1555 114.181 7.77732 112.909C5.30001 111.584 3.3558 109.64 2.03092 107.163L1.87491 106.865C0.650726 104.482 0.000488281 101.957 0.000488281 95.4517V58.4932Z"
      :fill="fill"
    />

  </svg>
</template>

<script>
export default {
	name: 'Folder',
	props: {
		fill: {
			type: String,
			default: '#FF5992'
		},
		width: {
			type: String,
			default: '100%'
		}
	}
};
</script>

<style>

</style>
