

import { sync } from 'vuex-router-sync';


import {vuetify,router,store,registerComponents,webfontloader,vueFileAgent,vueMeta,vueSmoothScroll} from './index';

const registerPlugins =()=>{
	vueFileAgent();
	sync(store, router);
	registerComponents();
	webfontloader();
	vueMeta();
	vueSmoothScroll();
	return {vuetify,router,store};
};

export {registerPlugins};