<template>
  <div>
    <span v-if="vuetifyField.type==='otp' && field.labeling" :class="{'error--text':isValidated}">
      {{ field.labeling.label }}
    </span>
    <v-tooltip bottom :disabled="!title || !isOver">
      <template v-if="vuetifyField" #activator="{ on, attrs }">
        <Component
          :is="vuetifyField.element"
          :id="field.name"
          v-bind="{ ...attributes, ...attrs }"
          ref="inputRef"
          :field="field"
          :readonly="readonly || attributes.readonly"
          :outlined="outlined"
          :solo="solo"
          :dense="dense"
          :tooltip="title"
          :emit-submit="emitSubmit"
          :format="field.format"
          :description="description"
          :model="model"
          :class="customClass"
          :value="inputValue"
          :search-input.sync="search"
          autocomplete="off"
          :vuetify-field="vuetifyField"
          :hint="(!inputValue || inputValue === '') && !!field.hint ? field.hint : null"
          :label="label"
          :prepend-inner-icon="field.style && !!field.style.prependInnerIcon
            ? field.style.prependInnerIcon
            : null
          "
          :append-icon="appendIcon"
          :counter="field.type | toCounter"
          :maxlength="field.type | toCounter"
          :type="field.type === 'password'
            ? showPass
              ? 'text'
              : 'password'
            : field.type | toType
          "
          :placeholder="field.placeholder ? field.placeholder : ''"
          :rules="rules"
          :length="vuetifyField.counter"
          :single-line="false"
          :filter="comparator"
          :disabled="attributes.disabled"
          :attributes="{ ...attributes, ...attrs }"
          :items="items"
          :loading="loading"
          :item-text="itemText"
          :item-value="itemValue"
          :data-parent-model="dataParentModel"
          :no-data-text="nameListEmptyField"
          :multiple="field.multiple && String(field.multiple) === 'true'"
          :true-value="'trueValue' in field ? field.trueValue : true"
          :false-value="'falseValue' in field ? field.falseValue : false"
          :events-bus="eventsBus"
          @mouseover.native="changeBlur(true)"
          @mouseleave.native="changeBlur(false)"
          @click:append="field.type === 'password' ? handleClickPassword() : null"
          @input="handleInputChange(field, $event)"
          @change="handleInputChange(field, $event)"
          @keydown="handleKeyDown"
          @blur="handleBlur"
          @changeCustomInput="$emit('input', $event)"
          @upload-file="handleUploadFile"
          @reload-form="$emit('reload-form', $event)"
          v-on="on"
        >
          <template v-if="isSelectCustomText" #selection="{ item: dataItem }">
            {{ generateStringFromVariables({ title: isSelectCustomText, data: dataItem }) }}
          </template>

       
          <template v-if="isSelectCustomText" #item="{ item: dataItem }">
            <v-list-item-content>
              <v-list-item-title v-text="generateStringFromVariables({ title: isSelectCustomText, data: dataItem })" />
              <v-list-item-subtitle v-if="isSelectCustomDescription" v-text="generateStringFromVariables({ title: isSelectCustomDescription, data: dataItem })" />
            </v-list-item-content>
          </template>

          <template v-if="isSelectApi && !inputValue" #append-item>
            <div v-if="items.length > 2" class="text-text text-caption px-4 py-2">
              <div v-if="items.length >= 10">
                Se muestran los primeros 10 resultados.
              </div>
              <div>
                Ingrese texto para afinar la búsqueda
              </div>
            </div>
          </template>
        </Component>
      </template>

      <span>{{ title }}</span>
    </v-tooltip>
    <!-- <ErrorMsg v-if="vuetifyField.type==='otp' && isValidated && !isFieldValidated" msg="Completar." /> -->
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { getConstant } from '@/util/constantsHelpers';
import { toPathObject, generateStringFromVariables } from '@/util/helpers';
import { genericRequest } from '@/api/modules';
import { getActiveFromArray } from '@/util/dataHelpers';
import { orderArrayBy } from '@/util/arrayHelpers';
import { debounce } from 'lodash';
import {
	toAttributes,
	toType,
	toCounter,
	toFullVuetifyField,
	toVuetifyValidate
} from '@/mappers/form';

export default {
	name: 'CustomInput',
	filters: {
		toType,
		toCounter
	},
	props: {
		eventsBus: {
			type: Object,
			default: function () {
				return {};
			}
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		emitSubmit: {
			type: [Boolean, String],
			default: false
		},
		model: {
			type: Object,
			default: function () {
				return {};
			}
		},
		localFormFields: {
			type: [Boolean, Object, Array],
			default: function () {
				return {};
			}
		},
		value: {
			type: [String, Number, Boolean, Object, Array, File],
			default: false
		},
		validate: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		statusModal: {
			type: Boolean,
			default: null
		},
		outlined: {
			type: Boolean,
			default: false
		},
		solo: {
			type: Boolean,
			default: false
		},
		dense: {
			type: Boolean,
			default: false
		},

		dataParentModel: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		}
	},

	data() {
		return {
			search: null,
			inputValue: null,
			showPass: false,
			loading: false,
			items: [],
			isOver: false,
			isValidated: false,
			generateStringFromVariables,
			firstLoading: true
		};
	},
	computed: {
		meta: get('route/meta'),
		constants: get('app/constants'),
		appendIcon() {
			if (this.field.type === 'password') {
				if (this.showPass) {
					return 'mdi-eye-off';
				}
				return 'mdi-eye';
			}
			if (this.field.style?.appendIcon) {
				return this.field.style.appendIcon;
			}

			if (this.vuetifyField.items) {
				return '$dropdown';
			}
			return null;

		},
		title() {
			let txt = this.field.title;
			if (this.inputValue?.length) {
				const fieldValue = getActiveFromArray(this.inputValue);
				if (fieldValue) {
					txt = generateStringFromVariables({ title: this.field.title, data: fieldValue });
				}
			}


			return txt;
		},
		label() {
			return this.field?.labeling?.label ? this.field.labeling.label : '';
		},
		description() {
			return this.field?.labeling?.description ? this.field.labeling.description : false;
		},
		vuetifyField() {
			return toFullVuetifyField(this.field.type);
		},
		attributes() {
			return toAttributes(this.field?.style);
		},
		customClass() {
			return this.field?.style?.class;
		},
		rules() {
			return this.validate
				? toVuetifyValidate(this.field, this.model)
				: [];
		},
		itemValue() {
			return this.field?.key ? this.field.key : 'slug';
		},
		itemText() {
			return this.field?.text ? this.field.text : 'text';
		},
		isSelectCustomText() {
			return this?.vuetifyField.items && this.field?.['custom-text'];
		},
		isSelectCustomDescription(){
			return this?.vuetifyField.items && this.field?.['custom-description'];

		},
		isSelectApi() {
			return this?.vuetifyField.items && String(this.field?.['api']) === 'true';
		},
		nameListEmptyField() {
			let relationLabel = 'Sin datos';
			if (this.loading) {
				relationLabel = 'Cargando...';
			}
			if (this.field?.relation?.field) {
				const isLabel = this.localFormFields.find(e => e.name === this.field.relation?.field);
				if (isLabel) {
					if (!this.model[isLabel.name]) {
						relationLabel = `Completar ${isLabel?.labeling?.label}`;
					} else {
						relationLabel = `No se encontraron datos relacionados a ${isLabel?.labeling?.label}`;
					}
				}
			}

			return relationLabel;
		}

	},
	watch: {
		// value (value) {
		// 	if(this.vuetifyField.type!=='otp' && this.inputValue !== value){
		// 		this.inputValue = value;
		// 		this.$emit('input', this.inputValue);
		// 	}
		// },
		model() {
			this.inputValue = null;
			if (this.model?.[this.field.name]) {
				this.inputValue = this.field?.object || this.field.multiple==='true'  ? this.value : this.model[this.field.name];
			}
			if (!this.field.object && !this.inputValue && this.value) {
				this.inputValue = this.value;
			}
			this.$emit('input', this.inputValue);
		},
		search: debounce(function () {
			if (this.isSelectApi && (!this.search?.trim() || !this.inputValue) && !this.search?.endsWith(' ')) {
				this.getItems(true);
			}

		}, 550)
	},
	mounted() {

		if (this.vuetifyField?.items && (!this.field.relation?.field || this.model?.[this.field.relation.field])) {
			this.getItems();
		}

		if (this.model?.[this.field.name]) {
			this.inputValue = this.field?.object || this.field.multiple==='true' ? this.value : this.model[this.field.name];
		}
		if (!this.field.object && !this.inputValue && this.value) {
			this.inputValue = this.value;
		}
		this.$emit('input', this.inputValue);
		if (this.eventsBus?.$on) {

			this.eventsBus.$on('change', this.checkFieldDepend);
			this.eventsBus.$on('validation', this.checkValidations);
		}
		if (this.field.focus) {
			this.focusAutocomplete();
		}
	},
	methods: {
		comparator(a, b){
			const text = this.isSelectCustomText ? generateStringFromVariables({ title: this.isSelectCustomText, data: a }) : a[this.itemText];

			return this.isSelectApi || text.toLowerCase().includes(b.toLowerCase());
		},
		handleKeyDown(event) {
			if (event) {
				if (this.vuetifyField.type === 'otp') {
					if (this.inputValue && (event?.key?.toLowerCase() === 'tab' ||
					event?.code?.toLowerCase() === 'tab' ||
					event?.keyCode === 9)) {
						this.processTags(event);
					}else{
						if(this.attributes?.number && !Number(event.key)){
							event.preventDefault();
							event.stopPropagation();
						}
					}
					return;
				}
				if (event?.key?.toLowerCase() === 'escape' ||
					event?.code?.toLowerCase() === 'escape' ||
					event?.keyCode === 27) {
					this.$emit('onEsc');
				}
				if (
					(
						!this.vuetifyField?.items ||
						(
							!this.loading &&
							this.inputValue
						)
					) &&
					(
						event?.key?.toLowerCase() === 'enter' ||
						event?.code?.toLowerCase() === 'enter' ||
						event?.keyCode === 13
					)
				) {
					event.preventDefault();
					event.stopPropagation();
					this.$emit('onEnter');
				}

			}
		},
		handleBlur(event) {
			if (
				event &&
				this.vuetifyField?.items &&
				this.loading
			) {
				event.target.focus();
			}

		},
		prevent(event) {
			event.preventDefault();
			event.stopPropagation();
		},
		processTags() {
			if (this.vuetifyField.type === 'otp') {
				const value = this.inputValue;
				if (Number(this.vuetifyField.counter) > value.length) {
					const zerosToAdd = Number(this.vuetifyField.counter) - value.length;
					let _value = '0'.repeat(zerosToAdd) + value;
					this.inputValue = _value;
					this.$emit('input', _value);
					this.focusAutocomplete(Number(this.vuetifyField.counter)-1);
				}
			}

		},

		checkValidations(data) {
			this.isValidated = data;
		},
		checkFieldDepend(field) {
			const isDepends = this.field.relation?.field && this.field.relation.field === field.name && this.field.name !== field.name;
			if ((this.statusModal || this.statusModal === null) && this.vuetifyField?.items && isDepends) {
				this.getItems();
			}
		},
		handleInputChange(field, value) {
			const props = toFullVuetifyField(field.type);
			this.isValidated = false;

			let newValue = value;
			if (value && (typeof value === 'string' || value instanceof String)) {
				newValue = value.trim();
			}

			if (props.type === 'number') {
				newValue = Number(newValue);
			}

			this.inputValue = newValue;
			this.$emit('input', newValue);
		},
		handleClickPassword() {
			this.showPass = !this.showPass;
		},
		async getItems(focus) {
			let response = { resources: [] };
			let get = true;

			if (this.field?.relation?.field) {
				const isRelationField = this.localFormFields.find(e => e.name === this.field.relation?.field);
				
				if (isRelationField) {
					this.items = [];
					this.inputValue = null;
					this.$emit('input', null);
					if (!this.model[isRelationField.name]) {
						get = false;
					} else {
						const dataConstant = this.field?.relation?.data?.constants;
						if (dataConstant) {
							get = false;
							response.resources = getConstant(
								{
									field: dataConstant.field,
									service: dataConstant.service,
									property: dataConstant.property,
									filter: this.model[isRelationField.name],
									keyToReturn: dataConstant.keyToReturn
								}
							);
						}
					}


				}
			}
		
			const orderBy = this.field?.data?.order || 'name';
			if (this.field?.data?.href && !this.loading && get) {
				
				this.loading = true;
				try {
					const url = generateStringFromVariables({
						title: this.field.data.href,
						data: { ...this.dataParentModel, ...this.model }
					});

					if (url) {
						const params = {};
	
						if (this.isSelectApi) {
							if (this.search?.length) {
								this.search = this.search.trim();
								params.keyword = this.search;
							} else {
								if (this.firstLoading && this.value) {
									params.keyword = this.value;
								}
							}

						}
						response = await genericRequest({
							url,
							method: this.field.data.method,
							service: this.field.data.service,
							params
						});

					} else {
						response = { resources: [] };
					}
				} catch (error) {
					console.log(error);
					response = { resources: [] };
					this.loading = false;
				}
			} else if (this.field?.data?.list) {
				response.resources = this.field.data.list;
			} else if (this.field?.data?.source) {
				response.resources = this.model ? toPathObject(this.model, this.field.data.source) : [];
			} else if (this.field?.data?.parentData) {
				response.resources = this.dataParentModel ? toPathObject(this.dataParentModel, this.field.data.parentData) : [];
			} else if (this.field?.data?.constants) {
				response.resources = getConstant({ 
					field: this.field.data.constants, 
					service: this.field.data.constants.service || this.field.data.service,
					filter: this.field.data.constants.filter,
					keyToReturn: this.field.data.constants.keyToReturn 
				});
			}
			
			if (this.vuetifyField?.items && this.field?.key && !this.field?.object) {
				const fieldDataItems = response?.resources?.find(e => e[this.field.key] === this.model?.[this.field.name]);
				if (!response.resources?.length || (this.model?.[this.field.name] && !fieldDataItems)) {
					// this.inputValue = null
					// this.$emit('input', null)
				}
			}

			if (Array.isArray(response.resources)) {
				//this.items = response.resources.filter(e=>!this.model || e.uuid!==this.model.uuid);
				this.items = orderBy && !this.isSelectApi ? orderArrayBy(response.resources, orderBy) : response.resources;
			}
	
			if (this.field?.data?.nulleable) {
				this.items.push({ [this.field.key]: 'null', [this.field.text]: this.field.data.nulleable });
			}
			this.loading = false;

			this.firstLoading = false;
			if (focus) {
				this.focusAutocomplete();
			}

		},
		handleUploadFile(file) {
			const files = file.fileRecordsForUpload ? file.fileRecordsForUpload : [];
			let sendFiles = file.multipleFiles ? [] : null;
			files.forEach(element => {
				if (file.multipleFiles) {
					sendFiles.push(element.file);
				} else {
					sendFiles = element.file;
				}
			});
			this.$emit('input', sendFiles);
		},
		changeBlur(event) {
			this.isOver = event;
		},
		focusAutocomplete(index=0) {
			// Use $nextTick to wait for the component to be rendered before focusing
			this.$nextTick(() => {
				const focus = this.$refs?.inputRef?.$refs?.input;
				if (focus) {
					if (Array.isArray(focus)) {
						focus[index]?.focus();
					} else {
						focus.focus();
					}
				}

			});
		}

	}
};
</script>

<style></style>
