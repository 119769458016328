<template>
  <div class="d-flex justify-space-between align-center py-3">
    <a
      :href="file.url"
      class="text-none blue--text text-decoration-none d-none d-md-block"
      target="_blank"
    >
      {{ label }}
      <v-icon
        color="blue"
        small
      >
        mdi-open-in-new
      </v-icon>

    </a>
  </div>
</template>

<script>

import { saveAs } from 'file-saver';

export default {
	name: 'BtnDownloadFile',
	components: {

	},

	props: {
		file: {
			type: [Object],
			default: function () {
				return {};
			}
		},
		label: {
			type: [String],
			default: 'Ver en pantalla completa'
		}
	},

	methods: {

		download (url, name, event) {
			event.preventDefault();
			saveAs(url, name);
		}

	}
};
</script>

  <style scoped>

    </style>
