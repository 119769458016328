<template>
  <VRow>
    <VCol
      cols="12"
      md="6"
    >
      <component
        :is="form.columns[0].type"
        :form="form.columns[0]"
        validate
        @search="secondFormData=false"
        @clickRow="handleClickRow"
      />
    </VCol>
    <VCol
      cols="12"
      md="6"
      class="p-relative"
    >
      <custom-loading :loading="loading" absolute opacity="0.1" />
      <component
        :is="form.columns[1].type"
        v-show="!loading"
        :form="form.columns[1]"
        validate
        :validations="validations"
        :form-data="secondFormData"
        @clickRow="handleClickRow"
        @changeValidation="handleChangeValidation"
      />
    </VCol>
  </VRow>
</template>

<script>
import { genericRequest } from '@/api/modules';
export default {
	name: 'Layout5050',
	components: {

	},
	props: {
		form: {
			type: Object,
			default: function () {
				return { headers: [] };
			}
		}
	},

	data () {
		return {
			loading:false,
			validations:{},
			benefitTypes:[],
			secondFormData:false,
			excepcionBtn:{
				name: 'Excepción',
				isExcepcion: true,
				disabled:false,
			}
		};
	},
	computed: {

	},
	async mounted () {
		const {resources:benefitTypes} = await genericRequest({
			url: 'benefit-types',
			method: 'get',
			service: 'afiliaciones'
		});
		this.benefitTypes=benefitTypes;


	},

	methods: {
		async handleClickRow (data) {
			this.loading=true;
			const validations = await genericRequest({
				url: `persons/${data.uuid}/validations`,
				method: 'get',
				service: 'afiliaciones',
				checkTokenStatus: true
			});
			this.validations=validations;
			
			const {resources:personBenefits} = await genericRequest({
				url: `persons/${data.uuid}/benefits`,
				method: 'get',
				service: 'afiliaciones'
			});

			this.secondFormData=data;
			if(this.benefitTypes?.length){
				const benefitTypes=this.benefitTypes.map(e=>{
					const personBenefit=personBenefits.find(_e=>_e.benefit_type.uuid===e.uuid);
					if(personBenefit){
						return {...personBenefit.benefit_type,disabled:false};
					}
					return {...e,disabled:true};
				});
				this.secondFormData.benefits=[...benefitTypes,...[this.excepcionBtn]];
			}
			
			this.loading=false;
		},
		async handleChangeValidation(benefit){
			const params={};
			if(benefit.benefit_subtype_uuid){
				params.benefit_subtype_uuid=benefit.benefit_subtype_uuid;
			}
			if(benefit.uuid){
				params.benefit_type_uuid=benefit.uuid;
			}
			this.loading=true;
			const validations = await genericRequest({
				url: `persons/${this.secondFormData.uuid}/validations`,
				method: 'get',
				params,
				service: 'afiliaciones',
				checkTokenStatus: true
			});
			this.validations=validations;
			
			this.loading=false;
		},
	}
};
</script>

<style lang="scss">
    .no-icon{
        .v-stepper__step__step{
            display: none !important;
        }
    }
</style>
