<template>
  <div>
    <custom-loading
      :loading="loading"
      z-index="16"
    />
    <v-navigation-drawer
      id="drawer-comentarios"
      v-model="drawerComentarios"
      fixed
      right
      app
      temporary
      width="420px"
      :permanent="loading"
    >
      <v-subheader
        v-text="'Comentarios'"
      />
      <v-tabs
        v-model="tab"
        background-color="transparent"
        centered
        grow
      >
        <v-tab>
          Recibidos
        </v-tab>
        <v-tab>
          Enviados
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-list three-line>
            <template v-for="(item, index) in data.received_comments">
              <v-divider
                v-if="data.received_comments && index>0 && index<data.received_comments.length"
                :key="`tabs-divider-${index}`"
                color="lightGrey"
              />

              <v-list-item
                :key="`tabs-${index}`"
                dense
              >
                <v-list-item-content>
                  <v-list-item-action class="my-0 justify-end">
                    <v-list-item-action-text v-text="shortDateTime(item.created_at)" />
                  </v-list-item-action>
                  <v-list-item-title>
                    <v-chip
                      :key="`${index}-find-received`"
                      x-small
                      class="mr-2"
                    >
                      {{ findArea(item.from_area) }}
                    </v-chip>
                  </v-list-item-title>
                  <div
                    v-if="item.message.length> 215"
                    class="d-flex justify-end mb-0"
                  >
                    <v-btn
                      icon
                      depressed
                      plain
                      retain-focus-on-click
                      x-small
                      @click="changeExpand(item)"
                    >
                      <v-icon
                        v-if="expand[item.uuid]"
                        small
                      >
                        mdi-arrow-up-thin
                      </v-icon>
                      <v-icon
                        v-else
                        small
                      >
                        mdi-arrow-down-thin
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-list-item-subtitle
                    :class="{'pt-2':item.message.length< 216,expanded:expand[item.uuid]}"
                    v-html="item.message"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-list three-line>
            <template v-for="(item, index) in data.sent_comments">
              <v-divider
                v-if="data.sent_comments && index>0 && index<data.sent_comments.length"
                :key="`tabs-divider-${index}`"
                color="lightGrey"
              />

              <v-list-item
                :key="`item_${index}`"
                dense
              >
                <v-list-item-content>
                  <v-list-item-action class="my-0 justify-end">
                    <v-list-item-action-text v-text="shortDateTime(item.created_at)" />
                  </v-list-item-action>
                  <v-list-item-title>
                    <template v-if="item.to_area && item.to_area.length">
                      <v-chip
                        v-for="area in item.to_area"
                        :key="`${area}-find`"
                        x-small
                        class="mr-2"
                      >
                        {{ findArea(area) }}
                      </v-chip>
                    </template>
                    <v-chip
                      v-else
                      :key="`${index}-find`"
                      x-small
                      class="mr-2"
                    >
                      Todas las áreas
                    </v-chip>
                  </v-list-item-title>
                  <div
                    v-if="item.message.length> 215"
                    class="d-flex justify-end mb-0"
                  >
                    <v-btn
                      icon
                      depressed
                      plain
                      retain-focus-on-click
                      x-small
                      @click="changeExpand(item)"
                    >
                      <v-icon
                        v-if="expand[item.uuid]"
                        small
                      >
                        mdi-arrow-up-thin
                      </v-icon>
                      <v-icon
                        v-else
                        small
                      >
                        mdi-arrow-down-thin
                      </v-icon>
                    </v-btn>
                  </div>

                  <v-list-item-subtitle
                    :class="{'pt-2':item.message.length< 216,expanded:expand[item.uuid]}"
                    v-html="item.message"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-tab-item>
      </v-tabs-items>

      <template
        v-if="!readonly"
        #append
      >
        <VForm
          ref="comments"
          class="px-2 py-4"
        >
          <VRow>
            <VCol
              cols="12"
              class="py-0"
            >
              <v-textarea
                v-model="addComment.message"
                outlined
                name="input-7-4"
                label="Ingresar comentario"
                value=""
                rows="2"
                hide-details="auto"
                :rules="textRules"
              />
            </VCol>
            <VCol
              cols="12"
            >
              <v-select
                v-model="addComment.to_area"
                :items="itemsAreas"
                chips
                item-text="name"
                item-value="uuid"
                label="Para áreas"
                multiple
                deletable-chips
                outlined
                small
                hide-details
              />
            </VCol>
            <VCol
              cols="12"
              class="pt-0"
            >
              <v-btn
                block
                small
                dark
                color="green"
                @click="handleAddComment"
              >
                Comentar
              </v-btn>
            </VCol>
          </VRow>
        </VForm>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { genericRequest } from '@/api/modules';
import { shortDateTime } from '@/util/helpers';
export default {
	name: 'PersonComment',
	props: {
		data: {
			type: Object,
			default: function () {
				return {};
			}
		},
		widget: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	data: () => ({
		expand: {},
		shortDateTime,
		textRules: [(v) => !!v || 'Campo requerido'],
		loading: false,
		tab: 0,
		addComment: {
			message: null,
			to_area: []
		},
		itemsAreas: [],
		value: ['Todas']
	}),

	computed: {
		...sync('app', ['drawerComentarios']),
		readonly () {
			return String(this.widget?.readonly) === 'true';
		},
		itemSelect () {
			let response = 'received_comments';
			if (this.tab === 1) {
				response = 'sent_comments';
			}
			if (this.data?.[response]?.length) {
				return this.data?.[response];
			}
			return [];
		}

	},
	watch: {
		drawerComentarios () {
			this.init();
		}
	},
	mounted () {
		this.getAreas();
	},
	methods: {
		changeExpand (item) {
			this.$set(this.expand, item.uuid, !this.expand[item.uuid]);
		},
		init () {
			this.loading = false;
			this.addComment = {
				message: null,
				to_area: []
			};
			this.$refs.comments.resetValidation();
		},
		async getAreas () {
			const { resources } = await genericRequest({
				url: '/areas-for-user',
				method: 'get',
				service: 'rbac'
			});
			this.itemsAreas = resources;
		},
		async handleAddComment (event) {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}

			if (this.$refs.comments.validate()) {
				this.loading = true;
				const response = await genericRequest({
					url: `persons/${this.data?.uuid}/comments`,
					method: 'post',
					service: 'afiliaciones',
					params: this.addComment
				});

				this.$emit('reload-form', { response, field: { name: 'sent_comments' } });
				this.$emit('reload-form', { response, field: { name: 'received_comments' } });
				this.init();
			}
		},
		findArea (area) {
			const find = this.itemsAreas.find(element => element.uuid === area);
			return find?.name ? find.name : '';
		}
	}
};
</script>

  <style lang="scss">
    #drawer-comentarios{
      z-index:15;
      .v-chip.v-size--default {
          font-size: 12px!important;
          height: 30px!important;
      }
      .v-select__selections{
        min-height: unset!important;
      }
      .v-list-item__content {
        -ms-flex-item-align:  unset!important;
        align-self: unset!important;
      }
      .v-list-item {
        min-height: unset!important;
        .v-list-item__subtitle{
          -webkit-line-clamp: 4;
          line-clamp: 4;
          &.expanded{
            -webkit-line-clamp: unset!important;
          line-clamp: unset!important;
          }
        }
      }
    }
  </style>
