var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VRow',{attrs:{"justify":"center"}},[_c('VCol',{attrs:{"cols":"12","md":"10","lg":"9","xl":"8"}},[_c('VForm',{ref:"functionality-module",attrs:{"disabled":_vm.loading}},[_c('VRow',{staticClass:"pt-2",attrs:{"justify":"center"}},[_c('VCol',{attrs:{"cols":"12","md":"5"}},[_c('custom-input',{attrs:{"field":{name:'functionalityName',labeling:{label:'Nombre de la funcionalidad'},title:'Nombre de la funcionalidad',type:'string:75',required:true},"value":_vm.functionalityName,"validate":"","tooltip":'Nombre'},on:{"input":function($event){return _vm.handleInputChange({name:'functionalityName'}, $event)},"onEnter":_vm.handleClickAdd}})],1),_c('VCol',{attrs:{"cols":"10","md":"5"}},[_c('custom-input',{attrs:{"field":{
                name: 'functionalityReference',
                labeling: { 
                  label:'Referencia de la funcionalidad'
                },
                title: 'Referencia de la funcionalidad',
                type: 'select',
                required: true, 
                key: 'uuid',
                text: 'name',
                data: {
                  href: 'functionality-types',
                  method: 'GET'
                }
              },"value":_vm.functionalityReference,"validate":"","tooltip":'Referencia'},on:{"input":function($event){return _vm.handleInputChange({name:'functionalityReference'}, $event)},"onEnter":_vm.handleClickAdd}})],1),_c('VCol',{staticClass:"d-flex align-center justify-center",attrs:{"md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","elevation":"5","icon":""},on:{"click":_vm.handleClickAdd}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Agregar funcionalidad")])])],1)],1)],1)],1),_c('VCol',{staticClass:"pt-3",attrs:{"cols":"12","md":"8","lg":"7"}},[_c('simple-table',{attrs:{"form":{ headers: _vm.headers },"items":_vm.functionalitiesModule,"loading":_vm.loadingTable || !_vm.loaded || _vm.loading,"item-key":"uuid"},on:{"delete":_vm.deleteFunctionality}})],1)],1),(_vm.actions && _vm.actions.length)?_c('VRow',{staticClass:"custom-footer",attrs:{"justify":"center"}},[_c('VCol',{staticClass:"d-flex align-center pb-6 pt-12",attrs:{"cols":"11"}},[_c('VRow',{staticStyle:{"width":"100%"},attrs:{"justify":"space-between"}},_vm._l((_vm.actions),function(action,index){return _c('VCol',{key:`action-${index}`,staticClass:"pb-1",attrs:{"cols":"12","md":action.col ? action.col : 'auto'}},[_c('custom-btn',{ref:"button",refInFor:true,attrs:{"attributes":_vm.toAttributes(action.style),"custom-class":"text-none","color":action.style ? action.style.type : '',"width":action.style ? action.style.width : '',"loading":_vm.loading || !_vm.loaded,"icon":action.style ? action.style.icon : false,"label":action.label,"tooltip":action.title},on:{"handleClick":function($event){return _vm.handleClick(action)}}})],1)}),1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }