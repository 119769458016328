import Vue from 'vue';
import Router from 'vue-router';
import { layout, route } from '@/util/routes';
import store from '@/store';
import { getToken } from '@/util/auth';
import getPageTitle from '@/util/get-page-title';

const WHITE_LIST = ['/ingresar', '/auth-redirect'];

Vue.use(Router);

const routes = [
	{
		path: '/404',
		name: 'NotFound',
		component: () => import('@/views/error-page/404')
	},
	layout('Default', [
		route('Redirect', null, '/redirect/:path(.*)'),
		route('Dashboard', null, '/', {
			drawer: true,
			title: 'Inicio',
			icon: 'mdi-view-dashboard',
			loginRequired: true
		}),
		{
			name: 'Profile',
			component: () => import('@/views/generic-module/Index'),
			path: '/perfil',
			meta: {
				accountMenu: true,
				title: 'Perfil',
				loginRequired: true,
				color: 'primary',
				icon: 'account',
				folder: 'profile',
				form: 'profile',
				dataUser:true
			}
		}
	]),
	{
		path: '/ingresar',
		name: 'login',
		component: () => import('@/views/login/index')
	},
	{
		path: '/recuperar-contrasena/:token',
		name: 'recover',
		component: () => import('@/views/login/recover')
	},
	layout('Default',[
		{
			name: 'Base',
			component: () => import('@/views/generic-module/Index'),
			path: '/:module',
			meta: {
				title: 'Generic Module',
				loginRequired: true
			}
		},
		{
			name: 'Browse',
			component: () => import('@/views/generic-module/Index'),
			path: '/:area/:module',
			meta: {
				title: 'Generic Module',
				loginRequired: true
			}
		},
		{
			name: 'Add',
			component: () => import('@/views/generic-module/Index'),
			path: '/:area/:module/:action',
			meta: {
				title: 'Generic Module',
				loginRequired: true
			}
		},
		{
			name: 'Edit',
			component: () => import('@/views/generic-module/Index'),
			path: '/:area/:module/:id/:action',
			meta: {
				title: 'Generic Module',
				loginRequired: true
			}
		},
		{
			name: 'Task',
			component: () => import('@/views/generic-module/Index'),
			path: '/:area/:module/:id/:task/:action',
			meta: {
				title: 'Generic Module',
				loginRequired: true
			}
		}
	])
];

// routes.push({
// 	path: '/:pathMatch(.*)*',
// 	name: 'NotFound',
// 	component: () => import('@/views/error-page/404')
// });

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if (to.hash) return { selector: to.hash };
		if (savedPosition) return savedPosition;

		return { x: 0, y: 0 };
	},
	routes
});

router.beforeEach(async (to, from, next) => {
	// set page title
	store.commit('app/SET_LOADING', false);
	document.title = getPageTitle(to.meta.title);
	const hasToken = getToken();
	if (hasToken) {
		if (to.path === '/logout') {
			store.commit('app/SET_LOADING', true);
			await store.dispatch('user/logout');
			store.commit('app/SET_LOADING', false);
			next({ path: '/ingresar' });
		} else if (to.name === 'login' || to.name === 'recover') {
			// if is logged in, redirect to the home page
			next('/');
		} else {
			next();
		}
	} else {
		/* has no token */
		if (to.path === '/logout') {
			next('/ingresar');
		} else if (WHITE_LIST.indexOf(to.path) !== -1 || !to.meta.loginRequired) {
			// in the free login whitelist, go directly
			next();
		} else {
			// other pages that do not have permission to access are redirected to the login page.
			next(`/ingresar?redirect=${to.path}`);
		}
	}
});

export default router;