<template>
  <div>
    <custom-loading :loading="loading" />
    <component
      :is="'browse-table'"
      :parent-data="getTemplateParams"
      :form="form"
      validate
      :force-initialize="forceInitialize"
      @getTemplate="getTemplate"
    />
    <form-modal
      :open.sync="selectTemplateModal"
      :form="formSelectTemplate"
      persistent
      :data-source="getTemplateParams"
      @confirm="handleSelectTemplate"
      @cancel="selectTemplateModal=null"
    />
    <form-modal
      :open.sync="formTaskTemplateModal"
      :form="formTemplate"
      :is-send-form="false"
      @confirm="handleTemplateSubmit"
      @error="handleTemplateSubmit"
      @cancel="formTaskTemplateModal=null"
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import jsons from '@/mixins/jsons';
import { genericRequest } from '@/api/modules';
export default {
	name: 'Task',
	mixins: [jsons],
	props: {
		dataSource: {
			type: Boolean,
			default: false
		},
		validate: {
			type: Boolean,
			default: false
		},
		form: {
			type: Object,
			default: function () {
				return { headers: [] };
			}
		},
		currentModule: {
			type: [Object],
			default: ()=>{}
		}
	},
	data () {
		return {
			loading: false,
			selectTemplateModal: false,
			formSelectTemplate: null,
			formTemplate: null,
			formTaskTemplateModal: false,
			templateSelected: null,
			forceInitialize: false
		};
	},
	computed: {
		params: get('route/params'),
		route: get('route'),
		task_category_name () {
			return this.params?.id;
		},
		task_type_name () {
			return this.params?.task;
		},
		getTemplateParams () {
			return { task_category_name: `${this.task_category_name}`, task_type_name: `${this.task_type_name}`, template_uuid: this.templateSelected?.uuid };
		}
	},
	watch: {

	},
	mounted () {
		this.templateSelected = null;
		this.$emit('changeTitle',{task_category_name:this.task_category_name});
	},
	methods: {
		handleSelectTemplate ({ data: { template } }) {
			this.forceInitialize = false;
			this.templateSelected = null;

			const form = template.settings.form;

			this.templateSelected = template;

			this.formTemplate = form;
			this.formTaskTemplateModal = true;
		},

		async handleTemplateSubmit (data) {
			this.loading=true;
			const url=data.action.submit.href;
			const method=data.action.submit.method;
			const service=data.action.submit.service;
			const json = JSON.stringify(data.data);

			const response = await genericRequest({
				url,
				method,
				// headers:{'Content-Type':'application/json'},
				params:{...this.getTemplateParams,data:json},
				service,
				checkTokenStatus: true
			});

			console.log(response);
			this.formTaskTemplateModal = false;
			this.templateSelected = null;
			this.forceInitialize = true;
			this.loading=false;
		},
		async getTemplate () {
			this.loading=true;
			this.templateSelected = null;
			this.formSelectTemplate = this.importJson(`afiliaciones/${this.currentModule.reference}/templates/get.json`);

			const url='/templates';
			const {resources} = await genericRequest({
				url,
				method:'get',
				params:this.getTemplateParams,
				service:'tareas'
			});
			if(resources?.length>1){
				this.selectTemplateModal = true;
			}else{
				this.handleSelectTemplate({data:{template:resources[0]}});
			}
			this.loading=false;
		}
	}
};
</script>

<style>

</style>
