<template>
  <v-tooltip
    bottom
    :disabled="!tooltip"
    :class="customClass"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="text-none"
        v-bind="{...attributes,...attrs}"
        :class="customClass"
        :to="href"
        :loading="loading"
        v-on="on"
        @click="handleClick"
      >
        <v-icon
          v-if="attributes['prepend-icon']"
          :class="!!label?'mr-2':''"
          v-bind="{...attributesIcon}"
        >
          mdi-{{ attributes['prepend-icon'] }}
        </v-icon>
        {{ label }}
      </v-btn>
    </template>
    <span v-html="tooltip" />
  </v-tooltip>
</template>

<script>
export default {
	name: 'CustomBtn',
	props: {
		customClass: {
			type: [Object,String],
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		},
		href: {
			type: [String, undefined],
			default: undefined
		},

		label: {
			type: [String, undefined],
			default: undefined
		},
		tooltip: {
			type: [String, Boolean],
			default: false
		},

		attributes: {
			type: Object,
			default: function () {
				return { };
			}
		},
		attributesIcon: {
			type: Object,
			default: function () {
				return { };
			}
		}
	},
	methods: {
		handleClick (event) {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}
			this.$emit('handleClick');
		}
	}
};
</script>

<style>

</style>
