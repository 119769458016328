import { mapperColumns, mapperActions } from './utils/columnsVuetify';

export default (data) => {
	if(!data.columns){
		data.columns=[];
	}
	let headers = [];
	const source = data.source || false;
	const actions = data.actions?.length ? data.actions : false;
	const bottomActions = data.bottomActions?.length ? data.bottomActions : false;
	
	if (actions) {
		const actionsHeader = mapperActions(actions, data.actionTitle,data.actionClass);
		if (actionsHeader) {
			headers = [actionsHeader];
		}
	}
	
	let _headers=[];
	
	
	if(data.alignActions==='right'){
		_headers=[ ...data.columns.filter((obj) => obj.hidden !== 'true').map((obj) => mapperColumns(obj,data)),...headers];
	}else{
		_headers=[...headers, ...data.columns.filter((obj) => obj.hidden !== 'true').map((obj) => mapperColumns(obj,data))]; 
	}

	return {
		...data,
		sourceActions: source?.actions?.length ? source.actions : [],
		bottomActions,
		headers: _headers
	};

};
