<template>
  <VRow justify="center" class="pb-10">
    <VCol cols="12">
      <VRow class="mx-2" justify="center">
        <VCol cols="12">
          <div v-if="!formData" class="text-subtitle-1 text-center">
            Seleccionar afiliado
          </div>
          <VRow v-else align="center" justify="start">
            <VCol cols="12" class="pb-md-0">
              <div class="text-subtitle-1 font-weight-negrita">
                {{ formData.firstname }} {{ formData.lastname }} -
                {{ formData.identification_type.name }}
                {{
                  formatter({ value: formData.idnumber, formatter: "idnumber" })
                }}
              </div>
            </VCol>
            <VCol cols="12">
              <div class="text-subtitle-2 mb-1">
                Crédito disponible
              </div>
              <custom-btn
                tooltip="Ver detalle"
                :label="
                  formatter({ value: disponible, formatter: 'decimalPrice' })
                "
                :attributes="{
                  color:'green lighten-4'
                }"
                custom-class="py-5 px-10 textNormal--text"
                @handleClick="showDisponibleModal"
              />
            </VCol>
            <VCol cols="12" class="">
              <VForm ref="prestamoForm" class="pt-4">
                <VRow>
                  <!-- -->
                  <VCol cols="12" class="">
                    <v-select
                      v-model="form.type"
                      :items="types"
                      item-text="name"
                      item-value="uuid"
                      label="Tipo de préstamo"
                      outlined
                      hide-details="auto"
                      dense
                      :rules="[rules('required')]"
                      return-object
                      @change="changeType"
                    />
                  </VCol>
                  <VCol v-if="form.type && form.type.uuid === 2" cols="12" class="">
                    <v-text-field
                      v-model="form.type_rifa"
                      dense
                      :rules="[
                        rules('number')
                      ]"
                      outlined
                      hide-details="auto"
                      label="N° Rifa"
                      type="number"
                    />
                  </VCol>

                  <VCol cols="12" class="">
                    <v-select
                      v-if="form.type && form.type.uuid === 1"
                      v-model="form.amount"
                      :items="amounts"
                      item-text="name"
                      item-value="value"
                      label="Importe cuota"
                      outlined
                      hide-details="auto"
                      dense
                      :rules="[rules('required')]"
                      no-data-text="Seleccione cantidad de cuotas"
                      @change="checkCuotas"
                    />
                    
                    <v-text-field
                      v-else
                      v-model="form.amount"
                      dense
                      :rules="[
                        rules('number'),
                        rules(
                          'lowerOrEqualThan',
                          {
                            fields: ['disponible'],
                            message:
                              'El valor a solicitar debe ser menor al crédito disponible',
                          },
                          { disponible}
                        ),
                      ]"
                      outlined
                      hide-details="auto"
                      label="Importe cuota"
                      prepend-inner-icon="mdi-currency-usd"
                      type="number"
                      :min="0"
                      @input="checkCuotas"
                    />
                  </VCol>
                  <VCol v-if="form.type && form.type.uuid === 1" cols="12" class="">
                    <v-select
                      v-if="form.type && form.type.uuid === 1"
                      v-model="form.quantity"
                      :items="quantities"
                      item-text="name"
                      item-value="value"
                      label="Cantidad de cuotas"
                      outlined
                      hide-details="auto"
                      dense
                      :rules="[rules('required')]"
                      @change="checkCuotas"
                    />
                  </VCol>
                  <VCol v-if="form.type && form.type.uuid !== 1" cols="12" md="6" class="">
                    <v-text-field
                      v-model="form.quantity"
                      :rules="[rules('number')]"
                      dense
                      outlined
                      hide-details="auto"
                      label="Canditad de cuotas"
                      type="number"
                      :min="0"
                      @input="checkCuotas"
                    />
                  </VCol>
                  <VCol v-if="importeTotal>0" cols="12" class="">
                    <div class="text-subitle-2 font-weight-negrita">
                      Importe total: {{ formatter({ value: importeTotal, formatter: 'decimalPrice' }) }}
                    </div>
                  </VCol>
                  <VCol cols="12" class="">
                    <v-textarea
                      v-model="form.description"
                      outlined
                      label="Descripción"
                      value=""
                      rows="3"
                      hide-details="auto"
                      :rules="[rules('required')]"
                    />
                  </VCol>
                  <VCol cols="12" class="">
                    <v-select
                      v-model="form.chargeMethodType"
                      :items="formData.chargeMethodTypesList"
                      item-text="name"
                      item-value="uuid"
                      label="Forma de cobro"
                      outlined
                      hide-details="auto"
                      dense
                      :rules="[rules('required')]"
                      return-object
                    />
                  </VCol>
                  <VCol v-if="isComercio" cols="12" class="">
                    <div class="text-subtitle-2 mb-1">
                      Buscar comercio por tipo, nombre o cuit
                    </div>

                    <VAutocomplete
                      v-model="form.comercio"
                      :items="comercios"
                      label="Comercio"
                      no-data-text="Sin datos"
                      item-text="name"
                      item-value="uuid"
                      dense
                      outlined
                      :rules="[rules('required')]"
                      hide-details="auto"
                    />
                  </VCol>
                </VRow>
                <VRow
                  v-if="
                    bottomActions && bottomActions.length
                  "
                  class="custom-footer"
                  justify="center"
                >
                  <VCol class="d-flex align-center pb-6 pt-12" cols="12">
                    <VRow justify="space-between" style="width: 100%">
                      <VCol
                        v-for="(action, index) in bottomActions"
                        :key="`action-${index}`"
                        cols="12"
                        md="auto"
                        class="pb-1"
                      >
                        <custom-btn
                          ref="button"
                          :attributes="toAttributes(action.style)"
                          :custom-class="`text-none`"

                          :label="action.label"
                          :tooltip="action.title"
                          @handleClick="handleClick(action)"
                        />
                      </VCol>
                    </VRow>
                  </VCol>
                </VRow>
              </VForm>
            </VCol>
          </VRow>
        </VCol>
        <form-modal
          :open.sync="disponibleModal"
          :form="formDetalleCredito"
          persistent
          :attributes="['hide-default-footer', 'hide-default-header']"
          :items="dataDisponibleModal"
          @upgradeLimit="upgradeLimitHandle"
          @upgradeLimitShow="upgradeLimitShowHandle"
        />
        <form-modal
          :open.sync="upgradeLimitModal"
          :form="formUpgradelimit"
          persistent
          :data-source="{ actual_limit: limiteCredito }"
          max-width="400px"
          @confirm="handleUpgradeSubmit"
        />
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import formatter from '@/mixins/formatter';
import jsons from '@/mixins/jsons';
import tableMapper from '@/mappers/tables';
import rules from '@/util/rules';
import {simuladorCuotas} from '@/util/prestamos';
import { toAttributes } from '@/mappers/form';
import { genericRequest } from '@/api/modules';

export default {
	name: 'RegistroPrestamosForm',
	mixins: [formatter, jsons],
	props: {
		formData: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		}
	},
	data() {
		return {
			toAttributes,
			upgradeLimitModal:false,
			rules,
			disponibleModal:false,
			formDetalleCredito:null,
			formUpgradelimit:null,
			form:{chargeMethodType:'4bda6599-ff49-4f65-ada9-6eb52c3a20fa'},
			types:[
				{name:'Adelanto de Haberes',comercio:false,uuid:1},
				{name:'Rifa',comercio:false,uuid:2},
				{name:'Entrada Fiesta',comercio:false,uuid:3},
				{name:'Compra en cuotas',comercio:true,uuid:4}
			],
			comercios:[
				{name:'Comercio 1',uuid:1},
				{name:'Comercio 2',uuid:2},
				{name:'Comercio 3',uuid:3},
				{name:'Comercio 4',uuid:4}
			],
			amounts:[
				{name:'$ 10.000',value:10000},
				{name:'$ 20.000',value:20000},
				{name:'$ 30.000',value:30000},
				{name:'$ 40.000',value:40000}
			],

			quantities:[
				{name:'3',value:3},
				{name:'6',value:6},
				{name:'12',value:12},
				{name:'24',value:24}
			],
			ampliacionLimite:null,
			limiteActual:475000,
			'bottomActions': [
				{
					'id': 'back',
					'label': 'Cancelar',
					'event': {
						'action': 'cancel',
						'reset': true
					},
					'style': {
						'class': '',
						'attributes': [
							'outlined',
							'prepend-icon:arrow-left'
						]
					}
				},
				{
					'id': 'save',
					'label': 'Confirmar',
					'event': {
						'action': 'save',
						'reset': true
					},
					'style': {
						'attributes': [
							'color:success',
							'prepend-icon:plus'
						]
					}
				}
			] 
		};
	},
	computed: {
		meta: get('route/meta'),
		alertTxt: sync('app/alertTxt'),
		appLoading: sync('app/loading'),
		alert: sync('app/alert'),
		limiteCredito(){
			return this.ampliacionLimite || this.limiteActual;
		},
		disponible(){
			return this.limiteCredito-350000;
		},
		consumidos(){
			return [200000,100000,50000];
		},
		dataDisponibleModal(){
			const {limiteCredito,disponible}=this;
			const upgradeLimiteCredito=!this.ampliacionLimite;
			const data= [
        
				{
					id:'a',
					title:'Crédito disponible', 
					amount:disponible, 
					color:'green lighten-4'
				},
				{
					id:'b',
					title:'Límite de crédito', 
					amount:limiteCredito, 
					color:'blue-grey lighten-4',
					upgrade_limite_credito:upgradeLimiteCredito,
					upgraded_limite_credito:!upgradeLimiteCredito}
			];

			const dataConsumida=this.consumidos.map((element,key) => {
				return {id:`c${key}`,title:'Crédito consumido',amount:element,color:'red accent-1'};
			});
			return [...data,...dataConsumida];
		},
		isComercio(){
			return this.form?.type?.comercio;
		},
		importeTotal(){

			const {amount,quantity}=this.form;

			if(amount && quantity){
				return this.form.amount*this.form.quantity;
			}
			return 0;
		}
	},
	watch: {

		formData(){
			this.init();
		}
	},
	mounted() {

		
	},
	methods: {
		changeType(){
			if(this.$refs.prestamoForm){
				this.form={
					chargeMethodType:'4bda6599-ff49-4f65-ada9-6eb52c3a20fa',
					type:this.form.type
				};
				this.$emit('changeAmount', []);
			}
		},
		handleClick(action){
			if(action.event.action==='save'){
				this.handleSave();
			}
			if(action.event.action==='cancel'){
				this.handleCancel();
			}
		},
		upgradeLimitShowHandle(){
			console.log('show');
		},
		handleUpgradeSubmit(data){
			if(data?.data?.new_limit){
				this.ampliacionLimite=Number(data.data.new_limit);
			}
			this.alertTxt = {
				timeout: 3700,
				type: 'success',
				txt: 'Límite actualizado con éxito',
			};
			this.alert = true;
		},
		upgradeLimitHandle(){
			const form=this.importJson(
				'casas/casas-comerciales-registro-de-prestamos/upgrade-limit.json'
			);
			this.formUpgradelimit = form;
			this.upgradeLimitModal=true;
		},
		handleSave(){
			this.handleSubmit();
		},
		handleCancel(){
			this.init();
		},
		checkCuotas(){
			if(this.form.quantity && this.form.amount){
				const quantity=Number(this.form.quantity);
				const amount=Number(this.form.amount);

				this.$emit('changeAmount', simuladorCuotas({amount,quantity}));
			}else{
				this.$emit('changeAmount', false);
			}

		},
		showDisponibleModal(){
			const form=this.importJson(
				'casas/casas-comerciales-registro-de-prestamos/detalle-credito.json'
			);
			this.formDetalleCredito = tableMapper(form);
			this.disponibleModal=true;
		},
		async getLoanTypes(){
			this.validations = await genericRequest({
				url: 'loan-types',
				method: 'get',
				service: 'afiliaciones',
				checkTokenStatus: true
			});
		},

		init() {
			if(this.$refs.prestamoForm){
				this.$refs.prestamoForm.resetValidation();
			}
			this.getLoanTypes();
			this.form={
				chargeMethodType:'4bda6599-ff49-4f65-ada9-6eb52c3a20fa'
			};
			this.ampliacionLimite=null;
			this.$emit('changeAmount', false);
		},


		async handleSubmit() {
			if(this.$refs?.prestamoForm?.validate()){
				this.alertTxt = {
					timeout: 3700,
					type: 'success',
					txt: 'Guardado con éxito',
				};
				this.alert = true;
				this.init();
			}

		},

	},
};
</script>

<style>
</style>
