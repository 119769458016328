<template>
  <svg
    :width="width"
    viewBox="0 0 153 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layer1">
      <rect
        id="rect5663"
        rx="15.4"
        ry="15.4"
        height="105.8"
        width="152.9"
        y="9.3"
        x="0"
        :fill="fill"
        opacity="0.68471"
      />
    </g>

    <path
      opacity="0.58471"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 0.0005 58.4932 C 9.5457 55.0477 21.9728 49.6674 37.7396 39.1574 C 46.3708 33.404 46.4425 24.1292 47 9.3721 L 133.374 9.3721 C 140.15 9.3721 142.608 10.0776 145.085 11.4025 C 147.562 12.7274 149.507 14.6716 150.831 17.1489 L 150.987 17.4464 C 152.212 19.8297 152.862 22.3545 152.862 28.8598 V 43.034 C 150.59 48.7083 147.659 54.1668 144.733 59.3244 C 143.828 60.9189 142.586 62.349 141.349 63.7735 L 141.349 63.7736 C 139.334 66.0926 137.333 68.3967 136.822 71.3709 C 135.995 76.1813 136.631 81.0789 137.268 85.9863 C 137.813 90.1865 138.359 94.3938 137.989 98.5597 C 137.349 105.781 128.214 111.186 118.908 114.939 H 19.4882 L 18.6955 114.936 C 12.5153 114.882 10.1555 114.181 7.7773 112.909 C 5.3 111.584 3.3558 109.64 2.0309 107.163 L 1.8749 106.865 C 0.6507 104.482 0.0005 101.957 0.0005 95.4517 V 58.4932 Z"
      :fill="fill"
    />

  </svg>
</template>

<script>
export default {
	name: 'Square',
	props: {
		fill: {
			type: String,
			default: '#FF5992'
		},
		width: {
			type: String,
			default: '100%'
		}
	}
};
</script>

<style>

</style>
