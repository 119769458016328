
export default {
	methods: {
		handleFilter (value, filter) {
			const query = {};
			if (value && value.trim() !== '') {
				query[filter.param] = value.trim();
			}
			// this.$router.push({ path: this.$route.path, query })
			return query;
		}
	}
};
