import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from './fonts/vfs_fonts';

import { saveAs } from 'file-saver';

import templateRechazo from './templates/rechazo';
import templateEmpty from './templates/empty';
import templateObraSocial from './templates/obra-social';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
	Inter: {
		normal: 'Inter-Regular.ttf',
		bold: 'Inter-SemiBold.ttf'
	}
};
class PdfController {
	async dowloadRechazo (data, download = 'open') {
		const template = await templateRechazo(data);
		if (download === 'download') {
			pdfMake.createPdf(template).getBlob((blob) => {
				saveAs(blob, `rechazo_${Math.floor(Date.now() / 1000)}.pdf`);
			});
		} else {
			pdfMake.createPdf(template).open();
		}
	}
	async dowloadConfirmacion (data, download = 'open') {
		const template = await templateRechazo(data);
		if (download === 'download') {
			pdfMake.createPdf(template).getBlob((blob) => {
				saveAs(blob, `confirmacion_${Math.floor(Date.now() / 1000)}.pdf`);
			});
		} else {
			pdfMake.createPdf(template).open();
		}
	}

	async obraSocialConfirmation (data, download = 'open') {
		const template = await templateObraSocial(data);
		if (download === 'download') {
			pdfMake.createPdf(template).getBlob((blob) => {
				saveAs(blob, `confirmacion_${Math.floor(Date.now() / 1000)}.pdf`);
			});
		} else {
			pdfMake.createPdf(template).open();
		}
	}

	async dowloadTurismo(data, download = 'open') {
		const template = await templateEmpty(data);
		if (download === 'download') {
			pdfMake.createPdf(template).getBlob((blob) => {
				saveAs(blob, `${data.fileName}_${Math.floor(Date.now() / 1000)}.pdf`);
			});
		} else {
			pdfMake.createPdf(template).open();
		}
	}
}

export const pdfController = new PdfController();
