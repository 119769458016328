<template>
  <VRow justify="center" class="pb-6">
    <VCol cols="12" class="pa-0">
      <v-card
        class="d-flex align-center justify-space-between px-3 py-1"
        outlined
        @click="handleShow"
      >
        <span class="text-subtitle-1">{{ data.name }}</span>

        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-card>
    </VCol>
    <VCol cols="12" class="pl-9" :class="show ? '' : 'py-0'">
      <div v-if="loading && show" class="d-flex align-center justify-center">
        <VProgressCircular
          indeterminate
          size="44"
        />
      </div>

      <v-expand-transition v-else>
        <div v-show="show">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(remittance, keyRemittance) in remittances"
              :key="keyRemittance"
            >
              <v-expansion-panel-header class="py-0" @click="handleClickOrder(keyRemittance)">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex justify-end align-center">
                    <v-fade-transition leave-absolute>
                      <v-row no-gutters style="width: 100%">
                        <v-col cols="3">
                          Creación: {{ shortDate( remittance.created_at ) }}
                        </v-col>
                        <v-col cols="4">
                          {{ remittance.payment_orders.length }} Órdenes de pago
                        </v-col>
                        <v-col cols="5">
                          Suma de importes a pagar: $
                        </v-col>
                      </v-row>
                    </v-fade-transition>

                    <custom-btn
                      ref="button1"
                      color="success"
                      :attributes="{ icon: true }"
                      :attributes-icon="{ medium: true }"
                      outlined
                      custom-class="text-none mr-1"
                      icon="send-check"
                      tooltip="Pasar a estado “enviada”"
                    />
                    <custom-btn
                      ref="button2"
                      color="primary"
                      :attributes="{ icon: true }"
                      :attributes-icon="{ medium: true }"
                      outlined
                      custom-class="text-none mr-2"
                      icon="file-find"
                      tooltip="Pasar a estado “en revisión”"
                    />
                  </v-col>
                </v-row>
                <template #actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <custom-loading :loading="contentLoading" absolute opacity="0.4" size="40" />
                <v-row v-show="!contentLoading" justify="space-around" no-gutters>
                  <VCol cols="12">
                    <v-data-table
                      :headers="headers1"
                      :items="remittance.payment_orders"
                      sort-by="calories"
                      class="elevation-0"
                      :show-expand="false"
                      :expanded.sync="expanded"
                      item-key="user"
                      dense
                      :hide-default-header="false"
                    >
                      <template #expanded-item="{ headers:_headers, item }">
                        <td :colspan="_headers.length">
                          Más información del ítem {{ item.name }}
                        </td>
                      </template>
                      <template #[`item.actions`]="{ }">
                        <custom-btn
                          ref="button1"
                          color="primary"
                          :attributes="{ icon: true }"
                          :attributes-icon="{ small: true }"
                          outlined
                          custom-class="text-none"
                          icon="pencil"
                          tooltip="Editar"
                        />
                      </template>
                    </v-data-table>
                  </VCol>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-expand-transition>
    </VCol>
  </VRow>
</template>

<script>
import { genericRequest } from '@/api/modules';
import { shortDate } from '@/util/helpers';
export default {
	name: 'ExpansionPanels',
	props:{
		data: {
			required: true,
			type: Object,
			default: function () {
				return {  };
			}
		},
		keyValue: {
			required: true,
			type: Number,
			default: 0
		}
	},
	data: () => ({
		shortDate,
		remittances:[],
		loading:false,
		contentLoading:false,
		show: false,
		panels: {},
		expanded: [],
		headers: [
			{ text: 'Acciones', value: 'actions', sortable: false },
			{
				text: 'Item 1',
				align: 'start',
				sortable: false,
				value: 'name',
			},
			{ text: 'Item 2', value: 'calories' },
			{ text: 'Item 3', value: 'fat' },
			{ text: 'Item 4', value: 'carbs' },
			{ text: 'Item 5', value: 'protein' },
			{ text: '', value: 'data-table-expand' },
		],
		headers1: [
			{ text: 'Acciones', value: 'actions', sortable: false },
			{
				text: 'Creación',
				align: 'start',
				sortable: false,
				value: 'created',
			},
			{ text: 'Usuario', value: 'user' },
			{ text: 'Importe', value: 'amount' },
			{ text: 'Destino', value: 'destino' },
			{ text: 'CBU', value: 'cbu' },
			{ text: 'Concepto', value: 'concept' },
			{ text: 'Referencia', value: 'ref' },
			//   { text: "", value: "data-table-expand" },
		]
	}),

	computed: {},

	watch: {},

	created() {
		this.initialize();
	},

	methods: {
		handleTableAction() {},
		handleShow(){
			this.show = !this.show;
			if(this.show ){
				this.emitOpen();
			}
		},
		async handleClickOrder(keyRemittance){
			this.contentLoading=true;
			this.panels[keyRemittance]=!this.panels[keyRemittance];
			const {uuid} = this.remittances[keyRemittance];
			if(this.panels[keyRemittance] && uuid){
				const resource=await this.getRemittance(uuid);
				console.log(resource);
			}
			this.contentLoading=false;
		},
		async initialize() {
			if(this.keyValue===0){
				this.show=true;
				this.emitOpen();
			}
		},
		async emitOpen(){
			this.loading=true;
			const resources = await this.getRemittances();
			this.remittances=resources;
			this.loading=false;
			this.$emit('open',this.data);
		},
		async getRemittance(uuid){

			const resource = await genericRequest({
				url: `/remittances/${uuid}/payment-orders`,
				method: 'get',
				service: 'remesas',
				checkTokenStatus: true
			});
			return resource;
		},
		async getRemittances(){
			const params = {
				remittance_status_type_uuid:this.data.uuid
			};
			const {resources} = await genericRequest({
				url: '/remittances',
				params,
				method: 'get',
				service: 'remesas',
				checkTokenStatus: true
			});
			return resources;
		}
	},

};
</script>
<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75),
    inset 0 -4px 4px -5px rgba(50, 50, 50, 0.75);
}
</style>