<template>
  <VApp
    id="myApp"
    :style="{background: $vuetify.theme.themes[$vuetify.theme.dark? 'dark' : 'light'].background}"
  >
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <form-modal
      :open.sync="modalChangePassword"
      :form="formChangePassword"
    />

    <custom-alert />
    <custom-loading :loading="loading" />
  </VApp>
</template>

<script>
// Styles
import { get, sync } from 'vuex-pathify';
export default {
	name: 'App',
	metaInfo: {
		title: 'SSP',
		titleTemplate: '%s | Sindicato de Salud Pública',
		htmlAttrs: { lang: 'es' },
		meta: [
			{ charset: 'utf-8' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]
	},
	data: () => ({

		formChangePassword: require('@/jsons/profile/recover-session.json')

	}),
	computed: {
		...sync('app', ['modalChangePassword']),
		...get('user', ['dark']),
		...get('app', ['loading']),
	},
	created () {
		this.$vuetify.theme.dark = this.dark;
	},
	methods: {
	}
};
</script>
