<template>
  <VRow
    align="center"
    justify="start"
    class="py-1"
  >
    <VCol cols="auto">
      <div
        v-if="label"
        class="text-headline"
      >
        {{ label }}
      </div>
      <div
        v-for="(txt,index) in textValue"
        :key="index"
        class="text-subtitle-1"
      >
        {{ txt }}
      </div>
    </VCol>
  </VRow>
</template>

<script>
import { generateStringFromVariables } from '@/util/helpers';
export default {
	name: 'CustomList',
	props: {
		value: {
			type: [Number, String, Boolean, Array],
			default: function () {
				return '';
			}
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		label: {
			type: [String, null],
			default: ''
		},

		model: {
			type: Object,
			default: function () {
				return {};
			}
		}

	},
	data () {
		return {

		};
	},
	computed: {
		textValue () {
			const items = [];

			if (this.field?.propertyLabel && this.value?.length) {
				this.value.forEach((value) => {
					const txt = generateStringFromVariables({ title: this.field.propertyLabel, data: value });
					if (txt && txt !== 'null') {
						items.push(txt);
					}
				});
			}

			return items;
		}
	},
	methods: {

	}
};

</script>

<style>

</style>
