
export default {
	methods: {
		importJson (path) {
			let form = null;
			try {
				form = require(`@/jsons/${path}`);
			} catch {
				form = null;
				console.log(`No se encontró el json: ${path}`);
			}
			return form;
		}
	}
};
