<template>
  <VRow
    :justify="justify"
    align="center"
  >
    <VCol
      :class="`justify-${justify} ${justify!=='start'?'d-flex':''}`"
    >
      <v-autocomplete
        ref="autocomplete"
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        hide-selected
        item-text="Description"
        item-value="name"
        label="Productos"
        placeholder="Buscar productos"
        append-outer-icon="mdi-plus"
        return-object
        deletable-chips
        small-chips
        multiple
        auto-select-first
        persistent-hint
        hint="Búsqueda de productos"
        :filter="comparator"
        @update:search-input="searchInput"
        @keyup.enter="handleEnter"
        @keyup.native.enter="handleEnter"
        @click:append-outer="handleEnter"
        @blur="handleEnter"
      >
        <template #no-data>
          <v-list-item>
            <v-list-item-title>
              Buscar producto
            </v-list-item-title>
          </v-list-item>
        </template>
        <template
          #selection="{item, select}"
        >
          <v-chip
            v-if="item"
            v-bind="item.attrs"
            :input-value="item.selected"
            close
            small
            class="mt-2"
            @click="select"
            @click:close="remove(item)"
          >
            {{ item.name }} -  {{ `${item.presentation} - $ ${ item.health_insurance_amount }` }}
          </v-chip>
        </template>
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption">
              {{ `${item.presentation} - ${ item.drug }` }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="text-caption">
            $ {{ item.health_insurance_amount }}
          </v-list-item-action>
        </template>
      </v-autocomplete>
    </VCol>
    <VCol cols="12">
      <template v-if="list && list.length > 0">
        <p class="text-start text-subtitle-2">
          Productos agregados
        </p>
        <v-simple-table class="mb-10 transparent">
          <template #default>
            <thead>
              <tr>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Presentación
                </th>
                <th class="text-left">
                  Droga
                </th>
                <th class="text-left">
                  Precio
                </th>
                <th class="text-left" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in list"
                :key="item.uuid"
              >
                <td class="text-caption">
                  {{ item.name }}
                </td>
                <td class="text-caption">
                  {{ item.presentation }}
                </td>
                <td class="text-caption">
                  {{ item.drug }}
                </td>
                <td class="text-caption">
                  $ {{ item.health_insurance_amount }}
                </td>
                <td>
                  <v-btn icon depressed @click="deleteProduct(item)">
                    <v-icon small color="error">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <p v-else class="text-subtitle-2">
        Agregar productos
      </p>
    </VCol>
  </VRow>
</template>

<script>
import jsons from '@/mixins/jsons';
// eslint-disable-next-line no-unused-vars
import { genericRequest } from '@/api/modules';
export default {
	name: 'ApiSearch',
	mixins: [ jsons],
	props: {
		label: {
			type: [String, Boolean],
			default: false
		},
		justify: {
			type: [String],
			default: 'start'
		},
		value: {
			type: [String, Boolean,Array],
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		tooltip: {
			type: [String, undefined],
			default: undefined
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
	},
	data () {
		return {
			// entries:  this.importJson('modules/farmacia-presentaciones/vademecun.json'),
			entries:[],
			isLoading: false,
			model: [],
			list:[],
			search: null,
		};
	},
	computed: {    
		items () {

			const list=this.list.map(e=>e.uuid);
			return this.entries.filter(e=>{
				return !list.includes(e.uuid);

			});
		},
	},
	watch: {

	},
	mounted () {

	},
	methods: {
		remove (item) {
			const index = this.model.findIndex(e=>e.uuid === item.uuid);
		
			// eslint-disable-next-line vue/no-mutating-props
			if (index >= 0) this.model.splice(index, 1);
		},
		comparator(){
			return true;
		},
		async searchInput(){
			this.entries=[];
			// Items have already been requested
			if (this.isLoading) return;

			if(this.field?.data){

				const {href:url,method,service} = this.field.data;

				setTimeout(async () => {
					if(this.search?.length>=2 ){
						const	response = await genericRequest({
							url,
							method,
							service,
							params:{'keyword':this.search}
						});
						this.entries=response?.resources || response;
					}
					this.isLoading = false;
				}, 1000);
				this.isLoading = true;
			}

		},
		handleEnter(event){
			if(event){
				event.preventDefault();
				event.stopPropagation();
			}
			
			this.addItems();
		},
		addItems(){
			this.$refs.autocomplete.blur();
			this.search=null;
			this.list=this.list.concat(this.model);
			this.model=[];
			this.$emit('changeCustomInput', this.list.map(e=>e.uuid));
		},
		deleteProduct(data){
			const index=this.list.findIndex(e=>e.uuid === data.uuid);
			this.list.splice(index, 1);
			this.$emit('changeCustomInput', this.list.map(e=>e.uuid));
		}
	}
};
</script>

<style>

</style>
