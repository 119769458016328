<template>
  <VContainer fluid>
    <div class="d-flex justify-space-between align-end">
      <span
        class="font-weight-negrita"
        :class="$vuetify.breakpoint.mdAndUp ? fontMdAndUp : fontSmAndDown"
      >
        {{ title }}
      </span>
    </div>
  </VContainer>
</template>

<script>
export default {
	name: 'CustomTitle',
	components: {},

	props: {
		title: {
			type: String,
			default: ''
		},
		fontMdAndUp: {
			type: String,
			default: 'title'
		},
		fontSmAndDown: {
			type: String,
			default: 'subtitle-1'
		},
	}
};
</script>

<style></style>
