
export default (data = { }) => {
	const items = [{
		text: [
			{ text: 'Motivo: ', fontSize: 11, bold: true },
			{ text: data.motivo, fontSize: 11, bold: false }
		],
		color: '#000000'
	}];

	return data.motivo ? items : [];
};
