<template>
  <div>
    <div
      v-if="label"
      class="text-headline text-none "
    >
      {{ label }}
    </div>
    <div class="text-subtitle-1">
      {{ textValue }}
    </div>
  </div>
</template>

<script>
import formatter from '@/mixins/formatter';
import { generateStringFromVariables } from '@/util/helpers';
export default {
	name: 'CustomLabel',
	mixins: [formatter],
	props: {
		value: {
			type: [Number, String, Array, Object],
			default: function () {
				return '';
			}
		},
		label: {
			type: [String, null],
			default: ''
		},
		field: {
			type: [Object],
			default: function () {
				return {};
			}
		}
	},
	computed: {
		textValue () {
			let txt = null;
			if (this.value && this.field.title) {
				txt = generateStringFromVariables({ title: this.field.title, data: this.value });
			}
			return txt !== 'null' ? txt || '-' : '-';
		}
	},
};
</script>

<style>

</style>
