<template>
  <app-card v-bind="$attrs" tile class="mt-4" v-on="$listeners">
    <template v-if="$slots.img">
      <slot name="img" />
    </template>

    <template v-if="$slots.heading">
      <slot name="heading" />
    </template>
    <template v-if="$slots.title">
      <slot name="title" />
    </template>
    <template v-if="$slots.body">
      <slot name="body" />
    </template>
    <template v-if="$slots.subtitle">
      <slot name="subtitle" />
    </template>

    <template v-if="$slots.actions">
      <v-divider class="mt-2 mx-4" />

      <v-card-actions class="px-4 text-caption grey--text">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </app-card>
</template>

<script>
export default {
	name: 'MaterialCard',

	props: {

	},

	computed: {},
};
</script>
