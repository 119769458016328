import { fomatters } from '@/util/helpers';
export default {
	data () {
		return {

		};
	},
	methods: {
		formatter (data) {
			const formatter = data.header?.formatter || data?.formatter;
			const value = data.value || null;
			if (formatter && value && value !== 0) {
				return fomatters[formatter] ? fomatters[formatter](data.value,data) : data.value;
			}
			if(!value){
				if(data?.header.defaultValue){
					return data.header.defaultValue;
				}
			}
			return value;
		}
	}
};
