<template>
  <v-tabs
    vertical
    elevation="0"
    flat
    non-linear
    class="no-icon"
  >
    <template v-for="(entidad, key) in entidades">
      <v-tab
        :key="`step-${key}`"
        complete
        :step="key"
        editable
        @click="handleClick(entidad,key)"
      >
        <div class="text-subtitle-2 text-start">
          {{ entidad.name }}
        </div>
      </v-tab>

      <v-tab-item
        :key="`step-content-${key}`"
        :step="key"
      >
        <v-card flat>
          <VRow>
            <VCol
              cols="12"
              class="py-0"
              style="position:relative;min-height:400px;"
            >
              <custom-loading :loading="loading" />
              <material-card
                v-if="!loading"
                :color="dark ? '' : 'white'"
                elevation="0"
                class="py-0"
              >
                <template #body>
                  <v-card-text>
                    <component
                      :is="dataBrowse.type"
                      v-if="dataBrowse"
                      :form="dataBrowse"
                      validate
                      is-search
                      :force-initialize="forceInitialize && selectedKey ===key"
                      @loaded="handleLoaded"
                      @add="handleAdd"
                      @edit="handleEdit"
                      @edited="confirmModalAction"
                    />
                    <div
                      v-else
                      class="text-subtitle-1 text-center"
                    >
                      <v-icon>mdi-database-remove-outline</v-icon>

                      Sin datos
                    </div>
                    <custom-loading :loading="loadingForm" />
                  </v-card-text>
                </template>
              </material-card>
            </VCol>
          </VRow>
        </v-card>
      </v-tab-item>
    </template>

    <form-modal
      :open.sync="dialogForm"
      :form="dataToSave"
      :params="dataParams"
      :max-with="dataToSave.maxWidth"
      :force-initialize="forceInitializeForm"
      @loaded="handleLoadedForm"
      @click="handleClickModal"
      @edit="handleClickModal"
      @confirm="confirmModalAction"
    />
    <form-modal
      :open.sync="secondDialogForm"
      :form="secondDataToSave"
      :params="secondDataParams"
      :data-source="secondDataParams"
      @confirm="confirmSecondModalAction"
    />
  </v-tabs>
</template>

<script>
import { get } from 'vuex-pathify';
import jsons from '@/mixins/jsons';
export default {
	name: 'VerticalStep',
	components: {

	},
	mixins: [jsons],
	props: {
		entidades: {
			type: Array,
			default: function () {
				return [];
			}
		}

	},
	data () {
		return {
			forceInitialize: false,
			dialogForm: false,
			secondDialogForm:false,
			dataToSave: false,
			secondDataToSave: false,
			loading: true,
			dataBrowse: null,
			loadingForm: false,
			selectedKey: 0,
			dataParams: null,
			secondDataParams:null,
			forceInitializeForm:false
		};
	},
	computed: {
		dark: get('user/dark'),
		fieldRules () {
			const rules = [];
			var rule = (v) => !!v || 'Completar';
			rules.push(rule);

			return rules;
		}

	},

	mounted () {
		this.initialize();
	},

	methods: {
		handleLoaded () {
			this.forceInitialize = false;
		},
		handleLoadedForm(){
			this.forceInitializeForm = false;
		},
		handleClickModal(data){
			let eventForm=data?.value?.event?.form || data.event?.form;
			if(eventForm){
				this.resetSecondModal();

				const form = this.importJson(`${eventForm}.json`);
				this.secondDataToSave = form || false;
				this.secondDataParams={parent_uuid:this.dataParams?.data?.item?.uuid};
				if(data.item){
					this.secondDataParams={parent_uuid:this.dataParams?.data?.item?.uuid,...data.item};
				}
				this.secondDialogForm = true;
			}
		},
		async initialize () {
			this.loading = true;
			this.reset();

			const form = this.importJson(`${this.entidades?.[0]?.form}.json`);

			this.dataBrowse = form || null;
			this.loading = false;
		},
		handleClick (entidad, key) {
			this.selectedKey = key;
			this.forceInitialize = true;
			this.reset();
			const form = this.importJson(`${entidad.form}.json`);

			this.dataBrowse = form || null;
		},

		handleAdd (data) {
			this.reset();

			const form = this.importJson(`${data.event.form}.json`);

			this.dataToSave = form || false;
			this.dialogForm = true;
		},
		async handleEdit (data) {
			
			this.reset();
			if (data?.value?.event?.form) {
				this.loadingForm = true;

				const form = this.importJson(`${data.value?.event?.form}.json`);

				this.dataParams = { data};

				this.loadingForm = false;
				this.dataToSave = form || false;
				this.dialogForm = true;
				this.forceInitializeForm = true;
			}
		},
		confirmModalAction () {
			this.reset();
			this.forceInitialize = true;
		},
		confirmSecondModalAction(){
			this.resetSecondModal();
			this.forceInitializeForm = true;
		},
		reset () {
			this.dataParams = null;
			this.dataToSave = false;
		},
		resetSecondModal () {
			this.secondDataParams = null;
			this.secondDataToSave = false;
		}
	}
};
</script>

<style>
</style>
