<template>
  <div class="v-messages theme--light error--text">
    <div class="v-messages__wrapper">
      <div class="v-messages__message">
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: 'ErrorMsg',
	components: {},

	props: {
		msg: {
			type: String,
			default: ''
		}
	}
};
</script>

<style></style>
