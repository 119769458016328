<template>
  <div>
    <VMenu
      v-if="!readonly"
      v-model="menu"
      :close-on-content-click="false"
      min-width="auto"
      offset-y
      v-bind="{...position}"
      transition="scale-transition"
      z-index="204"
    >
      <template #activator="{on, attrs}">
        <VTextField
          v-model="computedFecha"
          v-mask="changing?'':timeRangeMask"
          :readonly="readonly || type === 'month'"
          color="insti"
          :rules="rules"
          :label="label"
          placeholder=""
          :hide-details="hideDetails"
          :clearable="!readonly"
          :single-line="singleLine"
          v-bind="attrs"
          append-icon="mdi-calendar"
          :hint="msgError"
          v-on="on"
          @keydown="changing=false"
          @change="changeInput"
          @click:clear="data=null"
        />
      </template>
      <VDatePicker
        v-model="data"
        v-bind="attrDepends"
        locale="es-AR"
        no-title
        :type="type"
        :readonly="readonly"
        @input="change"
      />
    </VMenu>
    <disabled-input
      v-else
      :value="computedFecha"
      :label="label"
    />
  </div>
</template>

<script>
import { dateHelpers, getToday, getDateFromParam } from '@/util/helpers';
export default {
	name: 'DatePicker',
	props: {
		attributes: {
			type: [String, Number, Boolean, Object, Array],
			default: null
		},
		vuetifyField: {
			type: [String, Number, Boolean, Object, Array],
			default: null
		},
		rules: {
			type: Array,
			default: function () {
				return [];
			}
		},
		model: {
			type: [Object],
			default: function () {
				return {};
			}
		},
		value: {
			type: [String, Number, Boolean, Object, Array],
			default: ''
		},
		format: {
			type: String,
			default: 'shortDate'
		},
		label: {
			type: String,
			default: 'Fecha'
		},
		readonly: {
			type: Boolean,
			default: false
		},
		singleLine: {
			type: Boolean,
			default: false
		},
		hideDetails: {
			type: [String,Boolean],
			default: false
		},
		field: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	data () {
		return {
			menu: false,
			data: '',
			changing: true,
			msgError: 'dd/mm/aaaa'
		};
	},
	computed: {

		computedFecha: {
			get () {
				if (this.type === 'month') {
					return dateHelpers.monthDate(this.data);
				}
				return dateHelpers[this.format](this.data);
			},

			set (newName) {
				return newName;
			}
		},
		position () {
			return this.attributes?.position ? { [this.attributes.position]: true } : { bottom: true };
		},

		type () {
			return this.vuetifyField?.formatDate ? this.vuetifyField.formatDate : 'date';
		},
		defaultValue(){
			let defaultValue='';

			if(this.field?.defaultValue==='today'){
				defaultValue=getToday();
			}
			if('defaultCustomValue' in this.field){
				defaultValue=getDateFromParam(this.field.defaultCustomValue);
			}
			return defaultValue;
		},
		attrDepends () {
			const attr = {
				min: '1900-01-01',
				max:'2060-01-01'
			};

			const date = new Date();

			const currentDay = date.getDate();
			const currentMonth = date.getMonth() + 1;
			const year = date.getFullYear();
			const day = String(currentDay).padStart(2, '0');
			const month = String(currentMonth).padStart(2, '0');
			const currentDate = `${year}-${month}-${day}`;

			if (this.attributes?.min) {
				const min = this.model?.[this.attributes.min];
				if (min) {
					attr.min = min;
				}
			}

			if (this.attributes?.max) {
				const max = this.model?.[this.attributes.max];
				if (max) {
					attr.max = max;
				}
			}
			if (this.attributes?.min === 'today') {
				attr.min = currentDate;
			}

			if (this.attributes?.max === 'today') {
				attr.max = currentDate;
			}
			return attr;
		}
	},
	watch: {
		data (val) {
			this.$emit('changeCustomInput', val);
		},
		value (value) {
			let date= value;
			if(value){
				date = value.split(' ')[0];
			}
			this.data = date;
		}

	},
	mounted () {
		this.data=this.defaultValue;
		if (this.type === 'month') {
			this.msgError = 'mm/aaaa';
		}
	},
	methods: {
		timeRangeMask (value) {
			const date = value.split('/');
			const cuantitySlash = (value.match(/\//g) || []).length;
			let day = [/^[0-3]?$/, /^[0-9]$/];
			let month = [/^[0-9]?$/, /^[0-9]?$/];
			const year = [/^[0-9]?$/, /^[09876]?$/, /^[0-9]?$/, /^[0-9]?$/];

			if (date[0]?.length === 1) {
				if (Number(date[0]) === 3) {
					day = [/^[0-3]?$/, /^[0-1]$/];
				}
				if (cuantitySlash > 0) {
					day = [/^[0-9]?$/, '/'];
				}
			} else if ((date[0]?.length === 2 && cuantitySlash > 0) || date[0]?.length === 3) {
				day = [/^[0-3]?$/, /^[0-9]$/, '/'];
				if (Number(date[0][0]) === 3) {
					day = [/^[0-3]?$/, /^[0-1]$/, '/'];
				}
			}

			if (date[1]?.length === 1) {
				if (Number(date[1]) > 1 || cuantitySlash === 2) {
					month = [/^[0-9]?$/];
					if (Number(date[1]) === 1) {
						month = [/^[0-9]?$/, /^[0-2]$/];
					}
					if (cuantitySlash > 1) {
						month = [/^[0-9]?$/, '/'];
					}
				}
			} else if ((date[1]?.length === 2 && cuantitySlash > 1) || date[1]?.length === 3) {
				month = [/^[0-9]?$/, /^[0-9]?$/, '/'];
				if (Number(date[1][0]) === 1) {
					month = [/^[0-9]?$/, /^[0-2]$/, '/'];
				}
			} else if (date[1]?.length === 2 && Number(date[1]) > 12) {
				month = [/^[0-9]?$/, '/'];
			}

			return [...day, ...month, ...year];
		},

		changeInput (value) {
			if (value) {
				const date = value.split('/');
				const day = String(date[0]).padStart(2, '0');
				const month = String(date[1]).padStart(2, '0');
				const year = date[2].length === 4 ? date[2] : '';
				const validDate = `${year}-${month}-${day}`;
				const timestamp = Date.parse(validDate);
				if (year.length === 4 && validDate && timestamp && !isNaN(timestamp)) {
					let valid = true;
					if (this.attrDepends?.min) {
						const timestampMin = Date.parse(this.attrDepends.min);
						if (timestamp < timestampMin) {
							valid = false;
						}
					}
					if (this.attrDepends?.max) {
						const timestampMax = Date.parse(this.attrDepends.max);
						if (timestamp > timestampMax) {
							valid = false;
						}
					}
					if (valid) {
						this.data = validDate;
						this.menu = false;
						this.changing = false;
						return;
					}
				}
			}

			this.changing = true;
			this.data = null;
			this.msgError = 'Fecha no válida';
			this.menu = false;
		},
		change () {
			this.changing = true;
			this.menu = false;
		}
	}
};
</script>

<style>
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
