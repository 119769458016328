<template>
  <VContainer fluid>
    <VRow justify="center">
      <VCol
        v-if="isTitle"
        :class="step.class"
        cols="11"
        :md="widthMd"
        :lg="widthLg"
        :xl="widthXl"
      >
        <custom-title
          :back-btn="true"
          class="px-0 py-0"
          subtitle=""
          v-bind="toAttributes(step.titleAttr)"
          :title="step.label"
        />

        <div
          v-if="!!step.description"
          class="text-body-1"
        >
          {{ step.description }}
        </div>
      </VCol>
      <VCol
        v-if="widgets.length"
        class="py-md-0 d-flex justify-end align-center"
        cols="12"
      >
        <template
          v-for="(widget,keyWidget) in widgets"
        >
          <component
            :is="widget.component"
            :key="`component_widget_${keyWidget}`"
            :data="data"
            :widget="widget"
            @reload-form="reloadForm"
          />
          <div
            v-if="checkAction(widget)"
            :key="`widget_${keyWidget}`"
            class="px-1"
          >
            <v-badge
              bordered
              color="success"
              :content="checkBadge(widget)"
              :value="!!checkBadge(widget)"
              overlap
            >
              <custom-btn
                ref="button"
                class="px-2"
                :attributes="{
                  'icon':true, 
                  elevation:0, 
                  'prepend-icon': widget.icon
                }"
                outlined
                custom-class="text-none"
                :tooltip="widget.title"
                @handleClick="checkWidgets(widget)"
              />
            </v-badge>
          </div>
        </template>
      </VCol>
      <VCol
        :class="step.class"
        cols="11"
        :md="widthMd"
        :lg="widthLg"
        :xl="widthXl"
      >
        <VForm
          :ref="formulario"
          class=""
          :disabled="disabled || isLoading || loading"
          @submit.prevent="submit"
        >
          <VRow
            v-if="localFields"
            class=""
            justify="start"
            :align="step.align?step.align:'end'"
          >
            <custom-loading :loading="loading && isCustomLoading" />
            <template
              v-if="!loading"
            >
              <template
                v-for="(field, key) in localFields"
              >
                <VCol
                  v-if=" String(field.hidden)!=='true'"
                  :key="`${key}localField`"
                  class="py-1"
                  :class="field.class"
                  cols="12"
                  :md="field.col ? field.col : '6'"
                >
                  <label
                    v-if="field.description && field.description != ''"
                    class="pl-4 mt-1 font-weight-regular darkGrey--text d-block"
                    v-html="field.description"
                  />

                  <custom-input
                    v-if="toVuetifyField(field.type)"
                    :field="field"
                    :status-modal="statusModal"
                    :local-form-fields="localFields"
                    :model="data"
                    :value="getValue(field)"
                    validate
                    :data-parent-model="dataParentModel"
                    :emit-submit="emitSubmit"
                    :events-bus="eventsBus"
                    @input="handleInputChange(field, $event)"
                    @onEnter="onEnter"
                    @onEsc="onEsc"
                    @reload-form="reloadForm"
                  />
                  <Component
                    :is="field.type"
                    v-else
                  >
                    {{ `${data[field.name] || field.labeling.label}` }}
                  </Component>
                </VCol>
              </template>
            </template>
            <v-skeleton-loader
              v-else
              class="w-100"
              boilerplate
              type="article, actions"
            />
          </VRow>
        </VForm>
      </VCol>
    </VRow>
    <VRow
      v-if="actions && actions.length && !loading"
      class="custom-footer"
      justify="center"
    >
      <VCol
        class="d-flex align-center pb-6 pt-12"
        :cols="12"
        :md="widthMd"
        :lg="widthLg"
        :xl="widthXl"
      >
        <VRow
          :justify="actions.length===1 ? 'end':'space-between'"
          style="width:100%"
        >
          <VCol
            v-for="(action, index) in actions"
            :key="`action-${index}`"
            :cols="actions.length===2 ? '6':'12'"
            :md="action.col ? action.col : 'auto'"
            class="pb-1 d-flex align-center"
            :class="index===actions.length-1?'justify-end':''"
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              :custom-class="`text-none`"
              :loading="isLoading || loading"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="handleClick(action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import functionalities from '@/mixins/functionalities';
import { toPathObject, generateStringFromVariables } from '@/util/helpers';
import { deepClone } from '@/util/objectHelpers';
import { checkAndFilterDepends } from '@/util/dataHelpers';
import { paramMapped, toAttributes, toVuetifyField } from '@/mappers/form';
import { genericRequest } from '@/api/modules';
import { isError } from '@/api/errors';
import { get, sync,call } from 'vuex-pathify';
import Vue from 'vue';

export default {
	name: 'CustomForm',
	components: {},
	filters: {},
	mixins: [functionalities],
	props: {
		form: {
			type: Object,
			default: () => ({})
		},
		isTitle: {
			type: Boolean,
			default: false
		},
		widthMd: {
			type: [Number, String],
			default: 11
		},
		widthLg: {
			type: [Number, String],
			default: 11
		},
		widthXl: {
			type: [Number, String],
			default: 11
		},
		validate: {
			type: Boolean,
			default: false
		},
		isSendForm: {
			type: Boolean,
			default: true
		},
		formulario: {
			type: String,
			default: 'form'
		},
		btnEnviarTitle: {
			type: String,
			default: 'Enviar'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		isCustomLoading: {
			type: Boolean,
			default: true
		},
		statusModal: {
			type: Boolean,
			default: null
		},
		dataSource: {
			type: [Object, Boolean],
			default: () => false
		},
		dataParentModel: {
			type: [Object, Boolean],
			default: () => false
		},
		dataParams: {
			type: [Object, Boolean],
			default: () => false
		}
	},
	data() {
		return {
			emitSubmit: false,
			data: {},
			loading: false,
			toPathObject,
			isLoading: false,
			toAttributes,
			toVuetifyField,
			eventsBus: new Vue(),
			originalData: {},
			localFields:[],
			currentModule:{}
		};
	},
	computed: {
		params: get('route/params'),
		queryParams: get('route/query'),
		paramsData() {
			return { ...this.queryParams,...this.params, uuid: this.params.id,subUuid:this.params.task };
		},

		step(){
			if ('steps' in this.form) {
				return Array.isArray(this.form.steps) ? this.form.steps[0]:this.form.steps;
			}
			return this.form;
		},
		fields(){
			return this.step?.fields?.length?this.step.fields:[];
		},
		widgets(){
			return this.form?.widgets?.length?this.form.widgets:[];
		},
		actions(){
			let actions=this.step.actions?.length?this.step.actions:[];

			if ('actions' in this.step && !Array.isArray(this.step.actions)) {
				actions = [this.step.actions];
			}
			return actions.filter(e=>String(e.hidden)!=='true');
		},
		route: get('route'),
		...sync('app', ['drawerComentarios', 'drawerBlocks'])
	},
	watch: {
		statusModal(val) {
			if (!val) {
				this.data = {};
				this.resetForm();
			} else {
				this.eventsBus.$emit('openForm');
				this.init();
			}
		},
		form(val) {
			this.data = {};
			this.resetForm();
			if (val && !this.loading) {
				this.init();
			}
		},
		dataParams(val) {
			this.resetForm();
			if (val && !this.loading) {
				this.init();
			}
		},
		dataSource() {
			this.setOriginalData();
		}
	},
	async mounted() {
		await this.init();
		this.eventsBus.$emit('openForm');
		this.currentModule=await this.getCurrentModule();
	},
	methods: {
		getValue(field){
			const isMultiple=String(field?.multiple) === 'true';
			let value=field.property ? toPathObject(this.data,field.property):this.data[field.name];

			if(isMultiple && 'key' in field && Array.isArray(value)){
				value = value.map(e=>e[field.key]);
			}
			return value;
		},
		resetForm(){
			this.$refs[this.formulario].reset();
		},
		getCurrentModule: call('user/getCurrentModule'),
		checkAction(object){
			const isValid= this.checkFunctionality(object, this.currentModule);
			return isValid;
		},
		async init(){
			this.loading = true;
			this.localFields=[];
			await this.initializeForm();
			if (this.form?.title) {
				this.$emit('changeTitle', this.data);
			}
		},
		async initializeForm() {
			this.loading = true;
			this.checkForm();
			await this.fetchFormSource();
			this.setOriginalData();
			this.loading = false;
			if(this.$refs[this.formulario]){
				this.$refs[this.formulario].resetValidation();
			}
			

			this.$emit('loaded');
		},
		reloadForm (data) {
			const { response: { resource }, field } = data;
			this.data = { ...this.data, [field.name]: resource[field.name] };
			this.checkForm();
		},
		async fetchFormSource() {
			if (this.step?.source) {
				const { data: source, dataMock } = this.step.source;

				if (source?.href) {
					const url = generateStringFromVariables({
						title: source.href,
						data: this.getFetchParams()
					});

					const { resource } = await genericRequest({
						url,
						method: source.method,
						service: source.service,
						checkTokenStatus: source.checkTokenStatus
					});
					this.processFormFields(resource);
				}

				if (dataMock) {
					this.data = dataMock;
				}
			}
		},
		getFetchParams() {
			let params = this.paramsData;
			if (this.dataParams) {
				if (this.dataParams?.data?.item) {
					params = { ...params, ...this.dataParams.data.item };
				} else {
					params = { ...params, ...this.dataParams };
				}
			}
			return params;
		},
		processFormFields(resource) {
			if (this.fields?.length && resource) {
				this.fields.forEach(field => {
					const isMultiple=String(field?.multiple) === 'true';
					if (!(field?.name in resource)) {
						resource[field.name] = null;
						if('property' in field){
							resource[field.name] = toPathObject(resource, field.property);
						}
						
					}

					if('sourceValueName' in field && isMultiple){
						resource[field.sourceValueName]=Object.values(resource[field.sourceValueName]);
					}
						
					let customValue=resource[field.name];
					if ('property' in field) {
						customValue= toPathObject(resource, field.property);
					}

					if(isMultiple && 'key' in field && Array.isArray(customValue)){
						customValue = customValue.map(e=>e[field.key]);
					}

					if ('object' in field && customValue ) {
						resource[field.name][field.object] = customValue;
					} else if ('object' in field && !customValue && isMultiple) {
						resource[field.name][field.object]  = [];
					}else if (!('object' in field) && !customValue && isMultiple) {
						resource[field.name] = [];
					}else if (!('object' in field) && customValue) {
						resource[field.name] =  customValue;
					}
				});
			}
			this.data = resource;
		},
		setOriginalData() {
			this.data = { ...this.route?.query, ...this.data };
			if (this.dataSource) {
				this.data = { ...this.data, ...this.dataSource };
			}
			this.originalData = deepClone(this.data);
		},
		checkForm() {
			const {fields, inputsHidden} = checkAndFilterDepends({fields:this.fields,data:deepClone(this.data)});

			inputsHidden.forEach(field => {
				this.handleInputChange(field, null,false);
			});
			
			this.localFields=fields;
		},
		async sendForm(action) {
			let response = null;
			this.emitSubmit = true;
			this.eventsBus.$emit('validation', true);
			if (!this.validate || this.checkValidate()) {
				this.$emit('isLoading', true);

				if (action?.submit?.href && this.isSendForm) {
					this.isLoading = true;
					try {
						const url = generateStringFromVariables({
							title: action.submit.href,
							data: { ...this.paramsData, ...this.data }
						});
						const params = paramMapped(action?.submit?.parameters, this.data, this.fields, this.originalData, action?.submit);

						response = await genericRequest({
							url,
							method: action.submit.method,
							params,
							service: action.submit.service,
							checkTokenStatus: action.submit.checkTokenStatus
						});

						if (response && !isError(response.response?.status)) {
							if (action?.redirect?.href) {
								if (action?.redirect?.service === 'local') {
									const urlRedirect = generateStringFromVariables({
										title: action.redirect.href,
										data: { ...this.paramsData, ...response.resource }
									});
									this.$router.push({ path: urlRedirect || '/' }).catch((error) => {
										console.log(error.message);
									});
								}

								return;
							} else {
								this.$emit('submit', { data: this.data, response });
							}

							if (action.submit?.action) {
								this.$emit(action.submit.action, { data: this.data, response });
							}

							if (action.submit.reset) {
								this.resetForm();
							}
						} else {
							this.$emit('error');
						}
					} catch (error) {
						this.isLoading = false;
						this.$emit('error');
					} finally {
						this.isLoading = false;
					}
				} else {
					const _data = deepClone(this.data);
					if (action?.submit?.action) {
						this.$emit(action.submit.action, { data: _data, response });
					}

					this.$emit('submit', { data: _data, response, action });
					if (action?.submit?.reset) {
						this.resetForm();
					}
				}
			}
			this.$emit('isLoading', false);
		},
		handleClick(action) {
			if (!action?.submit && action?.redirect?.href) {
				if (action?.redirect?.service === 'local') {
					const url = generateStringFromVariables({
						title: action.redirect.href,
						data: { ...this.paramsData, ...this.data }
					});
					this.$router.push({ path: url || '/' }).catch((error) => {
						console.log(error.message);
					});
				}
			} else if (action.event?.action && action?.event?.action !== 'submit') {
				if (action.event.reset) {
					this.resetForm();
				}

				this.$emit(action.event.action, action);
			} else if (action?.submit || action?.event?.action === 'submit') {
				this.sendForm(action);
			}
		},
		onEnter() {
			this.submit();
		},
		onEsc(){
			this.$emit('escape');
		},
		checkBadge(widget) {
			return widget.badge && this.data?.[widget.field]?.length && this.data?.[widget.field].filter(element => !element.deleted_at && element.active === 1).length;
		},
		submit() {
			if (this.actions?.length) {
				const findSubmit = this.actions.filter(element => 'submit' in element);
				if (findSubmit.length === 1) {
					this.handleClick(findSubmit[0]);
				}
			}
		},
		checkWidgets(widget) {
			const {event,href} = widget;
			if(event==='open-link'){
				const url = generateStringFromVariables({
					title: href,
					data: this.data
				});
				this.$router.push({ path: url || '/' }).catch((error) => {
					console.log(error.message);
				});
				return;
			}
			this[event] = true;
		},
		checkValidate() {
			let response = this.$refs[this.formulario].validate();
			this.fields.forEach(element => {
				if (element.type === 'file' && String(element.required) === 'true') {
					if (!this.data?.[element.name]) {
						response = false;
					}
				}
				if (element?.type?.includes('otp') && String(element.required) === 'true') {
					if (!this.data?.[element.name]) {
						response = false;
					}
				}
			});
			return response;
		},
		handleInputChange(field, value, reloadValidation=true) {
			if (this.statusModal || this.statusModal === null) {
				if (String(field.multiple) === 'true' && (!this.data[field.name] || !this.data[field.name]?.length)) {
					this.data[field.name] = [];
				}
				if ('object' in field) {
					this.data[field.name] = this.data[field.name] ? { ...this.data[field.name], [field.object]: value } : { [field.object]: value };
					this.eventsBus.$emit('change', field);
				} else {
					if (this.data[field.name] !== value) {
						this.data[field.name] = value;
						this.eventsBus.$emit('change', field);
					}
				}

				if(reloadValidation){
					this.apiValidation(field, value);
					this.checkForm();
				}
			}
		},
		apiValidation(field, value) {
			this.$set(field, 'error', false);
			if (field.customValidations?.length) {
				const apiValidation = field.customValidations.find(element => element.validation === 'apiValidation');
				if (apiValidation && value?.length >= apiValidation.data.minLength) {
					const params = {};
					apiValidation.data.params.forEach(param => {
						params[param] = this.data[param];
					});
					this.isLoading = true;
					genericRequest({
						url: apiValidation.data.endpoint,
						method: apiValidation.data.method,
						params,
					})
						.then(({ resource }) => {
							this.$set(field, 'error', !resource);
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			}
		},
	}
};
</script>