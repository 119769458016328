<template>
  <VRow
    align="center"
    justify="start"
    class="py-1"
  >
    <VCol cols="auto">
      <div
        v-if="label"
        class="text-headline"
        :class="{'cursor-pointer':showHistory}"
        @click="showHistory? showHistoryEvent():false"
      >
        {{ label }}
      </div>
      <div>
        <v-btn
          v-if="linkValue && linkValue!=='-'"
          text
          :href="linkValue"
          color="blue"
          class="text-headline font-weight-negrita text-none pa-0"
        >
          {{ textValue }}
        </v-btn>
      </div>
    </VCol>
    <div
      v-if="edit"
      cols="auto"
      class="px-1"
    >
      <custom-btn
        :attributes="{icon:true, 'x-small':true, 'prepend-icon':editIcon}"
        :tooltip="editTooltip"
        @handleClick="handleEdit"
      />
    </div>
    <div
      v-if="showHistory"
      cols="auto"
      class="px-1"
    >
      <custom-btn
        :attributes="{icon:true,'x-small':true, 'prepend-icon':showIcon}"
        :tooltip="showTooltip"
        @handleClick="showHistoryEvent"
      />
    </div>
    <form-modal
      :open.sync="dialogForm"
      max-width="700px"
      :form="form"
      :items="items"
      @confirm="confirmModalAction"
    />
  </VRow>
</template>

<script>
import { generateStringFromVariables } from '@/util/helpers';
import tableMapper from '@/mappers/tables';

export default {
	name: 'CustomInputEditBrowseLink',
	props: {
		value: {
			type: [Number, String, Boolean, Array, Object],
			default: function () {
				return '';
			}
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		label: {
			type: [String, null],
			default: ''
		},
		editIcon: {
			type: [String, null],
			default: 'pencil'
		},
		editTooltip: {
			type: [String, null],
			default: 'Editar'
		},
		model: {
			type: Object,
			default: function () {
				return {};
			}
		},
		showTooltip: {
			type: [String, null],
			default: 'Ver histórico'
		},
		showIcon: {
			type: [String, null],
			default: 'history'
		},
		edit: {
			type: Boolean,
			default: false
		},
		showHistory: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			dialogForm: false,
			form: false,
			items: []
		};
	},
	computed: {
		textValue () {
			let txt = null;
			if (this.value) {
				txt = generateStringFromVariables({ title: this.field.title, data: this.value });
			}
			return txt !== 'null' ? txt || '-' : '-';
		},

		linkValue () {
			let txt='';

			if (this.field?.data?.link) {
				txt = generateStringFromVariables({ title: this.field.data.link, data: this.value });
			}

			return txt;
		}
	},
	methods: {
		confirmModalAction (data) {
			this.$emit('reload-form', { ...data, field: this.field });
			this.reset();
		},
		reset () {
			this.items = [];
			this.form = false;
		},
		handleEdit () {
			this.reset();

			let form = null;

			try {
				if (this.field?.form?.edit) {
					form = require(`@/jsons/${this.field.form.edit}.json`);
				}
			} catch {
				console.log('No se encontró el json');
			}

			this.form = form || false;
			this.dialogForm = !!form;
		},
		showHistoryEvent () {
			this.reset();

			let form = null;
			try {
				if (this.field?.form?.history) {
					form = require(`@/jsons/${this.field.form.history}.json`);
					if (form) {
						form = tableMapper(form);
						this.items = this.value;
					}
				}
			} catch (e) {
				console.log('No se encontró el json');
			}

			this.form = form || false;
			this.dialogForm = !!form;
		}
	}
};

</script>

<style>

</style>
