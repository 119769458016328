import { getMonthlyDatesFromNextMonth,formatDateToYYYYMMDD } from '@/util/helpers';

export const simuladorCuotas = ({ quantity, amount }) => {
	const amounts = [];
	const dates = getMonthlyDatesFromNextMonth(quantity);
	// const monthAmount = amount / quantity;

	for (let i = 0; i < quantity; i++) {
		const _monthAmount = { date: formatDateToYYYYMMDD(dates[i]), amount: amount };
		amounts.push(_monthAmount);
	}

	return amounts;
};
