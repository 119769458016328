<template>
  <div>
    <VMenu
      v-if="!readonly"
      v-model="menu"
      :close-on-content-click="false"
      min-width="auto"
      offset-y
      v-bind="{...position}"
      transition="scale-transition"
      z-index="204"
    >
      <template #activator="{on, attrs}">
        <VTextField
          v-model="data"
          v-mask="changing?'':timeRangeMask"
          :readonly="readonly"
          color="insti"
          :rules="rules"
          :label="label"
          placeholder=""
          :hide-details="hideDetails"
          :clearable="!readonly"
          v-bind="attrs"
          append-icon="mdi-clock-time-four-outline"
          :hint="msgError"
          v-on="on"
          @keydown="changing=false"
          @change="changeInput"
        />
      </template>
      <v-time-picker
        ref="timePicker"
        v-model="data"
        v-bind="attr"
        locale="es-AR"
        no-title
        active-picker="HOUR"
        format="24hr"
        :readonly="readonly"
        @click:minute="selectingTime"
        @input="change"
      />
    </VMenu>
    <disabled-input
      v-else
      :value="data"
      :label="label"
    />
  </div>
</template>

<script>
export default {
	name: 'TimePicker',
	props: {
		attributes: {
			type: [String, Number, Boolean, Object, Array],
			default: null
		},
		vuetifyField: {
			type: [String, Number, Boolean, Object, Array],
			default: null
		},
		rules: {
			type: Array,
			default: function () {
				return [];
			}
		},
		model: {
			type: [Object],
			default: function () {
				return {};
			}
		},
		value: {
			type: [String, Number, Boolean, Object, Array],
			default: ''
		},
		label: {
			type: String,
			default: 'Hora'
		},
		readonly: {
			type: Boolean,
			default: false
		},
		hideDetails: {
			type: [String,Boolean],
			default: false
		},
		field: {
			type: Object,
			default: function () {
				return {};
			}
		},
		eventsBus: {
			type: Object,
			default: function () {
				return {};
			}
		},
	},
	data () {
		return {
			menu: false,
			data: '',
			attr: {},
			changing: true,
			msgError: 'hh:mm'
		};
	},
	computed: {
		position () {
			return this.attributes?.position ? { [this.attributes.position]: true } : { bottom: true };
		}
	},
	watch: {
		data (val) {
			this.$emit('changeCustomInput', val);
		},
		value (val) {
			this.data = val;
		}
	},
	mounted () {


	},
	methods: {
		selectingTime() {
			this.$nextTick(() => {
				this.$refs.timePicker.selectingHour = true;
				this.menu=false;
			});
		},
		timeRangeMask (value) {
			const time = value.split(':');
			const cuantitySlash = (value.match(/:/g) || []).length;
			let hour = [/^[0-2]?$/, /^[0-9]$/];
			let minute = [/^[0-5]?$/, /^[0-9]?$/];

			if (time[0]?.length === 1) {
				if (Number(time[0]) === 2) {
					hour = [/^[0-2]?$/, /^[0-3]$/];
				}
				if (cuantitySlash === 1) {
					hour = [/^[0-2]?$/, ':'];
				}
			} else if ((time[0]?.length === 2 && cuantitySlash === 1) || time[0]?.length === 3) {
				hour = [/^[0-2]?$/, /^[0-9]$/, ':'];
			}

			return [...hour, ...minute];
		},

		changeInput (value) {
			if (value) {
				const time = value.split(':');
				const hour = time[0].length === 2 ? time[0] : `0${time[0]}`;
				const minute = time[1].length === 2 ? time[1] : `0${time[1]}`;
				const validTime = `${hour}:${minute}`;
				this.data = validTime;
				this.menu = false;
				this.changing = false;
				return;
			}

			this.changing = true;
			this.data = null;
			this.msgError = 'Hora no válida';
			this.menu = false;
		},
		change () {
			this.changing = true;
		}
	}
};
</script>

<style>

</style>
