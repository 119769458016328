<template>
  <VRow>
    <custom-loading :loading="loading" />

    <VCol cols="12" class="px-md-5 mt-4">
      <VRow>
        <VCol cols="6" md="3">
          <DatePicker
            label="Desde"
            hide-details
            :field="{defaultCustomValue:'-3m'}"
            :model="filters"
            :readonly="forceInitialize || loadingAction"
            :attributes="{max:'to'}"
            @changeCustomInput="handleInputChange('from', $event)"
          />
        </VCol>
        <VCol cols="6" md="3">
          <DatePicker
            label="Hasta"
            hide-details
            :field="{defaultValue:'today'}"
            :readonly="forceInitialize || loadingAction"
            :attributes="{min:'from'}"
            :model="filters"
            @changeCustomInput="handleInputChange('to', $event)"
          />
        </VCol>
      </VRow>
      <div class="mt-3 ">
        <span v-if="!mainFilters" class="text-subtitle-2 font-weight-negrita">
          Para visualizar el balance de un período, debe seleccionar un rango de fechas
        </span>
      </div>
    </VCol>
    <VCol v-if="!showConsolidation" cols="12" class="d-flex justify-end">
      <custom-btn
        tooltip="Conciliación débitos - créditos"
        label="Conciliación"
        :attributes="{
          color:'primary',
          'prepend-icon':'cash-sync'
        }"
        @handleClick="handleEditConsolidation"
      />
    </VCol>

    <VCol cols="12" :md="firstColumn.cols || '6'">
      <app-card class="pa-4" elevation="2">
        <div
          v-if="firstColumn.title"
          class="text-subtitle-1 font-weight-negrita mb-md-n3"
        >
          {{ firstColumn.title }}
        </div>
        
        <component
          :is="firstColumn.type"
          :form="firstColumn"
          validate
          :selected-items.sync="selectedItemsFirstColumn"
          :search-disabled="!mainFilters"
          :force-initialize="forceInitialize"
          v-bind="toAttributes(firstColumn.attr)"
          :external-query="mainFilters?filters:{}"
          :parent-data="data"
          :show-select="showConsolidation"
          @add="handleAdd"
          @loaded="handleLoaded"
        />
      </app-card>
    </VCol>
    <VCol cols="12" :md="secondColumn.cols || '6'">
      <app-card class="pa-4" elevation="2">
        <div
          v-if="secondColumn.title"
          class="text-subtitle-1 font-weight-negrita mb-md-n3"
        >
          {{ secondColumn.title }}
        </div>
        <component
          :is="secondColumn.type"
          :form="secondColumn"
          validate
          :selected-items.sync="selectedItemsSecondColumn"
          :search-disabled="!mainFilters"
          :force-initialize="forceInitialize"
          v-bind="toAttributes(secondColumn.attr)"
          :external-query="mainFilters?filters:{}"
          :parent-data="data"
          :show-select="showConsolidation"
          @add="handleAdd"
          @loaded="handleLoaded"
        />
      </app-card>
    </VCol>
    <VCol id="consolidationAnchor" cols="12">
      <app-card v-if="showConsolidation" class="pa-4" elevation="2">
        <div
          class="text-subtitle-1 font-weight-negrita"
        >
          Conciliación
        </div>
        <VRow>
          <VCol
            cols="12"
            class="text-caption"
          >
            Seleccionar débitos y créditos
          </VCol>
          <VCol cols="6">
            <div
              class="text-subtitle-2"
            >
              Débitos
            </div>
            <template v-if="selectedItemsFirstColumn.length">
              <v-list-item v-for="selectedItem in selectedItemsFirstColumn" :key="selectedItem.uuid" two-line dense>
                <v-list-item-content class="py-0">
                  <v-list-item-title>{{ selectedItem.type.code }} ({{ selectedItem.month }}-{{ selectedItem.year }}) </v-list-item-title>
                  <v-list-item-subtitle>{{ formatter({ value: selectedItem.amount, formatter: 'decimalDoublePrice',item:selectedItem }) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </VCol>

          <VCol cols="6">
            <div
              class="text-subtitle-2"
            >
              Créditos
            </div>
            <template v-if="selectedItemsSecondColumn.length">
              <v-list-item v-for="selectedItem in selectedItemsSecondColumn" :key="selectedItem.uuid" two-line dense>
                <v-list-item-content class="py-0">
                  <v-list-item-title>{{ selectedItem.type.code }} ({{ selectedItem.month }}-{{ selectedItem.year }})</v-list-item-title>
                  <v-list-item-subtitle>{{ formatter({ value: selectedItem.amount, formatter: 'decimalDoublePrice',item:selectedItem }) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </VCol>
          <VCol cols="12">
            <div
              class="text-subtitle-2 font-weight-negrita text-right"
            >
              Total conciliación
              <span class="text-caption font-weight-negrita">
                (Créditos - Débitos)
              </span> 
              :
              <span class="text-subtitle-1 font-weight-negrita" :class="{'primary--text':totalConciliaAmount>=0,'error--text':totalConciliaAmount<0}">
                {{ formatter({ value: totalConciliaAmount, formatter: 'decimalDoublePrice' }) }}
              </span> 
            </div>
          </VCol>
          <VCol cols="12">
            <VRow justify="end">
              <VCol cols="auto">
                <custom-btn
                  tooltip="Cancelar Conciliación"
                  label="Cancelar"
                  :attributes="{
                    outlined:true,
                    disabled: loadingAction
                  }"
                  @handleClick="handleCancelConsolidation"
                />
              </VCol>
              <VCol cols="auto">
                <custom-btn
                  tooltip="Confirmar Conciliación"
                  label="Confirmar"
                  :attributes="{
                    color:'success',
                    'prepend-icon':'cash-check',
                    disabled:!validateSubmit || loadingAction,
                    loading:loadingAction
                  }"
                  @handleClick="submitConciliacion"
                />
              </VCol>
            </VRow>
          </VCol>
          <custom-loading :loading="loadingAction" absolute />
        </VRow>
      </app-card>
    </VCol>    
    <form-modal
      :open.sync="dialogForm"
      :form="dataToSave"
      :params="data"
      :max-with="dataToSave.maxWidth"
      @confirm="forceInitialize=true"
    />
  </VRow>
</template>

<script>
import { toAttributes } from '@/util/helpers';
import jsons from '@/mixins/jsons';
import {generateStringFromVariables } from '@/util/helpers';
import { genericRequest } from '@/api/modules';
import { get } from 'vuex-pathify';
import anchors from '@/mixins/anchors';
import formatter from '@/mixins/formatter';

export default {
	name: 'LayoutCtaCte',
	components: {},
	mixins: [jsons,anchors,formatter],
	props: {
		form: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},

	data() {
		return {
			toAttributes,
			loading: true,
			loadingAction:false,
			forceReload: false,
			data:null,
			forceInitialize: false,
			filters:{
				from:null,
				to:null
			},
			selectedItemsFirstColumn:[],
			selectedItemsSecondColumn:[],
			dataToSave: false,
			dialogForm: false,
			showConsolidation:false
		};
	},

	computed: {
		params: get('route/params'),
		queryParams: get('route/query'),
		totalConciliaAmount(){
			const sumFirstColumn = this.selectedItemsFirstColumn.filter(item => !item.is_percentage).reduce((total, item) => total + Number(item.amount), 0);
			const sumSecondColumn = this.selectedItemsSecondColumn.filter(item => !item.is_percentage).reduce((total, item) => total + Number(item.amount), 0);
			return sumSecondColumn - sumFirstColumn;
		},
		consiliacionUuids(){
			return {
				credits_uuids:this.selectedItemsSecondColumn.map(item => item.type.uuid),
				debits_uuids:this.selectedItemsFirstColumn.map(item => item.type.uuid),
			};
		},
		validateSubmit(){
			return this.selectedItemsFirstColumn.length && this.selectedItemsSecondColumn.length;
		},
		mainFilters(){
			return !!(this.filters.from && this.filters.to);
		},
		firstColumn() {
			return this.form.columns[0] || {};
		},
		secondColumn() {
			return this.form.columns[1] || {};
		},
		paramsData() {
			return { ...this.queryParams,...this.params,uuid:this.params.id };
		}
	},
	async mounted() {
		await this.fetchFormSource();
		this.loading=false;
	},

	methods: {
		async submitConciliacion(){
			this.loadingAction=true;
			const { data: source } = this.form.source;
			const url = generateStringFromVariables({
				title: '/affiliations-admin/persons/{uuid}/consolidations',
				data: this.paramsData
			});

			await genericRequest({
				url,
				method: 'post',
				service: source.service,
				checkTokenStatus: source.checkTokenStatus,
				params: this.consiliacionUuids
			});
			this.resetItemsSelected();
			this.showConsolidation=false;
			this.loadingAction=false;
			this.forceInitialize=true;
		},
		resetItemsSelected(){
			this.selectedItemsFirstColumn=[];
			this.selectedItemsSecondColumn=[];
		},
		handleCancelConsolidation(){
			this.resetItemsSelected();
			this.showConsolidation=false;
		},
		handleEditConsolidation(){
			this.resetItemsSelected();
			this.showConsolidation=true;
			this.toAnchor({id:'consolidationAnchor', offset:-250});
		},
		handleInputChange(input,value){
			this.$set(this.filters,[input],value);
			if(!this.loading){
				this.forceInitialize=true;
			}
			
		},
		handleBack(){
			this.$router.go(-1);
		},
		init(){
			if (this.form?.title) {
				this.$emit('changeTitle', this.data);
			}
		},
		handleLoaded(){
			this.forceInitialize=false;
		},
		handleAdd (data) {
			this.reset();

			const form = this.importJson(`${data.event.form}.json`);

			this.dataToSave = form || false;
			this.dialogForm = true;
		},
		reset () {
			this.dialogForm = false;
			this.dataToSave = false;
		},
		async fetchFormSource() {
			if (this.form?.source) {
				const { data: source, dataMock } = this.form.source;
				if (dataMock) {
					this.data = dataMock;
					return;
				}
				if (source?.href) {
					const url = generateStringFromVariables({
						title: source.href,
						data: this.paramsData
					});

					const { resource } = await genericRequest({
						url,
						method: source.method,
						service: source.service,
						checkTokenStatus: source.checkTokenStatus
					});
					this.data=resource;
				}
		
			}
			if (this.form?.title) {
				this.$emit('changeTitle', this.data);
			}
			this.forceInitialize=true;
		},
	},
};
</script>
