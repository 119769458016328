<template>
  <div :class="customClass">
    <div
      v-if="label"
      class="text-headline text-none "
    >
      {{ label }}
    </div>
    <div class="text-subtitle-1">
      <v-img
        :src="image"
        width="130px"
      />
    </div>
  </div>
</template>

<script>
const defaultAvatar = require('@/assets/avatar.png');
export default {
	name: 'DisabledPhoto',
	props: {
		value: {
			type: [Number, String, null, Array],
			default: function () {
				return '';
			}
		},
		label: {
			type: [String, null],
			default: ''
		},
		field: {
			type: [Object],
			default: function () {
				return {};
			}
		}
	},
	data () {
		return {
			image: null
		};
	},
	computed: {
		customClass () {
			return this.field?.style?.class;
		}
	},
	watch: {
		value () {
			this.loadImg();
		}},
	mounted () {
		this.loadImg();
	},
	methods:{
		async loadImg(){
			let image=defaultAvatar;
			if(this.value){
				const isValid= await this.isURLValidImage(this.value);
				if(isValid){
					image=this.value;
				}

			}

			this.image= image;
		},
		isURLValidImage(url) {
			return new Promise(function(resolve) {
				var img = new Image();
				img.onload = function() {
					resolve(true);
				};
				img.onerror = function() {
					resolve(false);
				};
				img.src = url;
			});
		}
	}
};
</script>

<style>

</style>
