<template>
  <vertical-step :entidades="items" />
</template>

<script>

export default {
	name: 'VerticalSteps',
	components: {

	},
	props: {

		form: {
			type: Object,
			default: function () {
				return { headers: [] };
			}
		}
	},

	data () {
		return {

		};
	},
	computed: {
		items () {
			return this.form?.source?.dataMock?.length ? this.form.source.dataMock : [];
		}
	},

	methods: {

	}
};
</script>

<style lang="scss">
    .no-icon{
        .v-stepper__step__step{
            display: none !important;
        }
    }
</style>
