<template>
  <VRow justify="center" class="pb-10">
    <VCol cols="12">
      <VRow class="mx-2" justify="center">
        <VCol cols="12">
          <div v-if="!parentData" class="text-subtitle-1 text-center">
            {{ form.title || 'Seleccionar afiliado' }}
          </div>
          <VRow v-else align="center" justify="start">
            <VCol cols="12" class="pb-md-0">
              <div class="text-subtitle-1 font-weight-negrita">
                {{ parentData.firstname }} {{ parentData.lastname }}
              </div>
              <div v-if=" parentData.identification_type" class="text-subtitle-2 font-weight-medium">
                {{ parentData.identification_type.name }}:
                {{
                  formatter({ value: parentData.idnumber, formatter: "idnumber" })
                }}
              </div>
            </VCol>
  
            <VCol v-for="(_form, keyForm) in form.forms" :key="keyForm" cols="12">
              <div class="text-subtitle-2 font-weight-negrita">
                {{ generateStringFromVariables({title: _form.title,data:parentData}) }}
              </div>
              <component
                :is="_form.type"
                v-show="!loading"
                :form="_form"
                :parent-data="parentData"
                :loading.sync="loading"
                :force-initialize="forceInitialize"
                v-bind="toAttributes(_form.attr)"
                item-key="uuid"
                @loaded="handleLoaded"
                @add="handleAdd"
                @edited="$emit('modify')"
                @showCrud="$emit('showCrud',$event)"
                @editCrud="$emit('editCrud',$event)"
                @cancel="$emit('cancel')"
                @clickRow="$emit('clickRow',$event);"
              />
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCol>
    <form-modal
      :open.sync="addRegister"
      :form="formAddRegister"
      persistent
      :data-source="{ person_uuid: parentData.uuid,parent_uuid: parentData.uuid }"
      @confirm="handleAddRegisterConfirm"
      @cancel="handleCancel"
    />
  </VRow>
</template>
  
<script>
import { toAttributes,generateStringFromVariables } from '@/util/helpers';
import { sync } from 'vuex-pathify';
import formatter from '@/mixins/formatter';
import jsons from '@/mixins/jsons';

export default {
	name: 'SelectedPersonAction',
	mixins: [formatter, jsons],
	props: {
		parentData: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		},
		forceReload: {
			type: Boolean,
			default: function () {
				return false;
			},
		},
		form: {
			type: [Object],
			default: function () {
				return {forms:[]};
			},
		}
	},
	data() {
		return {
			generateStringFromVariables,
			toAttributes,
			forceInitialize: false,
			formAddRegister: null,
			addRegister: false,
			loading:false
		};
	},
	computed: {
		alertTxt: sync('app/alertTxt'),
		alert: sync('app/alert')
	},
	watch: {
		parentData (val) {
			if (val) {
				this.forceInitialize = true;
			}
		},
		loading(value){
			this.$emit('update:loading',value);
		},
		forceReload(val) {
			if (val) {
				this.$emit('update:forceReload',false);
				this.forceInitialize = true;
			}
		},
	},
	mounted() {},
	methods: {
		handleLoaded () {
			this.forceInitialize = false;
		},
		handleAdd(data) {
			this.formAddRegister = null;
			const form = this.importJson(`${data.event.form}.json`);
			if (form) {
				this.formAddRegister = form;
				this.addRegister = true;
			}
		},
  
		handleCancel() {
			this.formAddRegister = null;
			this.addRegister = false;
		},
		async handleAddRegisterConfirm() {
			this.forceInitialize = true;
			this.formAddRegister = null;
			this.addRegister = false;
			this.alertTxt = {
				timeout: 3700,
				type: 'success',
				txt: 'Registro insertado con éxito',
			};
			this.alert = true;
			this.$emit('modify');
		},
	},
};
</script>
  
  <style></style>
  