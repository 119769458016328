<template>
  <div>
    <VRow
      class=""
      justify="center"
    >
      <VCol
        cols="12"
        md="10"
        lg="9"
        xl="8"
      >
        <VForm
          ref="functionality-module"
          class=""
          :disabled="loading"
        >
          <VRow
            class="pt-2"
            justify="center"
          >
            <VCol
              cols="12"
              md="5"
            >
              <custom-input
                :field="{name:'functionalityName',labeling:{label:'Nombre de la funcionalidad'},title:'Nombre de la funcionalidad',type:'string:75',required:true}"
                :value="functionalityName"
                validate
                :tooltip="'Nombre'"
                @input="handleInputChange({name:'functionalityName'}, $event)"
                @onEnter="handleClickAdd"
              />
            </VCol>
            <VCol
              cols="10"
              md="5"
            >
              <custom-input
                :field="{
                  name: 'functionalityReference',
                  labeling: { 
                    label:'Referencia de la funcionalidad'
                  },
                  title: 'Referencia de la funcionalidad',
                  type: 'select',
                  required: true, 
                  key: 'uuid',
                  text: 'name',
                  data: {
                    href: 'functionality-types',
                    method: 'GET'
                  }
                }"
                :value="functionalityReference"
                validate
                :tooltip="'Referencia'"
                @input="handleInputChange({name:'functionalityReference'}, $event)"
                @onEnter="handleClickAdd"
              />
            </VCol>
            <VCol md="2" class="d-flex align-center justify-center">
              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    outlined
                    elevation="5"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClickAdd"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Agregar funcionalidad</span>
              </v-tooltip>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
      <VCol
        cols="12"
        md="8"
        lg="7"
        class="pt-3"
      >
        <simple-table
          :form="{ headers }"
          :items="functionalitiesModule"
          :loading="loadingTable || !loaded || loading"
          item-key="uuid"
          @delete="deleteFunctionality"
        />
      </VCol>
    </VRow>
    <VRow
      v-if="actions && actions.length"
      class="custom-footer"
      justify="center"
    >
      <VCol
        class="d-flex align-center pb-6 pt-12"
        cols="11"
      >
        <VRow
          justify="space-between"
          style="width: 100%"
        >
          <VCol
            v-for="(action, index) in actions"
            :key="`action-${index}`"
            cols="12"
            :md="action.col ? action.col : 'auto'"
            class="pb-1 "
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              custom-class="text-none"
              :color="action.style ? action.style.type : ''"
              :width="action.style ? action.style.width : ''"
              :loading="loading || !loaded "
              :icon="action.style ? action.style.icon : false"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="handleClick(action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { genericRequest } from '@/api/modules';
import { toAttributes } from '@/mappers/form';
export default {
	name: 'EditFunctionalityModule',
	props: {
		form: {
			type: Object,
			default: function () {
				return {};
			}
		}

	},
	data () {
		return {
			toAttributes,
			loaded: false,
			loading: true,
			loadingTable: true,
			functionalitiesModule: [],
			functionalityName: null,
			functionalityReference:null,
			textRules: [(v) => !!v || 'Campo requerido'],
			headers: [
				{ text: 'Funcionalidad', value: 'name' },
				{ text: 'Referencia', value: 'reference' },
				{
					text: '',
					sortable: false,
					value: 'actions',
					cellClass: 'text-end',
					data: [
						{
							title: 'Eliminar funcionalidad',
							confirmation: {
								message: 'Vas a borrar la funcionalidad {name}'
							},
							style: {
								attributes: [     
									'small',
									'icon',
									'prepend-icon:delete-outline',
									'color:danger'
								]
							},

							event: {
								action: 'delete'
							},
							col: '12'
						}
					]
				}
			]
		};
	},
	computed: {
		meta: get('route/meta'),
		params: get('route/params'),
		customTitle: sync('app/customTitle'),
		id () {
			return this.params?.id;
		},
		actions(){
			return this.form?.steps?.[0]?.actions || this.form.actions;
		}
	},
	mounted () {
		this.getData();
		this.loading = false;
	},
	methods: {
		handleInputChange (field, value) {
			this[field.name] = value;
		},

		async getData () {
			await this.getModule();

			this.loading = false;
			this.loaded = true;
		},

		async getModule () {
			this.loadingTable = true;
			const { resource } = await genericRequest({
				url: `modules/${this.id}`,
				method: 'get'
			});
			this.customTitle = resource.name;
			this.functionalitiesModule = resource?.functionalities?.length ? resource.functionalities : [];
			this.loadingTable = false;
		},

		async deleteFunctionality (data) {
			if (data?.item?.uuid) {
				this.loading = true;
				await genericRequest({
					url: `/modules/${this.id}/functionalities/${data.item.uuid}`,
					method: 'delete'
				});

				this.$refs['functionality-module'].reset();
				this.loading = false;
				this.getModule();
			}
		},

		async handleClickAdd () {
			if (this.$refs?.['functionality-module'].validate()) {
				this.loading = true;
				await genericRequest({
					url: `/modules/${this.id}/functionalities`,
					method: 'post',
					params: { 
						name: this.functionalityName,
						functionality_type_uuid: this.functionalityReference 
					}
				});

				this.$refs['functionality-module'].reset();
				this.loading = false;
				this.getModule();
			}
		},

		handleClick (action) {
			if (!action?.submit && action?.redirect?.href) {
				if (action?.redirect?.service === 'local') {
					this.$router
						.push({ path: action.redirect.href || '/' })
						.catch((error) => {
							console.log(error.message);
						});
				}
			}
		}
	}

};
</script>

<style>
.v-data-table.row-height-dense td
{
  height: 30px !important;
}
</style>
