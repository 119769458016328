
export default {
	methods: {
		toAnchor ({id, offset = 0,duration=700}) {
			const myEl = document.getElementById(id);
			this.$smoothScroll({
				scrollTo: myEl,
				duration,
				offset,
				updateHistory: false,
		
			});

		}
	}
};
