<template>
  <VOverlay
    :value="loading"
    :z-index="zIndex"
    :opacity="opacity"
    :color="color"
    :absolute="absolute"
  >
    <VProgressCircular
      indeterminate
      :size="size"
      :color="loadingColor"
    />
  </VOverlay>
</template>

<script>
export default {
	name: 'CustomLoading',

	components: {},
	props: {
		zIndex: {
			type: String,
			default: '2'
		},
		loading: {
			type: Boolean,
			default: false
		},
		absolute: {
			type: Boolean,
			default: false
		},
		loadingColor: {
			type: [Boolean, String],
			default: 'primary'
		},
		opacity: {
			type: [Boolean, String],
			default: '1'
		},
		color: {
			type: [Boolean, String],
			default: 'background'
		},
		size: {
			type: [Number, String],
			default: '64'
		}

	}
};
</script>

<style>

</style>
