<template>
  <div>
    <div
      v-if="label"
      class="text-headline text-none "
    >
      {{ label }}
    </div>
    <div class="text-subtitle-1">
      <template v-if="value && value!==''">
        {{ formatter({value,formatter:field.formatter}) }}
      </template>
      <template v-else>
        -
      </template>
    </div>
  </div>
</template>

<script>
import formatter from '@/mixins/formatter';
export default {
	name: 'DisabledInput',
	mixins: [formatter],
	props: {
		value: {
			type: [Number, String, null, Array],
			default: function () {
				return '';
			}
		},
		label: {
			type: [String, null],
			default: ''
		},
		field: {
			type: [Object],
			default: function () {
				return {};
			}
		}
	}
};
</script>

<style>

</style>
