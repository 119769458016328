import './styles/app.scss';
import Vue from 'vue';
import App from './App.vue';

import {registerPlugins} from '@/plugins/registerPlugins';

import VueTheMask from 'v-mask';

Vue.use(VueTheMask, {
	placeholders: {
		'#': null, // passing `null` removes default placeholder, so `#` is treated as character
		D: /\d/,
		Я: /[\wа-яА-Я]/ // cyrillic letter as a placeholder

	}
});

Vue.config.productionTip = false;

const plugins=registerPlugins();

new Vue({
	...plugins,
	render: (h) => h(App)
}).$mount('#app');