import { toPathObject } from '@/util/helpers';
import { getConstant } from '@/util/constantsHelpers';

export const getActiveFromArray = (array) => {
	if (!array || !Array.isArray(array)) return false;
	return array.find(e => e.to === null);
};

export const checkAndFilterDepends = ({ fields, data }) => {
	const inputsHidden = [];
	const _fields = [];
	
	fields.forEach(field => {
		if (field.depends?.length) {
			const responses = [];
			if (data) {
				field.depends.forEach(element => {
					let response = false;
					let fieldValue = data[element.field];
			
					if (element.values?.length) {
						if ('customField' in element) {
							fieldValue = data[element.customField.field];
							
							if (Array.isArray(fieldValue)) {
								fieldValue = getActiveFromArray(fieldValue);
							}
							if ('property' in element.customField) {
								fieldValue = toPathObject(fieldValue, element.customField.property);
							}

							if ('constants' in element.customField) {
								if (data[element.customField.field]) {
									const { service, field: _field, property:_property, keyToFind } = element.customField.constants;
									
									const payloadConstants={ 
										field: { 
											[_field]: data[element.customField.field] 
										}, 
										service,
										property: keyToFind
									};

									const _value = getConstant(payloadConstants);
	
									if (_value) {
										fieldValue = toPathObject(_value, _property);
									}

								}

							}
						}
				
						if (Array.isArray(fieldValue)) {
							element.values.forEach(_elementValue=>{
								if(_elementValue ==='array_not_empty' && Array.isArray(fieldValue) && fieldValue.length > 0){
									response = true;
								}
								
							});
						}else{
							element.values.forEach(value => {
								// Verificamos si el valor comienza con 'not:'
								if (typeof value === 'string' && value.startsWith('not:')) {
									// Caso 1: Si el valor comienza con 'not:', hacemos la comparación inversa
									const comparisonValue = value.slice(4); // Extraemos el valor después de "not:"
						
									if (typeof fieldValue === 'string' &&  fieldValue.toLowerCase() !== comparisonValue.toLowerCase()) {
										response = true;
									}
								}else {
									// Caso 2: Si no comienza con 'not:', comparamos directamente con el fieldValue
									if (value === fieldValue) {
										response = true;
									}
								}

							});

							if (element.values.includes(fieldValue)) {
								response = true;
							}
						}
						
					} else {
				
						if (fieldValue) {
							response = true;
						}
					}
					responses.push(response);
				});
			} else {
				responses.push(false);
			}
			if (responses.includes(false)) {
				inputsHidden.push(field);
			}
			_fields.push({ ...field, hidden: responses.includes(false) ? 'true' : 'false' });
		} else {
			_fields.push(field);
		}
	});

	return { fields:_fields, inputsHidden };
};