import { idnumber } from '@/util/helpers';

export default (data = { }) => {
	const items = [
		{
			canvas: [
				{
					type: 'line',
					x1: 0,
					x2: 525,
					y1: 0,
					y2: 0,
					lineWidth: 1,
					lineColor: 'black'
				}
			],
			margin: [0, 5, 0, 5]
		},
		{
			text: [
				{ text: 'Afiliado N°: ', fontSize: 11, bold: true },
				{ text: `${data.person.affiliate_number}`, fontSize: 11, bold: false }
			],
			margin: [0, 5, 0, 5],
			color: '#000000'
		},
		{
			text: [
				{ text: 'Nombre: ', fontSize: 11, bold: true },
				{ text: `${data.person.firstname} ${data.person.lastname}`, fontSize: 11, bold: false }
			],
			margin: [0, 5, 0, 5],
			color: '#000000'
		},
		{
			text: [
				{ text: 'DNI: ', fontSize: 11, bold: true },
				{ text: `${idnumber(data.person.idnumber)}`, fontSize: 11, bold: false }
			],
			margin: [0, 5, 0, 5],
			color: '#000000'
		},
		{
			canvas: [
				{
					type: 'line',
					x1: 0,
					x2: 525,
					y1: 0,
					y2: 0,
					lineWidth: 1,
					lineColor: 'black'
				}
			],
			margin: [0, 5, 0, 5]
		}
	];

	return items;
};
