<template>
  <VRow
    align="center"
    justify="start"
    class="py-1"
  >
    <custom-loading :loading="loading" />
    <VCol cols="12">
      <span class="text-subtitle-1 font-weight-negrita">
        {{ label }}
      </span>
      <component
        :is="type"
        :form="form"
        :items="items"
        :model="model"
        :is-custom-loading="false"
        :parent-data="model"
        :force-initialize="forceInitialize"
        :to-top="false"
        @loaded="handleLoaded"
        @add="handleAdd"
        @edit="handleEdit"
        @del="handleDel"
      />
    </VCol>
    <form-modal
      :open.sync="dialogForm"
      :form="dataToSave"
      :data-source="dataSource"
      :data-parent-model="{parent_uuid:model.uuid,...model}"
      :params="dataParams"
      @confirm="confirmModalAction"
    />
  </VRow>
</template>

<script>
import { genericRequest } from '@/api/modules';
import jsons from '@/mixins/jsons';
import tableMapper from '@/mappers/tables';
import { generateStringFromVariables } from '@/util/helpers';
export default {
	name: 'CustomInputBrowse',
	mixins: [ jsons],
	props: {
		value: {
			type: [Array],
			default: function () {
				return [];
			}
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		label: {
			type: [String, null],
			default: ''
		},

		model: {
			type: Object,
			default: function () {
				return {};
			}
		}

	},
	data () {
		return {
			loading: false,
			dataToSave: false,
			dataParams: null,
			dialogForm: false,
			dataSource: false,
			form:null,
			forceInitialize:false
		};
	},
	computed: {
		type () {
			return this.form?.steps?.type || this.form?.steps?.[0]?.type || this.form?.type;
		},
		items () {
			return this.value?.length ? this.value : [];
		}
	},
	watch: {

		value(){
			this.init();
		},
		model(){
			const reloadOnChange=String(this.form?.source?.data?.reloadOnChange)==='true';
			if(reloadOnChange){
				this.forceInitialize=true;
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		handleLoaded () {
			this.forceInitialize = false;
		},
		init(){
			let form = null;
			if (this.field?.form?.history) {
				form = this.importJson(`${this.field.form.history}.json`);
				if (form) {
					form = tableMapper(form);
					if(!form.source){
						form.source={};
					}
					if(!form.source?.data?.href){
						form.source.dataMock=this.items;
					}

				}
			}

			this.form= form;
		},
		async handleDel (data) {
			this.loading = true;
			try {
				const url = generateStringFromVariables({
					title: data.value.href,
					data: { person_uuid: this.model.uuid, ...data.item }
				});
				const response = await genericRequest({
					url: url,
					method: data.value.method,
					service: data.value.service
				});

				this.$emit('reload-form', { response: response, field: this.field });
				this.reset();
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},
		handleAdd (data) {
			this.reset();

			let form = data.event?.form ? this.importJson(`${data.event.form}.json`) : false;

			this.dataToSave = form || false;
			this.dialogForm = true;
		},
		async handleEdit (data) {
			this.reset();

			if (data?.value?.event?.form) {
				let form = data.value?.event?.form ? this.importJson(`${data.value.event.form}.json`) : false;

				this.dataParams = { data };
				this.dataSource = { person_uuid: this.model.uuid, ...data.item };
				this.dataToSave = form || false;
				this.dialogForm = true;
			}
		},
		reset () {
			this.dataParams = null;
			this.dataToSave = false;
			this.dataSource = false;
		},
		confirmModalAction (data) {
			const reloadOnChange=String(this.form?.source?.data?.reloadOnChange)==='true';
			if(reloadOnChange){
				this.forceInitialize=true;
			}else{
				this.$emit('reload-form', { ...data, field: this.field });
			}
			
			this.reset();
		}
	}

};

</script>

<style>

</style>
