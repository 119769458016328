<template>
  <VRow justify="start">
    <template v-for="(type,index) in data.rooms">
      <v-col :key="`${type.uuid}_quantity`" cols="12">
        <v-autocomplete
          v-model="type.room_acompanantes"
          :items="dependants"
          item-text="name"
          item-value="uuid"
          :label="type.name"
          multiple
          outlined
          small-chips
          hide-details="auto"
          dense
          :rules="[rules('array')]"
          no-data-text="Seleccione pasajero"
        >
          <template
            #selection="{item, select}"
          >
            <v-chip
              v-if="item"
              v-bind="item.attrs"
              :input-value="item.selected"
              close
              small
              class="mt-2"
              @click="select"
              @click:close="remove(item,index)"
            >
              {{ item.name || item.firstname }} - {{ item.lastname }}
            </v-chip>
          </template>

          <template
            #item="{item}"
          >
            <div v-if="item">
              {{ item.name || item.firstname }} - {{ item.lastname }}
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </template>
  </VRow>
</template>
  
<script>
import rules from '@/util/rules';
export default {
	name: 'CustomRoomPersonSelector',
	props: {
		data: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		},
		dependants: {
			type: [Array],
			default: function () {
				return [];
			},
		},
		parentData: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		}
	},
	data() {
		return {
			rules
		};
	},
	computed: {

	},
	watch: {
		'data.viajaAfiliado'() {
			this.data.rooms.forEach(room => {
				this.$set(room,'room_acompanantes',[]);
			});
			if(this.data.viajaAfiliado){
				this.$set(this.data.rooms[0],'room_acompanantes',[this.parentData.uuid]);
			}
		},
		'data.uuid'() {
			this.data.rooms.forEach(room => {
				this.$set(room,'room_acompanantes',[]);
			});
		}
	},
	mounted() {
		this.$set(this.data.rooms[0],'room_acompanantes',[]);
		if(this.data.viajaAfiliado){
			this.$set(this.data.rooms[0],'room_acompanantes',[this.parentData.uuid]);
		}
     
	},
	methods: {
		remove (item,i) {
			const index = this.data.rooms[i].room_acompanantes.indexOf(item.uuid);
		
			// eslint-disable-next-line vue/no-mutating-props
			if (index >= 0) this.data.rooms[i].room_acompanantes.splice(index, 1);
		}
	},
};
</script>
  
  <style>
  
  </style>
  