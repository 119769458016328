<template>
  <v-dialog
    v-model="modal"
    class="form-modal"
    :persistent="loading || persistent"
    :max-width="form && form.maxWidth || maxWidth"
  >
    <v-card v-if="form">
      <v-card-title v-if="form.label || form.title">
        <span class="text-subtitle-1 font-weight-negrita">{{ title }}</span>
      </v-card-title>
      <v-card-text class="px-0 pb-2">
        <component
          :is="type"
          validate
          :status-modal="modal"
          :form="form"
          :items="items"
          v-bind="_attributes"
          :is-custom-loading="false"
          :data-params="params"
          :parent-data="params && params.data && params.data.item"
          :data-parent-model="dataParentModel"
          :data-source="dataSource"
          :force-initialize="forceInitialize"
          :is-send-form="isSendForm"
          @click="handleClick"
          @edit="handleEdit"
          @formModalEvent="$emit($event.event,$event.action)"
          @error="$emit('error')"
          @submit="handleSubmit"
          @cancel="handleCancel"
          @escape="handleCancel"
          @isLoading="loading=$event"
          @loaded="$emit('loaded')"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { toAttributes } from '@/mappers/form';
import { generateStringFromVariables } from '@/util/helpers';
export default {
	name: 'FormModal',

	components: {},
	props: {
		forceInitialize: {
			type: Boolean,
			default: false
		},
		form: {

			type: [Object, Boolean],
			default: function () {
				return {};
			}
		},
		dataSource: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		},
		dataParentModel: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		},
		params: {
			type: [Object, Boolean],
			default: function () {
				return {};
			}
		},
		persistent: {
			type: [Boolean],
			default: function () {
				return false;
			}
		},
		isSendForm: {
			type: [Boolean],
			default: function () {
				return true;
			}
		},
		items: {
			type: [Array, Boolean],
			default: function () {
				return [];
			}
		},
		attributes: {
			type: [Array, Boolean],
			default: function () {
				return [];
			}
		},
		open: {
			type: Boolean,
			default: false
		},
		isOverlay: {
			type: Boolean,
			default: false
		},
		maxWidth: {
			type: String,
			default: '600px'
		}
	},

	data () {
		return { modal: false, loading: false };
	},
	computed: {
		_attributes(){
			return toAttributes(this.attributes);
		},
		type () {
			return this.form?.steps?.type || this.form?.steps?.[0]?.type || this.form?.type;
		},
		title () {
			const title = this.form.label || this.form.title;
			const txt = generateStringFromVariables({ title: title || '', data: this.dataSource || this.params?.data?.item || this.params });

			return txt;
		}
	},
	watch: {
		open: function (val) {
			if (val) {
				this.modal = true;
			} else {
				this.modal = false;
			}
		},
		modal: function (val) {
			if (!val) {
				this.$emit('update:open', false);
			}
		}
	},

	async mounted () {

	},

	methods: {
		handleClick(data){
			this.$emit('click', data);
		},
		handleEdit(data){
			this.$emit('edit', data);
		},
		handleCancel () {
			this.modal = false;
			this.$emit('cancel');
		},
		async handleSubmit (data) {
			this.modal = false;
			this.$emit('confirm', data);
		}
	}
};
</script>

<style>

</style>
