<template>
  <VRow justify="start">
    <template v-for="type in data.rooms">
      <v-col :key="`${type.uuid}_quantity`" cols="7" class="pb-0">
        <custom-input
          :model="type"
          :field="{
            name:'quantitySelected',
            labeling:{
              label:'Cantidad'
            },
            title:'Cantidad',
            type:'number',
            required:true, 
            style:{
              attributes:[`prefix:${type.name} -    `,'hide-datails:auto']
            },
            customValidations:
          
              [
                {
                  validation:'lessThan',
                  fields:[
                    'quantity'
                  ],
                  message:`La ${type.name} cuenta con ${type.quantity} unidades disponibles`
                }
              ]
          }"
          :value="0"
          validate
          :tooltip="'Cantidad'"
        />
      </v-col>
    </template>
  </VRow>
</template>
  
<script>

export default {
	name: 'CustomRoomSelector',
	props: {
		data: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		}
	},
	data() {
		return {

		};
	},
	computed: {

	},
	watch: {

      
	},
	mounted() {
  
          
	},
	methods: {

	},
};
</script>
  
  <style>
  
  </style>
  