<template>
  <v-select
    v-model="benefit"
    class="pt-0"
    :items="itemsFilters"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    no-data-text="Sin datos"
    :loading="loading"
    :disabled="loading"
    :rules="rules"
    @change="handleInputChange(field, $event)"
  >
    <template
      #selection="data"
    >
      {{ data.item[itemText] }}
      <div
        class="d-flex align-center justify-center"
      >
        <v-icon
          color="error"
          small
          class="ml-2"
        >
          mdi-alert-circle
        </v-icon>
        <span class="text-caption error--text">
          Habilitada por excepción
        </span>
      </div>
    </template>

    <template
      #item="data"
    >
      {{ data.item[itemText] }}
      <div
        class="d-flex align-center justify-center"
      >
        <v-icon

          color="error"
          small
          class="ml-2"
        >
          mdi-alert-circle
        </v-icon>
        <span class="text-caption error--text">
          Habilitar por excepción
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { getActiveFromArray } from '@/util/dataHelpers';
import { getConstant } from '@/util/constantsHelpers';
export default {
	name: 'CustomBenefitSelector',
	props: {
		eventsBus:  {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		}
		,
		items: {
			type: [Array],
			default: function () {
				return [];
			}
		},
		rules: {
			type: [Array],
			default: function () {
				return [];
			}
		},
		loading: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: ''
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		value: {
			type: [String, Boolean, Array, Object],
			default: false
		},
		dataParentModel: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		},
		model: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		}
	},
	data () {
		return {
			benefit: this.value
		};
	},
	computed: {
		itemValue () {
			return this.field?.key ? this.field.key : 'slug';
		},
		itemText () {
			return this.field?.text ? this.field.text : 'text';
		},

		itemsFilters () {
			const benefits = getConstant({ field: { 'benefit-types': null }, service: 'afiliaciones' }).filter(e=>!this.items.find(item=>item.uuid===e.uuid && item.uuid!==this.value));

			return benefits;
		}
	},
	watch: {
		value (value) {
			this.benefit = value;
		}
	},
	mounted () {
		this.benefit = this.value;
	},
	methods: {
		handleInputChange (field, value) {
			this.$emit('changeCustomInput', value);
		}
	}
};

</script>

<style>

</style>
