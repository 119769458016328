import mainLogo from '../../images/logo';

export default () => {
	return [

		{
			image: mainLogo,
			width: 202,
			absolutePosition: { x: 40, y: 15 }
		}
	];
};
