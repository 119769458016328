<template>
  <VRow justify="center" class="mt-2 p-relative">
    <custom-loading :loading="formLoading" absolute opacity="0.1" size="40" class="mt-12" />
    <VCol cols="12">
      <VRow align="center" justify="start">
        <VCol v-for="(benefit, index) in benefitsBtn" :key="`${index}_benefit`" cols="auto" class="py-md-1">
          <custom-btn
            :attributes="{
              'outlined': !isDisabled(benefit) && benefitSelected && benefitSelected.reference !== benefit.reference, 
              elevation: 6, 
              'color': isDisabled(benefit) ? 'bgDisabled' : currentModule.color
            }"
            :label="benefit.data.name"
            :custom-class="{
              'text-none':true,
              'textDisabled--text':isDisabled(benefit),
              'cursor-default':isDisabled(benefit), 
              'white--text': (!isDisabled(benefit) && !benefitSelected) || (benefitSelected && benefitSelected.reference === benefit.reference)
            }"
            :tooltip="getMsg(benefit)"
            @handleClick="!isDisabled(benefit) && handleChangeBenefit(benefit)"
          />
        </VCol>

        <VCol v-if="benefitSelected" cols="12" lg="auto" class="d-flex justify-end">
          <custom-btn
            :attributes="{ 
              icon: true, 
              small: true,
              'prepend-icon':'history'
            }"
            tooltip="Ver historial"
            label="Historial"
            custom-class="mx-8"
            @handleClick="showHistoryEvent"
          />
        </VCol>
        <VCol v-if="benefitSelected && !isException" cols="12">
          <VAutocomplete
            v-if=" subBenefitsBtn"
            v-model="subtypeSelected"
            :items="subBenefitsBtn"
            :label="`Tipos de ${benefitSelected.data && benefitSelected.data.name}`"
            no-data-text="Sin datos"
            item-text="name"
            item-value="uuid"
            return-object
            dense
            outlined
            hide-details
            @change="handleShowCrud"
          />
          <div v-else>
            No se encuentran tipos {{ benefitSelected.data && benefitSelected.data.name }} activos
          </div>
        </VCol>
        <template v-if="isAlertSection">
          <VCol
            cols="12"
            :lg="subtypeSelected ? '10' : '12'"
          >
            <VSheet height="135" elevation="1" class="px-4 py-2">
              <v-virtual-scroll :items="messages" height="125" item-height="24">
                <template #default="{ item }">
                  <div class="text-subtitle-2 font-weight-regular error--text">
                    {{ item }}
                  </div>

                  <v-divider />
                </template>
              </v-virtual-scroll>
            </VSheet>
          </VCol>
      
          <VCol
            cols="12"
            lg="2"
            class="pl-lg-0"
          >
            <VBtn
              elevation="6"
              :color="currentModule.color"
              :dark="subValidations.rejected"
              block
              width="100"
              class="text-none my-1"
              :disabled="!subValidations.rejected"
              @click="handleRechazo"
            >
              Rechazo
            </VBtn>
            <VBtn
              elevation="6"
              :color="currentModule.color"
              :dark="subValidations.continue"
              width="100"
              block
              class="text-none my-1"
              :disabled="!subValidations.continue"
            >
              Continuar
            </VBtn>
            <VBtn
              elevation="6"
              :color="currentModule.color"
              :dark="subValidations.exception"
              width="100"
              block
              class="text-none my-1"
              :disabled="!subValidations.exception"
            >
              Excepción
            </VBtn>
          </VCol>
        </template>
        <VCol v-if="(isEnableForm || isException) && crudForm" cols="12">
          <v-card elevation="1">
            <custom-form
              ref="customForm"
              validate
              :form="crudForm"
              :data-source="{ person_uuid: parentData.uuid, subtype_uuid: subtypeSelected && subtypeSelected.uuid }"
              :disabled="formDisabled"
              :is-custom-loading="false"
            />
            <VContainer fluid>
              <VRow align="center" justify="space-between">
                <VCol cols="auto">
                  <custom-btn
                    :attributes="{
                      'outlined':true
                    }"
                    label="Cancelar"
                    @handleClick="handleCancel"
                  />
                </VCol>
                <VCol cols="auto">
                  <custom-btn
                    :attributes="{
                      'color':'success'
                    }"
                    label="Guardar"
                    @handleClick="handleSubmit"
                  />
                </VCol>
              </VRow>
            </VContainer>
          </v-card>
        </VCol>
      </VRow>
    </VCol>
    <form-modal
      :open.sync="addRechazo"
      :form="formRechazo"
      persistent
      :data-source="{ reason: 1 }"
      @confirm="handleRechazoConfirm"
      @cancel="formRechazo = null"
    />
    <form-modal
      :open.sync="showHistory"
      max-width="700px"
      :form="formHistorial"
      :items="itemsHistory"
      :data-source="{ currentMonth: 'Noviembre' }"
      @openPdf="handleOpenPdf"
      @downloadPdf="handleDownloadPdf"
      @cancel="formHistorial = null"
    />
  </VRow>
</template>

<script>
import { get, sync} from 'vuex-pathify';
import formatter from '@/mixins/formatter';
import { paramMapped } from '@/mappers/form';
import jsons from '@/mixins/jsons';
import { stringToSlug, generateStringFromVariables } from '@/util/helpers';
import { pdfController } from '@/pdf';
import tableMapper from '@/mappers/tables';
import { genericRequest } from '@/api/modules';
import { getConstant } from '@/util/constantsHelpers';

export default {
	name: 'GestionPrestacionesForm',
	mixins: [formatter, jsons],
	props: {
		form: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		},
		loading: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		},
		parentData: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		}
	},
	data() {
		return {
			validations: {},
			formLoading: false,
			formDisabled:false,
			subtypeSelected: null,
			benefitSelected: null,
			crudVisible:false,

			addRechazo: false,
			crudForm: null,
			formRechazo: null,
			formHistorial: null,
			showHistory: false,
			itemsHistory: [],
		};
	},
	computed: {
		meta: get('route/meta'),
		currentModule: get('user/currentModule'),
		alertTxt: sync('app/alertTxt'),
		alert: sync('app/alert'),
		isException(){
			return this.benefitSelected?.reference==='exception';
		},
		messages() {
			return this.validations?.messages?.length ? this.validations?.messages : [];
		},
		subValidations(){
			return this.validations?.sub_validations || {};
		},
		isAlertSection(){
			return this.benefitSelected && this.subtypeSelected && !this.isEnableForm;
		},
		isEnableForm(){
			return this.benefitSelected && this.subtypeSelected && (this.validations?.sub_validations?.enable_form || this.crudVisible);
		},
		subBenefitsBtn(){
			return this.benefitSelected?.data?.benefit_subtypes || [];
		},
		benefitsBtn() {
			if(!this.validations) return [];

			const { benefits } = this.validations;

			if(!benefits) return [];
			
			const benefitTypes = Object.keys(benefits).map((keyBenefit) => {
				const findBenefit = getConstant({ field: { 'benefit-types': null }, service: 'afiliaciones', property:'reference', filter:keyBenefit});

				if(findBenefit?.[0]){
					return { ...benefits[keyBenefit], data:findBenefit[0], reference:keyBenefit };
				}
				
			});

			return benefitTypes;
		}
	},
	watch: {

		async parentData() {
			this.$emit('update:loading', true);
			await this.init();
			this.$emit('update:loading', false);
		}
	},
	async mounted() {
		this.$emit('update:loading', true);
		await this.init();
		this.$emit('update:loading', false);
	},
	methods: {
		getMsg(benefit){
			if(!benefit.message?.length) return false;

			const errorMSg = benefit.message.reduce(
				(previousValue, currentValue) => previousValue + `<div>${currentValue}</div>`,
				''
			);

			return errorMSg;
		},
		isDisabled(benefit){
			return this.formLoading || !benefit.enabled; 
		},
		async handleOpenPdf(data) {
			let title = 'Confirmación de Prestación';
			if (data.item.concepto === 'Rechazo') {
				title = 'Rechazo de Prestaciones';
			}
			await pdfController.dowloadRechazo(
				{
					currentDate: data.item.fecha,
					title,
					motivo: data.item.motivo,
					afiliado: this.formData,
					prestacion: `${data.item.prestacion} / ${data.item.tipo}`,
				},
				'open'
			);
		},
		async handleDownloadPdf(data) {
			let title = 'Confirmación de Prestación';
			if (data.item.concepto === 'Rechazo') {
				title = 'Rechazo de Prestaciones';
			}
			await pdfController.dowloadRechazo(
				{
					currentDate: data.item.fecha,
					title,
					motivo: data.item.motivo,
					afiliado: this.formData,
					prestacion: `${data.item.prestacion} / ${data.item.tipo}`,
				},
				'download'
			);
		},
		async showHistoryEvent() {
			this.formLoading = true;
			const endpoints = {
				['bond']: 'uptake-bonds',
				['refund']: 'uptake-refunds',
				['subsidy']: 'uptake-subsidies'
			};
			this.formHistorial = null;
			if (this.benefitSelected?.reference) {
				const { resources: items } = await genericRequest({
					url: `persons/${this.parentData.uuid}/${endpoints[this.benefitSelected?.reference]}`,
					method: 'get',
					service: 'afiliaciones'
				});
				this.itemsHistory = items;
				const form = this.importJson(
					`obra/obra-social-gestion-de-prestaciones/historial/${this.benefitSelected.reference}.json`
				);
				if (form) {
					this.formHistorial = tableMapper(form);
				}
			}
			this.formLoading = false;
			this.showHistory = true;
		},
		async init() {
			this.crudVisible = false;
			this.crudForm = null;
			this.benefitSelected = null;
			await this.getValidations();
			
		},
		async getValidations() {
			if(!this.parentData) return false;
			const params = {
				benefit_subtype_uuid:this.subtypeSelected?.uuid
			};
			this.validations = await genericRequest({
				url: `persons/${this.parentData.uuid}/validations`,
				method: 'get',
				params,
				service: 'afiliaciones',
				checkTokenStatus: true
			});
		},
		async handleChangeBenefit(benefit) {
			this.crudForm = null;
			this.subtypeSelected = null;
			this.benefitSelected = benefit;
			if(this.isException){
				this.formLoading = true;
				this.crudForm = this.importJson(
					'obra/obra-social-gestion-de-prestaciones/crud/exception.json'
				);
				this.formLoading = false;
			}
		},
		async handleShowCrud() {
			this.formLoading = true;

			this.crudForm = null;
			await this.getValidations();
			if( this.subtypeSelected){
				const form =stringToSlug(this.subtypeSelected.reference);

				this.crudForm = this.importJson(
					`obra/obra-social-gestion-de-prestaciones/crud/${form}.json`
				);
			}
			this.formLoading = false;

		},
		handleRechazo() {
			const form = stringToSlug(this.subtypeSelected.reference);
			this.formRechazo = this.importJson(
				`obra/obra-social-gestion-de-prestaciones/rechazo/${form}.json`
			);
			if (!this.formRechazo) {
				this.formRechazo = this.importJson(
					'obra/obra-social-gestion-de-prestaciones/rechazo/generic.json'
				);
			}
			this.addRechazo = true;
		},
		async handleSubmit(){
			const validate=this.$refs.customForm.checkValidate();
			if(validate){
				this.formLoading = true;
				const {data, step:{ actions, fields }} = this.$refs.customForm;
				const { submit } = actions.find(e=>e.id==='submit');

				const url = generateStringFromVariables({
					title: submit.href,
					data: { person_uuid:this.parentData.uuid }
				});

				const params = paramMapped(submit.parameters, data, fields);

				const {resource}=await genericRequest({
					url,
					method: submit.method,
					params,
					service: submit.service,
					checkTokenStatus: submit.checkTokenStatus
				});
				if(resource){
					await pdfController.obraSocialConfirmation(
						{...resource,benefit_type:this.benefitSelected.data},
						'open'
					);
					this.alertTxt = {
						timeout: 3700,
						type: 'success',
						txt: 'Guardado con éxito',
					};
				}else{
					this.alertTxt = {
						timeout: 3700,
						type: 'error',
						txt: 'Error al guardar',
					};
				}

				this.$refs.customForm.resetForm();
				this.alert = true;
				this.formLoading = false;
			}
		},

		handleCancel() {
			this.crudVisible = false;
			this.subtypeSelected = null;
			if(this.isException){
				this.benefitSelected =null;
				this.crudForm = null;
			}
		},
		async handleRechazoConfirm(data) {
			this.addRechazo = false;
			this.formRechazo = null;
			const date = new Date();
			const currentDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
			const currentData = data?.data ? data.data : {};
			let motivo = `Motivo ${currentData.reason}`;

			await pdfController.dowloadRechazo(
				{
					currentDate,
					title: 'Rechazo de Prestaciones',
					motivo,
					afiliado: this.parentData,
					prestacion: `${this.benefitSelected.name} / ${this.subtypeSelected.name}`,
				},
				'open'
			);

			this.handleChangeBenefit();
			this.alertTxt = {
				timeout: 3700,
				type: 'success',
				txt: 'Rechazo generado con éxito',
			};
			this.alert = true;
		},
	},
};
</script>

<style></style>
