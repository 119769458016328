
export default {
	methods: {
		checkFunctionality (object,module) {
			let foundMatch = true;

			if('functionalities' in object && object.functionalities?.length > 0){
				foundMatch = false;
				object.functionalities.forEach(item => {
					if (module.functionalitiesList?.length && module.functionalitiesList.includes(item)) {
						foundMatch = true;
						return; // Break the forEach loop
					}
				});
			}
			
			return foundMatch;

		}
	}
};
