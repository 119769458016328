<!-- eslint-disable -->
<template>
  <VRow justify="center" class="pb-10 pt-7 pl-md-6">
    <VCol cols="12" class="pa-0"> 
      <v-card class="d-flex align-center justify-space-between px-3 py-1" outlined  @click="show1 = !show1">
        <span class="text-subtitle-1">Remesa abierta</span>

        <v-icon>{{ show1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>

      </v-card>

    </VCol>
    <VCol cols="12" class="pl-9" :class="show1?'':'py-0'">

        <v-expand-transition>
          <div v-show="show1">

                    <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(panel, keyPanel) in panels1"
                        :key="keyPanel"
                    >
                        <v-expansion-panel-header class="py-0">
                        <v-row no-gutters>
                            <v-col cols="12" class="d-flex justify-end align-center">
                                <v-fade-transition leave-absolute>
                                    <v-row no-gutters style="width: 100%">
                                        <v-col cols="3">
                                            Creación: 3/5/2023
                                        </v-col>
                                        <v-col cols="4">
                                            {{desserts1.length}} Órdenes de pago
                                        </v-col>
                                        <v-col cols="5">
                                            Suma de importes a pagar: $1.000
                                        </v-col>
                                    </v-row>
                                </v-fade-transition>

                            <custom-btn
                                ref="button1"
                                color="success"
                                :attributes="{icon:true,'small':true,'prepend-icon':'send-check', color:'success'}"
                                outlined
                                custom-class="text-none mr-1"
                                tooltip="Pasar a estado “enviada”"
                            />
                            <custom-btn
                                ref="button2"
                                :attributes="{icon:true,'small':true,'prepend-icon':'file-find', color:'primary'}"
                                outlined
                                custom-class="text-none mr-2"
                                tooltip="Pasar a estado “en revisión”"
                            />
                            </v-col>
                        </v-row>
                       <template v-slot:actions>
                                    <v-icon color="primary">
                                    $expand
                                    </v-icon>
                                </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                        <v-row justify="space-around" no-gutters>
                            <VCol cols="12">
                            <v-data-table
                                :headers="headers1"
                                :items="desserts1"
                                sort-by="calories"
                                class="elevation-0"
                                :show-expand="false"
                                :expanded.sync="expanded"
                                item-key="user"
                                dense
                                :hide-default-header="false"
                            >
                                <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    Más información del ítem {{ item.name }}
                                </td>
                                </template>
                                <template v-slot:item.actions="{ item }">

                                <custom-btn
                                ref="button1"
                                color="primary"
                                :attributes="{icon:true}"
                                :attributesIcon="{'small':true}"
                                outlined
                                custom-class="text-none"
                                icon="pencil"
                                tooltip="Editar"
                            />
                                </template>
                            </v-data-table>
                            </VCol>
                        </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>

          </div>
        </v-expand-transition>
    </VCol>


    <VCol cols="12" class="pa-0 pt-6"> 
      <v-card class="d-flex align-center justify-space-between px-3 py-1"  outlined  @click="show2 = !show2">
        <span class="text-subtitle-1">En revisión</span>
        <v-icon>{{ show2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-card>

    </VCol>
    <VCol cols="12" class="pl-9" :class="show2?'':'py-0'">

        <v-expand-transition>
          <div v-show="show2">
            <v-expansion-panels
                v-for="(panelWrapper, keyPanelWrapper) in panelsWrapper1"
                :key="keyPanelWrapper"
                class="my-4"
                light
            >
                <v-expansion-panel>
                <v-expansion-panel-header class="py-0">
                    <v-row no-gutters>
                            <v-col cols="12" class="d-flex justify-end align-center">
                                <v-fade-transition leave-absolute>
                                    <v-row no-gutters style="width: 100%">
                                        <v-col cols="3" class="font-weight-negrita">
                                            Remesa {{keyPanelWrapper+1}}
                                        </v-col>
                                        <v-col cols="4">
                                            En revisión: {{keyPanelWrapper+1 * 2}}
                                        </v-col>
                                        <v-col cols="4">
                                            Totalmente revisadas: {{keyPanelWrapper+6 * 3}}
                                        </v-col>
                                    </v-row>
                                </v-fade-transition>
                            </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(panel, keyPanel) in panels"
                        :key="keyPanel"
                    >
                        <v-expansion-panel-header class="py-0">
                        <v-row no-gutters>
                            <v-col cols="12" class="d-flex justify-end align-center">
                                <v-fade-transition leave-absolute>
                                    <v-row no-gutters style="width: 100%" align="center">
                                        <v-col cols="2">
                                            Creación: 1/1/2023
                                        </v-col>
                                        <v-col cols="3">
                                            Pase a “en revisión”: 1/1/2023
                                        </v-col>
                                        <v-col cols="2">
                                            Órdenes incluidas: 4
                                        </v-col>
                                        <v-col cols="2">
                                            Órdenes revisadas: 4
                                        </v-col>
                                        <v-col cols="2">
                                            Total a pagar: $10.000
                                        </v-col>
                                        <v-col cols="1">
                                        <custom-btn
                                                ref="button1"
                                                color="success"
                                                :attributes="{icon:true}"
                                                :attributesIcon="{'medium':true}"
                                                outlined
                                                custom-class="text-none mr-1"
                                                icon="send-check"
                                                tooltip="Enviar a tesoreria"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-fade-transition>
                            </v-col>
                        </v-row>



                        <!-- <template v-slot:actions>
                                    <v-icon color="primary">
                                    $expand
                                    </v-icon>
                                </template> -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                        <v-row justify="space-around" no-gutters>
                            <VCol cols="12">
                            <v-data-table
                            :headers="headers1"
                                :items="desserts1"
                                sort-by="calories"
                                class="elevation-0"
                                :show-expand="false"
                                :expanded.sync="expanded"
                                item-key="name"
                                dense
                            >
                                <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    Más información del ítem {{ item.name }}
                                </td>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <custom-btn
                                ref="button1"
                                color="primary"
                                :attributes="{icon:true,'small':true}"
                                :attributesIcon="{'small':true}"
                                outlined
                                custom-class="text-none"
                                icon="pencil"
                                tooltip="Modificar datos"
                            />
                            <custom-btn
                                ref="button1"
                                color="error"
                                :attributes="{icon:true,'small':true}"
                                :attributesIcon="{'small':true}"
                                outlined
                                custom-class="text-none"
                                icon="delete-outline"
                                tooltip="Eliminar órden de pago"
                            />
                                </template>
                            </v-data-table>
                            </VCol>
                        </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-expand-transition>
    </VCol>

    <VCol cols="12" class="pa-0 pt-6"> 
      <v-card class="d-flex align-center justify-space-between px-3 py-1"  outlined  @click="show3 = !show3">
        <span class="text-subtitle-1">Enviadas</span>
        <v-icon>{{ show3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-card>

    </VCol>
    <VCol cols="12" class="pl-9" :class="show3?'':'py-0'">

        <v-expand-transition>
          <div v-show="show3">
            <v-expansion-panels
                v-for="(panelWrapper, keyPanelWrapper) in panelsWrapper3"
                :key="keyPanelWrapper"
                class="my-4"
                light
            >
                <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-row no-gutters>
                    <v-col cols="4"> {{ panelWrapper }} </v-col>
                    <v-col cols="4"> </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(panel, keyPanel) in panels"
                        :key="keyPanel"
                    >
                        <v-expansion-panel-header>
                        <v-row no-gutters>
                            <v-col cols="4"> Orden {{ panel }} </v-col>
                            <v-col cols="8" class="d-flex justify-end align-center">
                            <!-- <v-fade-transition leave-absolute>
                                            <span v-if="open">When do you want to travel?</span>
                                            <v-row v-else no-gutters style="width: 100%">
                                                <v-col cols="6">
                                                    Start date: {{ trip.start || 'Not set' }}
                                                </v-col>
                                                <v-col cols="6">
                                                    End date: {{ trip.end || 'Not set' }}
                                                </v-col>
                                            </v-row>
                                        </v-fade-transition> -->
                            <v-icon
                                small
                                class="mr-2"
                                @click="$event.stopPropagation()"
                                color="primary"
                            >
                                mdi-cash-sync
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="$event.stopPropagation()"
                                color="success"
                            >
                                mdi-cash-multiple
                            </v-icon>
                            <v-icon
                                small
                                @click="$event.stopPropagation()"
                                color="error"
                                class="mr-3"
                            >
                                mdi-close-box-multiple-outline
                            </v-icon>
                            </v-col>
                        </v-row>
                        <!-- <template v-slot:actions>
                                    <v-icon color="primary">
                                    $expand
                                    </v-icon>
                                </template> -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                        <v-row justify="space-around" no-gutters>
                            <VCol cols="12">
                            <v-data-table
                                :headers="headers1"
                                :items="desserts1"
                                sort-by="calories"
                                class="elevation-0"
                                show-expand
                                :expanded.sync="expanded"
                                item-key="name"
                                dense
                                :hide-default-header="false"
                            >
                                <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    Más información del ítem {{ item.name }}
                                </td>
                                </template>
                                <template v-slot:item.actions="{ item }">
                               <custom-btn
                                ref="button1"
                                color="primary"
                                :attributes="{icon:true,'small':true}"
                                :attributesIcon="{'small':true}"
                                outlined
                                custom-class="text-none"
                                icon="pencil"
                                tooltip="Modificar datos"
                            />
                            <custom-btn
                                ref="button1"
                                color="error"
                                :attributes="{icon:true,'small':true}"
                                :attributesIcon="{'small':true}"
                                outlined
                                custom-class="text-none"
                                icon="delete-outline"
                                tooltip="Eliminar órden de pago"
                            />
                                </template>
                            </v-data-table>
                            </VCol>
                        </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-expand-transition>
    </VCol>


    <VCol cols="12" class="pa-0 pt-6"> 
      <v-card class="d-flex align-center justify-space-between px-3 py-1"  outlined  @click="show4 = !show4">
        <span class="text-subtitle-1">Estados de ejecución</span>
        <v-icon>{{ show4 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-card>

    </VCol>
    <VCol cols="12" class="pl-9" :class="show4?'':'py-0'">

        <v-expand-transition>
          <div v-show="show4">
            <v-expansion-panels
                v-for="(panelWrapper, keyPanelWrapper) in panelsWrapper4"
                :key="keyPanelWrapper"
                class="my-4"
                light
            >
                <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-row no-gutters>
                    <v-col cols="4"> {{ panelWrapper }} </v-col>
                    <v-col cols="4"> </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(panel, keyPanel) in panels"
                        :key="keyPanel"
                    >
                        <v-expansion-panel-header>
                        <v-row no-gutters>
                            <v-col cols="4"> Orden {{ panel }} </v-col>
                            <v-col cols="8" class="d-flex justify-end align-center">
                            <!-- <v-fade-transition leave-absolute>
                                            <span v-if="open">When do you want to travel?</span>
                                            <v-row v-else no-gutters style="width: 100%">
                                                <v-col cols="6">
                                                    Start date: {{ trip.start || 'Not set' }}
                                                </v-col>
                                                <v-col cols="6">
                                                    End date: {{ trip.end || 'Not set' }}
                                                </v-col>
                                            </v-row>
                                        </v-fade-transition> -->
                            <v-icon
                                small
                                class="mr-2"
                                @click="$event.stopPropagation()"
                                color="primary"
                            >
                                mdi-cash-sync
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="$event.stopPropagation()"
                                color="success"
                            >
                                mdi-cash-multiple
                            </v-icon>
                            <v-icon
                                small
                                @click="$event.stopPropagation()"
                                color="error"
                                class="mr-3"
                            >
                                mdi-close-box-multiple-outline
                            </v-icon>
                            </v-col>
                        </v-row>
                        <!-- <template v-slot:actions>
                                    <v-icon color="primary">
                                    $expand
                                    </v-icon>
                                </template> -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                        <v-row justify="space-around" no-gutters>
                            <VCol cols="12">
                            <v-data-table
                                :headers="headers1"
                                :items="desserts1"
                                sort-by="calories"
                                class="elevation-0"
                                show-expand
                                :expanded.sync="expanded"
                                item-key="name"
                                dense
                                :hide-default-header="false"
                            >
                                <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    Más información del ítem {{ item.name }}
                                </td>
                                </template>
                                <template v-slot:item.actions="{ item }">
                               <custom-btn
                                ref="button1"
                                color="primary"
                                :attributes="{icon:true,'small':true}"
                                :attributesIcon="{'small':true}"
                                outlined
                                custom-class="text-none"
                                icon="pencil"
                                tooltip="Modificar datos"
                            />
                            <custom-btn
                                ref="button1"
                                color="error"
                                :attributes="{icon:true,'small':true}"
                                :attributesIcon="{'small':true}"
                                outlined
                                custom-class="text-none"
                                icon="delete-outline"
                                tooltip="Eliminar órden de pago"
                            />
                                </template>
                            </v-data-table>
                            </VCol>
                        </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-expand-transition>
    </VCol>

  </VRow>
</template>

<script>
/* eslint-disable */
export default {
  name: "OrdenesDePagoForm",
  data: () => ({
    show1:true,
    show2:false,
    show3:false,
    show4:false,
    date: null,
    panelsWrapper: ["Abierta", "En revisión", "Enviadas"],
    panelsWrapper1: ["En revisión", "Revisadas"],
    panelsWrapper2: [],
    panelsWrapper3: [],
    panelsWrapper4: [],
    panels: ["1", "2", "3", "4", "5", "6"],
    panels1: ["1"],
    expanded: [],
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      {
        text: "Item 1",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Item 2", value: "calories" },
      { text: "Item 3", value: "fat" },
      { text: "Item 4", value: "carbs" },
      { text: "Item 5", value: "protein" },
      { text: "", value: "data-table-expand" },
    ],
    headers1: [
      { text: "Acciones", value: "actions", sortable: false },
      {
        text: "Creación",
        align: "start",
        sortable: false,
        value: "created",
      },
      { text: "Usuario", value: "user" },
      { text: "Importe", value: "amount" },
      { text: "Destino", value: "destino" },
      { text: "CBU", value: "cbu" },
      { text: "Concepto", value: "concept" },
      { text: "Referencia", value: "ref" },
    //   { text: "", value: "data-table-expand" },
    ],
    desserts1: [],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    handleTableAction() {},
    initialize() {
      this.desserts = [
        {
          name: "Valor 1",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Valor 2",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
        },
        {
          name: "Valor 3",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Valor 4",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },
        {
          name: "Valor 5",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
        },
        {
          name: "Valor 6",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
        },
        {
          name: "Valor 7",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
        },
        {
          name: "Valor 8",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
        {
          name: "Valor 9",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
        },
        {
          name: "Valor 10",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
        },
      ];

      this.desserts1 = [
        {
            user: "Usuario 1",
          created: "1/1/2023",
          amount: 6.0,
          destino: "Destino",
          cbu: 11111111111111,
          concept: 'Reintegro',
          ref: '1111',
        },
        {
            user: "Usuario 2",
          created: "1/1/2023",
          amount: 6.0,
          destino: "Destino",
          cbu: 11111111111111,
          concept: 'Reintegro',
          ref: '1111',
        },
        {
            user: "Usuario 3",
          created: "1/1/2023",
          amount: 6.0,
          destino: "Destino",
          cbu: 11111111111111,
          concept: 'Reintegro',
          ref: '1111',
        },
        {
            user: "Usuario 4",
          created: "1/1/2023",
          amount: 6.0,
          destino: "Destino",
          cbu: 11111111111111,
          concept: 'Reintegro',
          ref: '1111',
        },
        {
            user: "Usuario 5",
          created: "1/1/2023",
          amount: 6.0,
          destino: "Destino",
          cbu: 11111111111111,
          concept: 'Reintegro',
          ref: '1111',
        }
      ];
    },
  },
};
</script>
<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75),
    inset 0 -4px 4px -5px rgba(50, 50, 50, 0.75);
}
</style>