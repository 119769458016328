import Cookies from 'js-cookie';

const TokenKey = 'ssp_session';

export function getToken (key = TokenKey) {
	let token = Cookies.get(key);
	if (token) {
		token = JSON.parse(token);
	}
	if (key === 'ssp_session') {
		return token?.token_type && token?.access_token ? { token_type: token.token_type, access_token: token.access_token, firstname: token.firstname, lastname: token.lastname } : false;
	}

	return token;
}

export function setToken (token, key = TokenKey) {
	return Cookies.set(key, token);
}

export function removeToken (key = TokenKey) {
	return Cookies.remove(key);
}
