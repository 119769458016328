<template>
  <VRow>
    <custom-loading :loading="loading" />
    <VCol
      v-for="(card, i) in modules"
      :key="`module-${i}`"
      cols="6"
      sm="6"
      md="auto"
      class="d-flex justify-center justify-md-start"
    >
      <material-card
        color="transparent"
        elevation="0"
        :to="card.url"
        class="rounded p-relative"
        style="width: 145px"
      >
        <template #img>
          <folder :fill="currentModule.color" width="100%" />
          <div style="top: 0; right: 0" class="pa-2 w-100 p-absolute">
            <v-avatar
              class="mt-3 d-flex"
              :color="currentModule.color"
              size="25"
              style="margin-left: auto"
            >
              <v-icon size="20px" dark dense>
                mdi-cog-play-outline
              </v-icon>
            </v-avatar>
          </div>

          <div
            style="top: 50%; left: 0; transform: translateY(-50%);"
            class="white--text align-start justify-center text-subtitle-2 font-weight-negrita d-block pa-3 w-100 p-absolute"
          >
            {{ card.name }}
          </div>
        </template>
      </material-card>
    </VCol>
  </VRow>
</template>

<script>
import { genericRequest } from '@/api/modules';

export default {
	name: 'Tasks',
	mixins: [],
	props: {
		currentModule: {
			type: [Object],
			default: () => {},
		},
	},
	data() {
		return {
			loading: false,
			modules: [],
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.getTasks();
	},
	methods: {
		async getTasks() {
			this.loading = true;
			this.modules = [];
			const modules = [];
			const url = '/templates';
			const { resources } = await genericRequest({
				url,
				method: 'get',
				params: { task_type_name: this.currentModule.name },
				service: 'tareas',
			});

			resources.forEach((value) => {
				modules.push({
					name: value.name,
					url: `/${this.currentModule.url}/${value.category.name}/${this.currentModule.name}/task`,
				});
			});
			this.modules = modules;
			this.loading = false;
		},
	},
};
</script>

<style>
</style>
