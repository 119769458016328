<template>
  <VRow
    align="center"
    justify="start"
    class="py-1"
  >
    <VCol cols="auto">
      <div
        v-if="label"
        class="text-headline"
        :class="{'cursor-pointer':showHistory}"
        @click="showHistory? showHistoryEvent():false"
      >
        {{ label }}
      </div>
    </VCol>
    <VCol
      v-if="showHistory"
      cols="auto"
      class="px-1"
    >
      <custom-btn
        :attributes="{icon:true, 'x-small':true, 'prepend-icon':showIcon}"
        :tooltip="showTooltip"
        @handleClick="showHistoryEvent"
      />
    </vcol>
    <form-modal
      :open.sync="dialogForm"
      max-width="700px"
      :form="form"
      :items="items"
      @confirm="confirmModalAction"
    />
  </VRow>
</template>

<script>
import tableMapper from '@/mappers/tables';
export default {
	name: 'CustomInputHistoryBrowse',
	props: {
		value: {
			type: [Number, String, Boolean, Array],
			default: function () {
				return '';
			}
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		label: {
			type: [String, null],
			default: ''
		},

		model: {
			type: Object,
			default: function () {
				return {};
			}
		},
		showTooltip: {
			type: [String, null],
			default: 'Ver histórico'
		},
		showIcon: {
			type: [String, null],
			default: 'eye'
		},
		edit: {
			type: Boolean,
			default: false
		},
		showHistory: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			dialogForm: false,
			form: false,
			items: []
		};
	},
	computed: {
	},
	methods: {
		confirmModalAction (data) {
			this.$emit('reload-form', { ...data, field: this.field });
			this.reset();
		},
		reset () {
			this.items = [];
			this.form = false;
		},
		showHistoryEvent () {
			this.reset();

			let form = null;
			try {
				if (this.field?.form?.history) {
					form = require(`@/jsons/${this.field.form.history}.json`);
					if (form) {
						form = tableMapper(form);
						this.items = this.value;
					}
				}
			} catch (e) {
				console.log('No se encontró el json');
			}

			this.form = form || false;
			this.dialogForm = !!form;
		}
	}
};

</script>

<style>

</style>
