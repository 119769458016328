
import topLeft from './blocks/topLeft';
import itemDetalle from './blocks/itemDetalle';
import itemAfiliado from './blocks/itemAfiliado';
import itemPrestacion from './blocks/itemPrestacion';
import date from './blocks/date';
import title from './blocks/title';
const COPIES = 1;

const generatePdf = (data = {}) => {
	const content = [];
	let page = 0;

	for (let index = 1; index <= COPIES; index++) {
		content.push(...date(data));
		if(data.benefit_type){
			content.push(...title(data));
		}
		if(data.person){
			content.push(...itemAfiliado(data));
		}
		if(data.benefit_subtype){
			content.push(...itemPrestacion(data));
		}
		content.push(...itemDetalle(data));
		content.push(
			{
				text: '',
				pageBreak: index < COPIES ? 'after' : false
			}
		);
	}

	const docDefinition = {
		pageSize: 'A4',

		// by default we use portrait, you can change it to landscape if you wish
		pageOrientation: 'portrait',

		// [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
		pageMargins: [40, 60, 30, 60],
		header: function () {
			return [
				...topLeft(data)
			];
		},
		footer: function (currentPage, pageCount) {
			if (page >= Math.ceil(pageCount / COPIES)) {
				page = 0;
			}
			page += 1;

			return [
				{
					text: `${page} / ${Math.ceil(pageCount / COPIES)}`,
					color: '#000000',
					fontSize: 9,
					bold: false,
					margin: [0, 35, 10, 0],
					alignment: 'right'
				}
			];
		},
		content,
		styles: {
			notesTitle: {
				fontSize: 10,
				bold: true,
				margin: [0, 50, 0, 3]
			},
			notesText: {
				fontSize: 10
			}
		},
		defaultStyle: {
			columnGap: 20,
			font: 'Inter'
		}
	};

	return { ...docDefinition };
};
export default generatePdf;
