<template>
  <div>
    <v-text-field
      :value="textValue"
      :label="label"
      readonly
    >
      <template #append>
        <div
          v-if="edit"
          cols="auto"
          class="px-1"
        >
          <custom-btn
            :attributes="{icon:true, 'x-small':true, 'prepend-icon':editIcon}"
            :tooltip="editTooltip"
            @handleClick="handleEdit"
          />
        </div>
        <div
          v-if="showHistory"
          cols="auto"
          class="px-1"
        >
          <custom-btn
            :attributes="{icon:true,'x-small':true, 'prepend-icon':showIcon}"
            :tooltip="showTooltip"
            @handleClick="showHistoryEvent"
          />
        </div>
      </template>
    </v-text-field>

    <form-modal
      :open.sync="dialogForm"
      max-width="700px"
      :form="form"
      :items="items"
      @confirm="confirmModalAction"
    />
  </div>
</template>

<script>
import { generateStringFromVariables } from '@/util/helpers';
import tableMapper from '@/mappers/tables';
import { getActiveFromArray } from '@/util/dataHelpers';
import jsons from '@/mixins/jsons';

export default {
	name: 'CustomInputEditBrowse',
	mixins: [jsons],
	props: {
		value: {
			type: [Number, String, Boolean, Array],
			default: function () {
				return '';
			}
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		label: {
			type: [String, null],
			default: ''
		},
		editIcon: {
			type: [String, null],
			default: 'pencil'
		},
		editTooltip: {
			type: [String, null],
			default: 'Editar'
		},
		model: {
			type: Object,
			default: function () {
				return {};
			}
		},
		showTooltip: {
			type: [String, null],
			default: 'Ver histórico'
		},
		showIcon: {
			type: [String, null],
			default: 'history'
		},
		edit: {
			type: Boolean,
			default: false
		},
		showHistory: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			dialogForm: false,
			form: false,
			items: []
		};
	},
	computed: {
		textValue () {
			let txt = null;
			const fieldValue = getActiveFromArray(this.value);
			if (fieldValue) {
				txt = generateStringFromVariables({ title: this.field.title, data: fieldValue });
			}
			return txt !== 'null' ? txt || '-' : '-';
		}
	},
	methods: {
		confirmModalAction (data) {
			this.$emit('reload-form', { ...data, field: this.field });
			this.reset();
		},
		reset () {
			this.items = [];
			this.form = false;
		},
		handleEdit () {
			this.reset();

			this.form = this.importJson(`${this.field.form.edit}.json`);

			this.dialogForm = !!this.form;
		},
		showHistoryEvent () {
			this.reset();

			let form = null;
			if (this.field?.form?.history) {
				form = this.importJson(`${this.field.form.history}.json`);
				if (form) {
					form = tableMapper(form);
					this.items = this.value;
				}
			}

			this.form = form || false;
			this.dialogForm = !!form;
		}
	}
};

</script>

<style>

</style>
