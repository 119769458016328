<template>
  <v-row class="v-data-footer pagination" justify="end" align="center">
    <div class="px-5 d-flex align-center">
      <div class="text-caption txtGrey--text font-weight-light">
        Ítems por página
      </div>
      <div class="select-container mx-4">
        <v-select
          v-model="_per_page"
          dense
          hide-details
          flat
          :items="itemsPerPage" 
        >
          <template #selection="{item}">
            <span class="text-subtitle-2 txtGrey--text font-weight-light">
              {{ item }}
            </span>
          </template>
        </v-select>
      </div>
    </div>

    <v-pagination
      v-model="_page"
      :length="dataPagination.last_page"
      color="blue-grey"
      :total-visible="7"
      light
      @input="$emit('pageChanged')"
    />
  </v-row>
</template>

<script>
export default {
	name: 'CustomPagination',
	props: {
		dataPagination: {
			type: Object,
			default: ()=>({
				current_page:1,
				last_page:1,
				total:1
			})
		},
		pagination: {
			type: Object,
			default: ()=>({
				page:1
			})
		},
		perPage:{
			type: Number,
			default: 10
		},
		itemsPerPage: {
			type: Array,
			default: ()=>([10])
		}
	},
	computed: {
		_page: {
			get: function () {
				return Number(this.pagination.page);
			},
			set: function (value) {
				this.$emit('update', {item:'page',value});
			}
		},
		_per_page: {
			get: function () {
				return this.perPage;
			},
			set: function (value) {
				this.$emit('update',{item:'per_page', value});
			}
		}
	}
};
</script>

<style lang="scss">
.pagination{
	.v-pagination__item,.v-pagination__navigation{
		min-width: 26px;
		height: 26px;
		font-size: 12px;
	}

	.select-container{
		width: 55px;
	}
}

</style>