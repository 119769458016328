<template>
  <div>
    <VRow
      class=""
      justify="center"
    >
      <VCol
        cols="12"
        md="6"
      >
        <VForm
          ref="services-module"
          class=""
          :disabled="loading"
        >
          <VRow
            class=""
            justify="center"
          >
            <VCol
              cols="8"
            >
              <VAutocomplete
                v-model="service"

                :items="servicesList"
                label="Servicios"
                item-text="name"
                item-value="uuid"
                no-data-text="Sin datos"
                dense
                outlined
                :rules="textRules"
                :filter="customFilter"
              >
                <template
                  #selection="data"
                >
                  {{ data.item.name }}
                  <v-icon
                    v-if="data.item.disabled"
                    color="green"
                    small
                    class="ml-2"
                  >
                    mdi-check
                  </v-icon>
                </template>

                <template
                  #item="data"
                >
                  {{ data.item.name }}
                  <v-icon
                    v-if="data.item.disabled"
                    color="green"
                    small
                    class="ml-2"
                  >
                    mdi-check
                  </v-icon>
                </template>
              </VAutocomplete>
            </VCol>
            <VCol cols="auto">
              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="handleClickAdd"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Agregar servicio</span>
              </v-tooltip>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
      <VCol
        cols="12"
        md="7"
        class="pt-0"
      >
        <simple-table
          :form="{ headers }"
          :items="servicesModule"
          :loading="loadingTable || !loaded || loading"
          item-key="uuid"
          @delete="deleteService"
        />
      </VCol>
    </VRow>
    <VRow
      v-if="actions && actions.length"
      class="custom-footer"
      justify="center"
    >
      <VCol
        class="d-flex align-center pb-6 pt-12"
        cols="11"
      >
        <VRow
          justify="space-between"
          style="width: 100%"
        >
          <VCol
            v-for="(action, index) in actions"
            :key="`action-${index}`"
            cols="12"
            :md="action.col ? action.col : 'auto'"
            class="pb-1"
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              custom-class="text-none"
              :color="action.style ? action.style.type : ''"
              :width="action.style ? action.style.width : ''"
              :loading="loading || !loaded "
              :icon="action.style ? action.style.icon : false"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="handleClick(action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { genericRequest } from '@/api/modules';
import { toAttributes} from '@/mappers/form';
export default {
	name: 'EditServiceModule',
	props: {
		form: {
			type: Object,
			default: function () {
				return {};
			}
		}

	},
	data () {
		return {
			toAttributes,
			loaded: false,
			loading: true,
			loadingTable: true,
			services: [],
			service: null,
			textRules: [(v) => !!v || 'Campo requerido'],
			headers: [
				{ text: 'Servicio', value: 'name' },
				{
					text: '',
					value: 'actions',
					cellClass: 'text-end',
					data: [
						{
							title: 'Eliminar servicio',
							confirmation: {
								message: 'Vas a borrar el servicio {name}'
							},
							style: {
								attributes: [     
									'small',
									'icon',
									'prepend-icon:delete-outline',
									'color:danger'
								]
							},

							event: {
								action: 'delete'
							},
							col: '12'
						}
					]
				}
			],
			servicesModule: []
		};
	},
	computed: {
		meta: get('route/meta'),
		params: get('route/params'),
		customTitle: sync('app/customTitle'),
		id () {
			return this.params?.id;
		},
		servicesList () {
			const services = this.services;
			return services.map(service => {
				return { ...service, disabled: !!this.servicesModule.find(serviceGroup => { return serviceGroup?.uuid === service.uuid; }) };
			});
		},
		actions(){
			return this.form?.steps?.[0]?.actions || this.form.actions;
		}
	},
	mounted () {
		this.getData();
		this.loading = false;
	},
	methods: {
		customFilter (item, queryText) {
			const textOne = item.name.toLowerCase();
			const textTwo = item.uuid.toLowerCase();
			const searchText = queryText.toLowerCase();
			return (
				textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
			);
		},
		async getData () {
			await this.getServices();
			await this.getModule();
			this.loading = false;
			this.loaded = true;
		},

		async getServices () {
			const { resources } = await genericRequest({
				url: '/services',
				method: 'get'
			});
			if (resources?.length) {
				this.services = resources;
			}
		},
		async getModule () {
			this.loadingTable = true;
			const { resource } = await genericRequest({
				url: `modules/${this.id}`,
				method: 'get'
			});
			this.customTitle = resource.name;
			this.servicesModule = resource?.services?.length ? resource.services : [];
			this.loadingTable = false;
		},

		async deleteService (data) {
			if (data?.item?.uuid) {
				this.loading = true;
				await genericRequest({
					url: `/modules/${this.id}/services/${data.item.uuid}`,
					method: 'delete'
				});

				this.$refs['services-module'].reset();
				this.loading = false;
				this.getModule();
			}
		},

		async handleClickAdd () {
			if (this.$refs?.['services-module'].validate()) {
				this.loading = true;
				await genericRequest({
					url: `/modules/${this.id}/services`,
					method: 'post',
					params: { service_uuid: this.service }
				});

				this.$refs['services-module'].reset();
				this.loading = false;
				this.getModule();
			}
		},

		handleClick (action) {
			if (!action?.submit && action?.redirect?.href) {
				if (action?.redirect?.service === 'local') {
					this.$router
						.push({ path: action.redirect.href || '/' })
						.catch((error) => {
							console.log(error.message);
						});
				}
			} else if (action.event?.action) {
				if (action.event.reset) {
					this.$refs['services-module'].reset();
				}

				this.$emit(action.event.action, action);
			}
		}
	}

};
</script>

<style>
.v-data-table.row-height-dense td
{
  height: 30px !important;
}
</style>
