<template>
  <v-select
    v-model="benefit"
    class="pt-0"
    :items="customItems"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="multiple"
    no-data-text="El afiliado no posee prestaciones habilitadas"
    :loading="loading"
    :disabled="loading"
    :rules="rules"
    @change="handleInputChange(field, $event)"
  >
    <template
      #selection="data"
    >
      {{ data.item[itemText] }}
    </template>

    <template
      #item="data"
    >
      {{ data.item[itemText] }}
      <div
        v-if="data.item.added"
        class="d-flex align-center justify-center"
      >
        <v-icon

          color="error"
          small
          class="ml-2"
        >
          mdi-alert-circle
        </v-icon>
        <span class="text-caption error--text">
          Beneficio incluido en otro bloqueo
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>

export default {
	name: 'CustomBenefitTypeSelector',
	props: {
		eventsBus: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		items: {
			type: [Array],
			default: function () {
				return [];
			}
		},
		rules: {
			type: [Array],
			default: function () {
				return [];
			}
		},
		loading: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: ''
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		value: {
			type: [String, Boolean, Array, Object],
			default: false
		},
		dataParentModel: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		},
		model: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		}
	},
	data () {
		return {
			benefit: []
		};
	},
	computed: {
		itemValue () {
			return this.field?.key ? this.field.key : 'slug';
		},
		itemText () {
			return this.field?.text ? this.field.text : 'text';
		},

		customItems () {
			return this.items.map(e=>
			{
				let added=false;
				if(this.dataParentModel?.locks?.length){
					added=this.dataParentModel.locks.find(lock=>{
						return !lock.deleted_at && lock.lock_benefits.find(lockBenefit=>lockBenefit.benefit_type.uuid===e.uuid);
					});
				}
				return {...e,added,disabled:added};
			});
		}
	},
	watch: {

	},
	mounted () {

	},
	methods: {
		handleInputChange (field, value) {
			this.$emit('changeCustomInput', value);
		}

	}
};

</script>

<style>

</style>
