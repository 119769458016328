<template>
  <div class="d-flex justify-center">
    <v-autocomplete
      v-model="selectValue"
      class="pt-0"
      :items="localItems"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      no-data-text="Sin datos"
      :loading="loading"
      :disabled="loading"
      :rules="rules"
      @change="handleInputChange(field, $event)"
    >
      <template v-if="isSelectCustomText" #selection="{ item: dataItem }">
        {{ generateStringFromVariables({ title: isSelectCustomText, data: dataItem }) }}
      </template>

      <template v-if="isSelectCustomText" #item="{ item: dataItem }">
        <v-list-item-content>
          <v-list-item-title v-text="generateStringFromVariables({ title: isSelectCustomText, data: dataItem })" />
          <v-list-item-subtitle v-if="isSelectCustomDescription" v-text="generateStringFromVariables({ title: isSelectCustomDescription, data: dataItem })" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <div
      v-if="add"
      class="px-1 pt-2"
    >
      <custom-btn
        :attributes="{icon:true, 'small':true, 'prepend-icon':addIcon}"
        :tooltip="addTooltip"
        @handleClick="handleAdd"
      />
    </div>
    <form-modal
      :open.sync="dialogForm"
      max-width="700px"
      :form="form"
      @confirm="confirmModalAction"
    />
  </div>
</template>

<script>
import { generateStringFromVariables } from '@/util/helpers';
import jsons from '@/mixins/jsons';
export default {
	name: 'SelectSearch',
	mixins: [jsons],
	props: {
		eventsBus:  {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		rules: {
			type: [Array],
			default: function () {
				return [];
			}
		},
		addTooltip: {
			type: [String, null],
			default: 'Agregar'
		},
		addIcon: {
			type: [String, null],
			default: 'plus'
		},
		loading: {
			type: Boolean,
			default: false
		},
		add: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: ''
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		value: {
			type: [String, Boolean, Array, Object],
			default: false
		},
		items: {
			type: [Array],
			default: function () {
				return [];
			}
		},
		dataParentModel: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		},
		model: {
			type: [Object, Boolean],
			default: function () {
				return false;
			}
		}
	},
	data () {
		return {
			dialogForm: false,
			selectValue: this.value,
			form: false,
			generateStringFromVariables,
			localItems: []
		};
	},
	computed: {
		itemValue () {
			return this.field?.key ? this.field.key : 'slug';
		},
		itemText () {
			return this.field?.text ? this.field.text : 'text';
		},
		isSelectCustomDescription(){
			return this.field?.['custom-description'];

		},
		isSelectCustomText() {
			return this.field?.['custom-text'];
		},
	},
	watch: {
		value (value) {
			this.selectValue = value;
		},
		items(items){
			this.localItems = items;
		}
	},
	mounted () {
		this.selectValue = this.value;
	},
	methods: {
		confirmModalAction (data) {
			const resource = data?.response?.resource;
			if(resource?.uuid){
				this.localItems.push(resource);
				this.selectValue = resource.uuid;
				this.$emit('changeCustomInput', resource.uuid);
			}
			// this.$emit('reload-form', { ...data, field: this.field });x
			this.reset();
		},
		handleInputChange (field, value) {
			this.$emit('changeCustomInput', value);
		},
		reset () {
			this.form = false;
		},
		handleAdd () {
			this.reset();
			this.form =  this.importJson(`${this.field.form.add}.json`);
			this.dialogForm = !!this.form ;
		},
	}
};

</script>

<style>

</style>
