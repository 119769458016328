<template>
  <div class="pb-10 pt-7 pl-md-6">
    <custom-loading :loading="loading" />
    <ExpansionPanels
      v-for="(status,key) in statusTypes"
      :key="key"
      :data="status"
      :key-value="key"
      @open="handleOpen"
    />
  </div>
</template>

<script>
import { genericRequest } from '@/api/modules';

export default {
	name: 'OrdenesDePagoCasasComerciales',
	data: () => ({
		statusTypes: [],
		loading:false
	}),

	computed: {},

	watch: {},

	created() {
		this.initialize();
	},

	methods: {
		handleTableAction() {},
		async handleOpen(data){
			console.log(data);
		},
		async initialize() {
			this.loading=true;
			await this.getRemittanceStatusTypes();
			this.loading=false;
		},
		async getRemittanceStatusTypes() {
			const { resources } = await genericRequest({
				url: 'remittance-status-types',
				method: 'get',
				service: 'remesas',
				checkTokenStatus: true
			});
			this.statusTypes = resources;
		},
	},
};
</script>
<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75),
    inset 0 -4px 4px -5px rgba(50, 50, 50, 0.75);
}
</style>