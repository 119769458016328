import Vue from 'vue';
import Vuetify from 'vuetify';
import { es } from 'vuetify/lib/locale';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const light = {
	primary: '#1428a0',
	afiliaciones: '#0D47A1',
	primaryDark: '#1428a0',
	secondary: '#C5CAE9',
	accent: '#e91e63',
	info: '#00CAE3',
	success: '#4CAF50',
	warning: '#FB8C00',
	error: '#FF5252',
	menuBg: '#eee',
	menuBgDrawer: '#fafafa',
	successLabel: '#21ba45',
	background: '#f5f5f5',
	bgDisabled:'#E0E0E0',
	textDisabled:'#A6A6A6',
	textNormal:'#4f4f4f',
	txtGrey:'#727272'
};

const dark = {
	primaryDark: '#fff',
	menuBg: '#272727',
	primary: '#1428a0',
	secondary: '#1428a0',
	bgDisabled:'#E0E0E0',
	textDisabled:'#A6A6A6',
	textNormal:'#4f4f4f',
	txtGrey:'#727272'
};

export default new Vuetify({
	breakpoint: { mobileBreakpoint: 960 },
	lang: {
		locales: { es },
		current: 'es'
	},
	icons: {
		values: { expand: 'mdi-menu-down' }
	},
	theme: {
		themes: {
			dark,
			light
		}
	}
});
