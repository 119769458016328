<template>
  <VContainer fluid class="px-0 px-md-1 px-lg-2">
    <VRow id="topTableAnchor">
      <VCol
        v-if="dataTable.sourceActions && dataTable.sourceActions.length"
        cols="12"
        class="d-flex justify-end align-center"
      >
        <template v-for="(action,key) in dataTable.sourceActions">
          <custom-btn
            v-if="checkAction(action)"
            :key="`actionSource_${key}`"
            :attributes="toAttributes(action.style)"
            :tooltip="action.title"
            :label="action.label"
            :href="action.href"
            :custom-class="action.style?action.style.class:''"
            @handleClick="handleSourceAction(action)"
          />
        </template>
      </VCol>
      <VCol
        cols="12"
        class="px-0 pt-0"
        :class="{'pt-6':!(dataTable.sourceActions && dataTable.sourceActions.length)}"
      >
        <v-data-table
          v-model="itemsSelected"
          :item-class="itemRowBackground"
          dense
          :items-per-page="perPage"
          :headers="headers"
          :items="items"
          sort-by="id"
          :item-key="itemKey"
          :show-select="showSelect"
          class="elevation-0"
          locale="es-AR"
          no-data-text="Sin datos"
          no-results-text="No se encontraron resultados"
          :loading="loading"
          loading-text="Cargando"
          :search="search"
          :custom-filter="filterOnlyCapsText"
          :hide-default-footer="isPageable"
          :footer-props="{
            itemsPerPageText: 'Ítems por página',
            itemsPerPageOptions: itemsPerPage,
          }"
          @click:row="handleRowClick"
          @current-items="handleChangeItems"
        >
          <template
            v-if="isSearch || (dataTable.search && dataTable.search.param) || (dataTable.filters && dataTable.filters.length)"
            #top
          >
            <v-col
              v-if="isSearch && !isPageable"
              cols="12"
              md="8"
              lg="6"
              xl="4"
              class="mb-5"
            >
              <v-text-field
                v-model="search"
                label="Buscar"
                clearable
                color="secondary"
                dense
                enclosed
                hide-details
                append-icon="mdi-magnify"
                :disabled="loading"
              />
            </v-col>

            <VCol
              v-if="dataTable.search && dataTable.search.param"
              cols="12"
              :md="dataTable.search.cols?dataTable.search.cols:6"
              :xl="dataTable.search.cols?dataTable.search.cols:4"
              class="mb-6"
            >
              <search
                :disabled="loading || searchDisabled"
                :loading="loading"
                @click="filter($event,dataTable.search)"
              />
            </VCol>

            <VCol
              v-if="dataTable.filters && dataTable.filters.length"
              cols="12"
              class="pt-0"
            >
              <VRow align="center" justify="end">
                <VCol v-for="(filterItem, keyFilter) in dataTable.filters" :key="`filter_${keyFilter}`" cols="auto">
                  <component
                    :is="filterItem.type"
                    v-model="query[filterItem.param]"
                    color="primary"
                    dense
                    hide-details
                    class="my-0"
                    :label="filterItem.label"
                    :value="filterItem.defaultValue"
                    :true-value="'trueValue' in filterItem ? filterItem.trueValue : true"
                    :false-value="'falseValue' in filterItem ? filterItem.falseValue : false"
                    @change="filter($event,filterItem)"
                  />
                </VCol>
              </vrow>
            </vcol>
          </template>
          <template #[`header.data-table-select`] />
          <template
            v-for="(header_slot,key) in dataTable.headers"
            #[`item.${header_slot.value}`]="{ item, index }"
          >
            <div
              v-if="header_slot.type"
              :key="`item_${key}-${index}`"
              class="d-flex align-center"
              :class="header_slot.class"
            >
              <template v-if="header_slot.type==='dropdown_action'">
                <v-select
                  :ref="`dropdown_action_${item.uuid}`"
                  :items="checkListValid(item,header_slot.options)"
                  :label="findValidValue(header_slot.property?toPathObject(item,header_slot.property):item[header_slot.value],'name')"
                  dense
                  :item-text="header_slot.itemText"
                  :item-value="header_slot.itemValue"
                  outlined
                  hide-details
                  height="25px"
                  solo
                  class="my-2"
                  return-object
                  :value="findValidValue(header_slot.property?toPathObject(item,header_slot.property):item[header_slot.value],'uuid')"
                  @change="handleActionDropdownChange($event,{item,header_slot})"
                  @click="handleActionDropdownClick"
                >
                  <template
                    #selection="data"
                  >
                    <span class="text-subtitle-2">{{ data.item.name }}</span>
                  </template>

                  <template
                    #item="data"
                  >
                    <span class="text-subtitle-2">{{ data.item.name }}</span>
                    <v-icon
                      :color="findValidValue(header_slot.property?toPathObject(item,header_slot.property):item[header_slot.value],'uuid') === data.item.uuid ? 'green' : 'txtGrey'"
                      small
                      class="ml-2"
                    >
                      {{ findValidValue(header_slot.property?toPathObject(item,header_slot.property):item[header_slot.value],'uuid') === data.item.uuid ? 'mdi-check' : 'mdi-swap-horizontal' }}
                    </v-icon>
                  </template>
                </v-select>
              </template>
              <!-- if is text or boolean -->
              <template v-else-if="toType(header_slot.type)==='text' || toType(header_slot.type)==='boolean'">
                <template v-if="toType(header_slot.type)==='text'">
                  {{ formatterTextField(header_slot,item) }}
                </template>
                <template v-else-if="toType(header_slot.type)==='boolean'">
                  <v-icon
                    v-if="header_slot.property?toPathObject(item,header_slot.property):item[header_slot.value]"
                    small
                  >
                    mdi-check
                  </v-icon>
                </template>
              </template>

              <!-- if is other type -->
              <div v-else>
                <component
                  :is="header_slot.type | toVuetifyField"
                  v-for="(itemInner,itemInnerIndex) in isArray(item[header_slot.value])?item[header_slot.value]:[{name:item[header_slot.value]}]"
                  :key="`${key}_custom_component_${index}_${itemInnerIndex}`"
                  :value="item[header_slot.value]"
                  v-bind="toAttributes(header_slot.style)"
                  :class="header_slot.style?header_slot.style.class:''"
                  :disabled="isLoading || loadingItem || !(header_slot.modifying && header_slot.modifying.modifiable==='true' && checkAction(header_slot.modifying)) "
                  autofocus
                  @changeCustomInput="handleAction({item,header:header_slot,value:$event,index})"
                >
                  {{ itemInner.name }}
                </component>
              </div>

              <!-- if item is modificable-->
              <template v-if="header_slot.type!=='color' && header_slot.modifying && header_slot.modifying.modifiable==='true' && checkAction(header_slot.modifying)">
                <div
                  :key="`${key}_component_${index}`"
                  class="d-flex justify-space-between align-center"
                >
                  <v-edit-dialog
                    :ref="`edit_dialog_${header_slot.value}-${index}`"
                    eager
                    @save="handleAction({item,header:header_slot,index})"
                    @open="handleChange({item,header:header_slot,value:item[header_slot.value],index})"
                  >
                    <v-btn
                      class="ml-4"
                      elevation="0"
                      icon
                    >
                      <v-icon
                        dark
                        small
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <template #input>
                      <VForm
                        :key="`item_${key}-${index}`"
                        :ref="`form_${header_slot.value}-${index}`"
                        class=""
                        :disabled="isLoading || loadingItem"
                        @submit.prevent="handleAction({item,header:header_slot,index})"
                        @keyup.native.enter="handleAction({item,header:header_slot,index})"
                      >
                        <Component
                          :is="header_slot.type | toVuetifyField"
                          :ref="`edit_input_${header_slot.value}-${index}`"
                          justify="center"
                          v-bind="toAttributes(header_slot.style)"
                          :value="item[`edit_${header_slot.value}-flag`]"
                          :rules="header_slot.type
                            ? toVuetifyValidate(header_slot, item)
                            : []
                          "
                          class="input-edit-place"
                          :disabled="loadingItem"
                          :counter="header_slot.type | toCounter"
                          autofocus
                          @keyup.native.enter="handleAction({item,header:header_slot,index})"
                          @change="handleChange({item,header:header_slot,value:$event,index})"
                          @changeCustomInput="handleChange({item,header:header_slot,value:$event,index})"
                        />
                      </VForm>
                      <div class="d-flex align-center justify-end">
                        <v-btn
                          elevation="0"
                          icon
                          depressed
                          retain-focus-on-click
                          :loading="loadingItem"
                          @click="closeDialog({item,header:header_slot,index})"
                        >
                          <v-icon
                            dark
                            medium
                            color="error"
                          >
                            mdi-close
                          </v-icon>
                        </v-btn>
                        <v-btn
                          elevation="0"
                          icon
                          depressed
                          retain-focus-on-click
                          :loading="loadingItem"
                          @click="handleAction({item,header:header_slot,index})"
                        >
                          <v-icon
                            dark
                            medium
                            color="green"
                          >
                            mdi-check-bold
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-edit-dialog>
                </div>
              </template>
            </div>
            <template v-if="header_slot.value==='actions'">
              <div :key="`${key}_actions_${index}`" class="d-flex align-center justify-start">
                <template v-if="!item.deleted_at">
                  <template v-for="(action,keyData) in header_slot.data">
                    <template v-if="checkDepends(action,item) && checkAction(action)">
                      <template v-if="action.type==='dropdown_action'">
                        <v-select
                          :key="`${key}_actions_dropdown_${index}_${keyData}`"
                          :items="checkListValid(item,action.property)"
                          label="Estado"
                          dense
                          :item-text="action.itemText"
                          :item-value="action.itemValue"
                          outlined
                          hide-details
                          height="25px"
                          solo
                          class="my-2"
                          :value="findValidValue(item,'uuid')"
                          return-object
                          @change="handleActionDropdownChange($event,{item,header_slot})"
                        >
                          <template
                            #selection="data"
                          >
                            <span class="text-subtitle-2">{{ data.item.name }}</span>
                          </template>

                          <template
                            #item="data"
                          >
                            <span class="text-subtitle-2">{{ data.item.name }}</span>
                            <v-icon
                              v-if="data.item.disabled"
                              :color="findValidValue(item,'uuid') === data.item.uuid ? 'green' : 'grey'"
                              small
                              class="ml-2"
                            >
                              {{ findValidValue(item,'uuid') === data.item.uuid ? 'mdi-check' : 'mdi-arrow-right' }}
                            </v-icon>
                          </template>
                        </v-select>
                      </template>
                      <custom-btn
                        v-else
                        :key="`${key}_actions_${index}_${keyData}`"
                        :attributes="toAttributes(action.style)"
                        :tooltip="action.title"
                        :label="action.label"
                        :custom-class="action.style?`px-md-3 ${action.style.class}`:'px-md-3'"
                        :loading="item.loading==='loading'"
                        @handleClick="handleTableAction({item,header:header_slot,value:action})"
                      />
                    </template>
                  </template>
                </template>
                <v-tooltip v-else bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="error"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-remove-outline
                    </v-icon>
                  </template>
                  <span>Elemento eliminado</span>
                </v-tooltip>
              </div>
            </template>
          </template>
        </v-data-table>
      </VCol>
      <VCol v-if="isPageable">
        <custom-pagination 
          :data-pagination="dataPagination" 
          :pagination="pagination" 
          :items-per-page="itemsPerPage" 
          :per-page="perPage"
          @update="handlePaginationUpdate"
        />
      </VCol>
      <confirm-modal
        :open.sync="confirmModal"
        :text="confirmModalText"
        :is-overlay="confirmModalLoading"
        @confirm="confirmModalAction"
      />
      <form-modal
        :open.sync="dialogActionForm"
        max-width="700px"
        :data-source="selected"
        :form="actionForm"
        @confirm="confirmModalAction(true)"
      />
    </VRow>
    <VRow
      v-if="dataTable.bottomActions && dataTable.bottomActions.length"
      class="custom-footer"
      justify="center"
    >
      <VCol
        class="d-flex align-center pb-6 pt-12"
        cols="12"
      >
        <VRow
          :justify="dataTable.bottomActions.length===1?'end':'space-between'"
          style="width:100%"
        >
          <VCol
            v-for="(action, index) in dataTable.bottomActions"
            :key="`actionBottom-${index}`"
            cols="12"
            :md="action.col ? action.col : 'auto'"
            class="pb-1"
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              :custom-class="`text-none`"
              :loading="isLoading || loading"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="$emit(action.event&&action.event.action ?action.event.action:'click',action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import functionalities from '@/mixins/functionalities';
import { toAttributes, toVuetifyField, toVuetifyValidate, toCounter, toType,paramMapped } from '@/mappers/form';
import { genericRequest } from '@/api/modules';
import tableMapper from '@/mappers/tables';
import { toPathObject, generateStringFromVariables } from '@/util/helpers';
import { isArray } from '@/util/rules';
import { get, call } from 'vuex-pathify';
import formatter from '@/mixins/formatter';
import filters from '@/mixins/filters';
import anchors from '@/mixins/anchors';
import { checkAndFilterDepends } from '@/util/dataHelpers';
import { deepClone } from '@/util/objectHelpers';
import jsons from '@/mixins/jsons';

export default {
	name: 'BrowseTable',
	components: { },
	filters: {
		toVuetifyField,
		toCounter
	},
	mixins: [formatter, filters, anchors, functionalities, jsons],
	props: {
		showSelect: {
			type: Boolean,
			default: false,
		},
		tableName: {
			type: String,
			default: 'custom-table'
		},
		isSearch: {
			type: Boolean,
			default: false
		},
		itemKey: {
			type: String,
			default: 'uuid'
		},
		form: {
			type: Object,
			default: function () {
				return { headers: [] };
			}
		},
		externalQuery:{
			type: Object,
			default: function () {
				return { };
			}
		},
		parentData: {
			type: [Array, Object],
			default: null
		},
		selectedItems: {
			type: [Array],
			default: function () {
				return [] ;
			}
		},
		clickRow: {
			type: Boolean,
			default: false
		},
		forceInitialize: {
			type: Boolean,
			default: false
		},
		searchDisabled: {
			type: Boolean,
			default: false
		},
		toTop: {
			type: Boolean,
			default: true
		},
	},

	data: () => ({
		selected:null,
		search: '',
		toVuetifyValidate,
		toType,
		isArray,
		loading: true,
		items: [],
		toAttributes,
		isLoading: false,
		confirmModal: false,
		confirmModalText: 'Confirmar',
		confirmValue: null,
		confirmModalLoading: false,
		dialogActionForm: false,
		actionForm: false,
		dataTable: {},
		toPathObject,
		loadingItem: false,
		dataPagination:{},
		pagination:{
			page:1
		},
		query:{},
		currentModule:{}
	}),
	computed:{
		...get('route', ['meta']),
		itemsSelected: {
			get(){
				return this.selectedItems || [];
			},
			set(newVal){
				this.$emit('update:selectedItems', newVal);
			} 
		},
		metaColor(){
			return this.meta?.color;
		},
		isPageable(){
			return this.form?.paging && String(this.form?.paging?.pageable)==='true';
		},
		defaultPerPage(){
			const defaultPerPage=this.form?.paging?.default_per_page || 10;
			return Number(defaultPerPage);
		},
		perPage(){
			return this.pagination.per_page || this.defaultPerPage;
		},
		itemsPerPage(){
			const itemsPerPage= this.form?.paging?.items_per_page?.length?this.form.paging.items_per_page:[      
				'10',
				'25',
				'50',
				'100'
			];
			return itemsPerPage.map(e=>Number(e));
		},
		headers(){
			const headers=[];
			// To insert column from data
			if(this.dataTable?.headers?.length){
		
				this.dataTable.headers.forEach(e=>{
					if(e.type==='objectHeader'){
						const property=this.items?.[0]?.[e.property];
						if(property){
							const template=this.items?.[0]?.template?.settings?.form;

							Object.keys(property).forEach(key=> {
								let text=key;
								if(template && typeof template.steps === 'object' && !Array.isArray(template.steps) && template.steps !== null && template.steps.fields?.length){

									const field=template.steps.fields.find(f=>f.name===key);
									if(field){
										text=field.title;
									}
								}
								headers.push({...e,value:`${e.property}.${key}`,text});
							});
						}

					}else{
						headers.push(e);
					}
					
				});
			}
			return  headers;
		}
	},
	watch: {
		forceInitialize (val) {
			if (val) {
				this.pagination={
					page:1
				};
				this.initialize();
			}
		}

	},

	async mounted () {
		this.pagination={
			page:1
		};
    
		this.initialize();
		this.currentModule=await this.getCurrentModule();
	},

	methods: {
		getCurrentModule: call('user/getCurrentModule'),
		formatterTextField(header_slot,item){
			let data=header_slot.property?toPathObject(item,header_slot.property):item[header_slot.value];
			data = header_slot?.['custom-text'] ? generateStringFromVariables({title:header_slot['custom-text'],data:item} ): data;
			return this.formatter({value:data,header:header_slot,item});
		},
		handleSourceAction(action){
			this.selected=null;
			this.$emit(action.event&&action.event.action ?action.event.action:'click',action);
		},
		checkAction(object){
			const isValid= this.checkFunctionality(object, this.currentModule);
			return isValid;
		},
		handlePaginationUpdate(event){
			const {item,value}=event;
			if(this.pagination[item] !== value){
				this.$set(this.pagination,item,value);
				this.getItems();
			}
		},
		handleChangeItems(data){
			if(this.isSearch && !this.isPageable){
				this.selected=null;
				this.$emit('search');
				if(this.clickRow && data.length===1){
					this.handleRowClick(data[0]);
				}
			}

		},
		handleRowClick(item){
			this.$emit('clickRow',item);
			this.selected=item;
		},
		itemRowBackground (item) {
			if(item.deleted_at){
				return 'red lighten-3';
			}
			return this.clickRow&&item[this.itemKey]===this.selected?.[this.itemKey] ? 'blue-grey lighten-4' : '';
		},
		async filter (value, params) {
			const query = this.handleFilter(value, params);
			this.query=query;
			this.$set(this.pagination,'page','1');
			this.$emit('search');
			await this.getItems();
			
		},
		filterOnlyCapsText (value, search) {
			return value != null &&
          search != null &&
          typeof value === 'string' && (
				value.toString().toLocaleUpperCase().indexOf(search) !== -1 || value.toString().toLocaleLowerCase().indexOf(search) !== -1);
		},
		async initialize () {
			this.dataTable = tableMapper(this.form);
			this.items = [];
			this.initializeQuery();
			if (this.dataTable?.source) {
				await this.getItems();
			}
		},
		initializeQuery(){
			if(this.dataTable.filters?.length){
				this.dataTable.filters.forEach(element => {
					if(element.defaultValue){
						this.query[element.param]=element.defaultValue;
					}
					
				});
				
			}
		},
		async getItems(){
			this.selected=null;
			const isParams = Object.values(this.query);
			const isExternalParams=Object.values(this.externalQuery);
			const { data, dataMock } = this.dataTable.source;

			const { href, method, service, checkTokenStatus, initialized }=data || {};
			this.items = [];
			this.loading = true;

			if (String(initialized) !== 'false' || isParams.length || isExternalParams.length) {
				if(this.toTop){
					this.toAnchor({id:'topTableAnchor', offset:-230});
				}
				
				this.dataPagination={};
				if (href) {
					let url = href;
					if (this.parentData) {
						url = generateStringFromVariables({
							title: href,
							data: this.parentData
						});
					}
					const {resources, current_page, last_page, total} = await genericRequest({
						url,
						method: method || 'get',
						service,
						params: {...this.query,...this.externalQuery, page:this.pagination.page, per_page:this.perPage},
						checkTokenStatus
					});

					if(resources?.length){
						this.dataPagination={current_page, last_page, total};
				
						this.items = resources.map((item) => ({ ...item, fullName: `${item.firstname} ${item.lastname}`, dependant_of: { ...item.dependant_of, fullName: `${item.dependant_of ? item.dependant_of.firstname : ''} ${item.dependant_of ? item.dependant_of.lastname : ''}` } }));
					}

				}
				if (dataMock) {
					if(isParams.length){
						isParams.forEach(
							param=>{
								this.items = dataMock[param];
							}
						);
					}else{
						this.items = dataMock;
					}
					
				}
			}
			this.loading = false;
			this.$emit('loaded');
			if(this.clickRow && String(this.dataPagination.total)==='1'){
				this.handleRowClick(this.items[0]);
			}
		},
		async handleAction ({ item, header, index, value }) {
			if (header.type === 'color' || this.$refs[`form_${header.value}-${index}`][0].validate()) {
				const _value = value || item[`edit_${header.value}-flag`];
				if (_value && _value !== item[header.value] && header.modifying?.modifiable === 'true' && header.modifying?.saving?.href) {
					try {
						this.loadingItem = true;
						const url = generateStringFromVariables({
							title: header.modifying.saving.href,
							data: {parentData:this.parentData,...item}
						});
						await genericRequest({
							url: url,
							method: header.modifying.saving.method,
							params: { [header.value]: _value },
							service: header.modifying.saving.service,
							checkTokenStatus: header.modifying.saving.checkTokenStatus
						});

						this.$set(item, [header.value], _value);
						this.$set(item, `edit_${header.value}-flag`, _value);
						this.$emit(header.modifying?.value?.method === 'DELETE' ? 'deleted' : 'edited');
					} catch (error) {
						console.log(error);
					} finally {
						this.loadingItem = false;
					}
				}
				this.closeDialog({ item, header, index });
			}
		},
		async handleTableAction ({ item, header, value }) {
			this.selected=item;
			if (value?.confirmation?.message) {
				const txt = generateStringFromVariables({ title: value.confirmation.message || '', data: item });
				this.confirmModal = true;
				this.confirmValue = { value, item };
				this.confirmModalText = txt;
			}else if(value?.confirmation?.form){
				this.actionForm = this.importJson(`${value?.confirmation?.form}.json`);
				if(this.actionForm){
					this.dialogActionForm = true;
				}
			} else if (value.service === 'local') {
				const url = generateStringFromVariables({
					title: value.href,
					data: item
				});

				this.$router.push({ path: url });
			} else if (value.event?.action) {
				this.$emit(value.event.action, { item, header, value });
			} else if (value.submit?.href) {
				try {
					const url = generateStringFromVariables({
						title: value.submit.href,
						data: item
					});
					//const params = paramMapped(value?.submit?.parameters, item, header);
					await genericRequest({
						url,
						method: value.submit.method,
						params:value?.submit?.parameters,
						service: value.submit.service,
						checkTokenStatus: value.submit.checkTokenStatus
					});


				} catch (error) {
					// this.isLoading = false;
					// this.$emit('error');
				} finally {
					// this.isLoading = false;
				}
			}
		},
		closeDialog ({ header, index }) {
			if (!this.$refs[`edit_dialog_${header.value}-${index}`]?.length) return false;
			this.$refs[`edit_dialog_${header.value}-${index}`].forEach(element => {
				if(element){
					element.isActive = false;
				}
			});
		},
		async confirmModalAction (reload= false) {
			if(!this.confirmValue?.value){
				this.confirmModal = false;
				this.confirmModalLoading = false;
				if(reload){
					this.initialize();
				}
				return;
			}
			const {method, href, service, checkTokenStatus} = this.confirmValue.value;

			if (service !== 'local') {
				this.confirmModalLoading = true;
				const url = generateStringFromVariables({
					title: href,
					data: {parentData:this.parentData,...this.confirmValue.item}
				});
				const params = paramMapped(this.confirmValue.value?.parameters,  this.confirmValue.item);
				await genericRequest({
					url,
					method: method,
					service: service,
					params,
					checkTokenStatus: checkTokenStatus
				});
			}
			this.confirmModal = false;
			this.confirmModalLoading = false;
			this.$emit(method === 'DELETE' ? 'deleted' : 'edited');
			if(method === 'DELETE'){
				this.pagination={
					page:1
				};
			}
			this.selected=null;
			this.initialize();
		},
		handleChange ({ item, header, value, index }) {
			const closeModals = Object.keys(this.$refs).filter((data) => data.includes('edit_dialog') && data !== `edit_dialog_${header.value}-${index}`);
			closeModals.forEach(element => {
				if(this.$refs?.[element]?.[0] ){
					this.$refs[element][0].isActive = false;
				}
				
			});
			this.$set(item, `edit_${header.value}-flag`, value);
		},
		
		checkDepends(header,data){
			const {inputsHidden} = checkAndFilterDepends({fields:[header],data:deepClone(data)});
			if(header?.method?.toUpperCase()==='DELETE' && data.deletable===0){
				return false;
			}
			return inputsHidden.length===0;

		},
		findValidValue(data, key ){
			const find=data.find(e=>e.to===null);
			if(!find) return; 
			return key? find.status_type[key]:find.status_type;
		},
		checkListValid(item,key){
			return item[key].map(e=>({...e, disabled:!e.enabled}));
		},
		handleActionDropdownChange(data,{header_slot,item}){
			this.$emit('cancel');
			if(this.$refs[`dropdown_action_${item.uuid}`]?.[0]){
				this.$refs[`dropdown_action_${item.uuid}`][0].setValue('d75d58a1-844b-4104-b444-a61728a3aad0');
			}
			
			const confirmation = header_slot?.confirmation?.message;
			if (confirmation) {
				const txt = generateStringFromVariables({ title: confirmation, data });
				const href = generateStringFromVariables({
					title: header_slot.submit.href,
					data: item
				});
				this.confirmModal = true;
				this.confirmValue = { value:{...header_slot.submit,href}, item:data };
				this.confirmModalText = txt;
			} 
		},
		handleActionDropdownClick(event){
			event.preventDefault();
			event.stopPropagation();
		}
	}
};
</script>

<style lang="scss">
  tbody{
    tr{
      &.v-data-table__mobile-table-row{
        height: unset!important;
      }
    }

  }

</style>
