<template>
  <VRow>
    <VCol
      cols="12"
      :md="firstColumn.cols ||'6'"
    >
      <app-card class="pa-4 mt-2" elevation="2">
        <div v-if="firstColumn.title" class="text-subtitle-1 font-weight-negrita">
          {{ firstColumn.title }}
        </div>
        <component
          :is="firstColumn.type"
          :form="firstColumn"
          validate
          v-bind="toAttributes(firstColumn.attr)"
          @search="secondFormData=false"
          @clickRow="handleClickRow"
        />
      </app-card>
    </VCol>
    <div id="secondColumn" />
    <VCol
      v-if="secondColumn && secondColumn.forms && secondColumn.forms.length"
      cols="12"
      :md="secondColumn.cols ||'6'"
    >
      <app-card class="pa-2  mt-2 p-relative" :color="secondFormData?'white':'transparent'" :elevation="secondFormData?2:0">
        <component
          :is="secondColumn.forms[0].type"
          :loading.sync="loadingSecond"
          :parent-data="secondFormData"
          v-bind="toAttributes(secondColumn.forms[0].attr)"
          :form="secondColumn.forms[0]"
          @cancel="secondInnerFormData=false"
          @modify="secondInnerFormData=false"
          @clickRow="handleClickSecondRow"
        />
        <custom-loading
          id="bottomSecondColumn"
          :loading="loadingSecond"
          absolute
          opacity="0.1"
          size="40"
          class="mt-12"
        />
      </app-card>

      <app-card v-if="secondFormData && secondInnerFormData && secondColumn.forms[1]" class="pa-2 pt-4 mt-4 p-relative" :color="secondInnerFormData?'white':'transparent'" :elevation="secondInnerFormData?2:0">
        <component
          :is="secondColumn.forms[1].type"
          id="secondInnerColumn"
          :loading.sync="loadingThird"
          :parent-data="secondInnerFormData"
          v-bind="toAttributes(secondColumn.forms[1].attr)"
          :form="secondColumn.forms[1]"
          :force-reload.sync="forceReload"
          @showCrud="handleClickSecondInnerRow"
          @editCrud="handleClickSecondInnerRow"
        />
        <custom-loading :loading="loadingThird" absolute opacity="0.1" size="40" class="mt-12" />
      </app-card>
    </VCol>
    <div id="thirdColumn" />
    <VCol
      v-if="secondFormData && secondInnerFormData && thirdFormData && thirdColumn"
      cols="12"
      :md="thirdColumn.cols ||'6'"
    >
      <app-card class="pa-2 pt-4 p-relative" :color="thirdFormData?'white':'transparent'" :elevation="thirdFormData?2:0">
        <component
          :is="thirdFormData.form.type"
          :loading.sync="loadingFourth"
          :data-source="{...thirdFormData.data, pharmacy:secondFormData}"
          v-bind="toAttributes(thirdFormData.form.attr)"
          :form="thirdFormData.form"
          @cancel="thirdFormData=false"
          @submit="forceReload=true"
        />
        <custom-loading :loading="loadingFourth" absolute opacity="0.1" size="40" class="mt-12" />
      </app-card>
    </VCol>
  </VRow>
</template>
  
<script>
import { toAttributes } from '@/util/helpers';
import jsons from '@/mixins/jsons';
import anchors from '@/mixins/anchors';

export default {
	name: 'Layout2Cols',
	components: {
  
	},
	mixins: [ jsons,anchors],
	props: {
		form: {
			type: Object,
			default: function () {
				return { };
			}
		}
	},
  
	data () {
		return {
			toAttributes,
			loading:false,
			loadingSecond:false,
			loadingThird:false,
			loadingFourth:false,
			secondFormData:false,
			secondInnerFormData:false,
			thirdFormData:false,
			forceReload:false
		};
	},
	computed: {
		firstColumn(){
			return this.form.columns[0] || {};
		},
		secondColumn(){
			return this.form.columns[1] || {};
		},
		thirdColumn(){ 
			return this.form.columns[2];
		}
	},
	mounted () {
  
	},

	methods: {
		handleClickRow (data) {
			this.secondFormData=data;
			this.secondInnerFormData=null;
			this.thirdFormData=null;
			this.toAnchor({id:'secondColumn', offset:-130});
		},
		handleClickSecondRow(data){
			this.secondInnerFormData=data;
			this.thirdFormData=null;
			this.toAnchor({id:'bottomSecondColumn', offset:0});
		},
		handleClickSecondInnerRow(data){
			this.toAnchor({id:'thirdColumn', offset:-230});
			this.thirdFormData=null;
			let form=data?.value?.event?.form || data?.event?.form;

			if(form){
				form = this.importJson(`${form}.json`);
				this.thirdFormData={form:form.steps.length?form.steps[0]:form,data:data.item || this.secondInnerFormData};
			}
			
		}
	}
};
</script>  