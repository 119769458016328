//TODO: sortable only if pageable is false. Check endpoints to sorting

export const mapperColumns = (data,fullForm) => ({
	...data,
	text: data.labeling?.label,
	align: data.labeling?.style?.align,
	width: data.labeling?.style?.width,
	sortable: String(data.sorting?.sortable) === 'true' && String(fullForm.paging?.pageable) !== 'true',
	value: data.name,
	property: data.property,
	modifying: data.modifying,
	type: data.type,
	cellClass: data.class,
	required: String(data.required) === 'true',
	style: data.style
});

export const mapperActions = (items, actionTitle,cellClass) => {
	let response = false;

	if (items?.length) {
		let width=items.length > 3 ? '170px' : '140px';

		const isDrowpdown=items.find(item=>item.type==='dropdown');
		if(isDrowpdown){
			width='200px';
		}
		response = {
			cellClass,
			text: actionTitle || 'Acciones',
			align: 'start',
			sortable: false,
			value: 'actions',
			data: items,
			modifying: false,
			width
		};
	}
	return response;
};
