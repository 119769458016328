
export default (data = { }) => {
	const items = [
		{
			text: data.benefit_type.name,
			fontSize: 12.5,
			bold: true,
			color: '#000000',
			margin: [0, 10, 0, 10]
		}
	];

	return items;
};
