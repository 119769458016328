<template>
  <VTextField
    v-model="search"
    append-icon="mdi-magnify"
    clearable
    color="secondary"
    dense
    enclosed
    hide-details
    :placeholder="placeholder"
    single-line
    :disabled="disabled"
    :loading="loading"
    @click:append="handleClick"
    @click:clear="handleClick('clear')"
    @keyup.enter="handleClick"
  />
</template>

<script>
export default {
	name: 'Search',
	props: {
		placeholder: {
			type: [String],
			default: 'Buscar',
		},
		disabled: {
			type: [Boolean],
			default: false
		},
		loading: {
			type: [Boolean],
			default: false
		}
	},
	data () {
		return {
			search: null
		};
	},
	methods: {

		handleClick (action) {
			if (action === 'clear') {
				this.search = null;
			}
			this.$emit('click', this.search);
		}
	}
};
</script>

<style></style>
