import { textLong } from '@/util/helpers';

export default (data = { }) => {
	const items = [
		{
			text: textLong(data.created_at),
			fontSize: 11,
			bold: false,
			color: '#000000',
			margin: [0, 15, 0, 5],
			alignment: 'right'
		}
	];

	return items;
};
