<template>
  <div @click="clickOutside">
    <VRow justify="center">
      <custom-loading :loading="loading" />
      <template v-if="loaded">
        <VCol
          v-for="(area, key) in areas"
          :key="`area-${key}`"
          cols="11"
        >
          <span class="text-subtitle-1">{{ area.name }}</span>

          <VRow
            justify="center"
            class="py-5"
          >
            <template v-if="area.modules && area.modules.length">
              <VCol
                v-for="(moduleArea, keyModule) in area.modules"
                :key="`module-${key}-${keyModule}`"
                cols="11"
              >
                <span class="text-subtitle-2">{{ moduleArea.name }}</span>

                <VRow
                  justify="center"
                  class="my-3"
                >
                  <VCol
                    v-if="
                      moduleArea.functionalities &&
                        moduleArea.functionalities.length
                    "
                    cols="11"
                    xl="10"
                  >
                    <VForm
                      :ref="`functionalities-${area.uuid}-${moduleArea.url}`"
                      class=""
                      :disabled="loading"
                    >
                      <VRow
                        class=""
                        justify="start"
                      >
                        <VCol
                          v-for="(
                            moduleAreaFunctionalities,
                            keyModuleAreaFunctionalities
                          ) in moduleArea.functionalities"
                          :key="`module-${key}-${keyModule}-${keyModuleAreaFunctionalities}`"
                          cols="6"
                          md="3"
                        >
                          <v-switch
                            v-model="
                              functionalities[
                                `${moduleAreaFunctionalities.uuid}`
                              ]
                            "
                            class="mt-0"
                            :true-value="moduleAreaFunctionalities.uuid"
                            :label="moduleAreaFunctionalities.name"
                            :color="meta.color"
                            @change="
                              handleFunctionalitiesChange(
                                $event,
                                moduleAreaFunctionalities
                              )
                            "
                          />
                        </VCol>
                      </VRow>
                    </VForm>
                  </VCol>
                  <VCol
                    cols="11"
                    xl="10"
                  >
                    <div class="text-subtitle-2 pb-4">
                      Servicios
                    </div>
                    <VForm
                      :ref="`services${moduleArea.name}${key}`"
                      class=""
                      lazy-validation
                      :disabled="loading"
                    >
                      <VRow
                        class=""
                        justify="start"
                      >
                        <VCol
                          cols="12"
                          md="5"
                          lg="4"
                        >
                          <v-select
                            v-model="moduleArea.serviceSelected"
                            :items="moduleArea.services"
                            label="Servicio"
                            item-text="name"
                            return-object
                            no-data-text="Sin datos"
                            dense
                            outlined
                            clearable
                            :rules="textRules"
                            :loading="moduleArea.loading"
                            :disabled="moduleArea.loading"
                            @click="$event.stopPropagation()"
                            @change="
                              getScopes(
                                moduleArea,
                                $event,
                                `services${moduleArea.name}${key}`
                              )
                            "
                          >
                            <template
                              #selection="data"
                            >
                              {{ data.item.name }}
                              <v-icon
                                v-if="data.item.disabled"
                                color="green"
                                small
                                class="ml-2"
                              >
                                mdi-check
                              </v-icon>
                            </template>

                            <template
                              #item="data"
                            >
                              {{ data.item.name }}
                              <v-icon
                                v-if="data.item.disabled"
                                color="green"
                                small
                                class="ml-2"
                              >
                                mdi-check
                              </v-icon>
                            </template>
                          </v-select>
                        </VCol>
                        <VCol
                          cols="12"
                          md="5"
                          lg="4"
                        >
                          <v-select
                            v-model="moduleArea.scopeSelected"
                            :items="
                              moduleArea.scopesList &&
                                moduleArea.scopesList.length
                                ? moduleArea.scopesList
                                : []
                            "
                            label="Rol"
                            item-text="description"
                            no-data-text="Sin datos"
                            dense
                            return-object
                            clearable
                            outlined
                            :loading="moduleArea.loading"
                            :disabled="moduleArea.loading"
                            :rules="textRules"
                            @click="$event.stopPropagation()"
                          >
                            <template
                              #selection="data"
                            >
                              {{ data.item.description }}
                              <v-icon
                                v-if="data.item.disabled"
                                color="green"
                                small
                                class="ml-2"
                              >
                                mdi-check
                              </v-icon>
                            </template>

                            <template
                              #item="data"
                            >
                              {{ data.item.description }}
                              <v-icon
                                v-if="data.item.disabled"
                                color="green"
                                small
                                class="ml-2"
                              >
                                mdi-check
                              </v-icon>
                            </template>
                          </v-select>
                        </VCol>
                        <VCol
                          cols="12"
                          md="2"
                          lg="4"
                          class="d-flex justify-end align-center d-md-block"
                        >
                          <v-btn
                            icon
                            @click="
                              handleClickAdd(
                                moduleArea,
                                $event,
                                `services${moduleArea.name}${key}`
                              )
                            "
                          >
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                        </VCol>
                        <VCol
                          cols="12"
                          md="10"
                          lg="8"
                          class="pt-0"
                        >
                          <v-data-table
                            no-data-text="No se encontraron resultados"
                            no-results-text="No se encontraron resultados"
                            dense
                            :headers="[
                              { text: 'Servicio', value: 'service.name' },
                              { text: 'Rol', value: 'scopes' },
                              {
                                text: '',
                                value: 'actions',
                                cellClass: 'text-end',
                              },
                            ]"
                            :items="Object.values(moduleArea.accesses)"
                            item-key="key"
                            hide-default-footer
                            class="elevation-4 row-height-dense"
                            loading-text="Cargando"
                            :loading="moduleArea.loading"
                          >
                            <template #[`item.actions`]="{ item }">
                              <v-btn
                                class="
                                  text-none
                                  font-weight-regular
                                  text-caption
                                "
                                small
                                text
                                @click="handleDel(item)"
                              >
                                <v-icon> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </VCol>
                      </VRow>
                    </VForm>
                  </VCol>
                </VRow>
              </VCol>
            </template>
            <VCol
              v-else
              cols="11"
            >
              No existen módulos para el área
            </VCol>
          </VRow>
        </VCol>
      </template>
    </VRow>
    <VRow
      v-if="actions && actions.length"
      class="custom-footer"
      justify="center"
    >
      <VCol class="d-flex align-center pb-6 pt-12" cols="11">
        <VRow justify="space-between" style="width: 100%">
          <VCol
            v-for="(action, index) in actions"
            :key="`action-${index}`"
            cols="12"
            :md="action.col ? action.col : 'auto'"
            class="pb-1"
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              custom-class="text-none"
              :loading="loading || !loaded"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="handleClick(action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <confirm-modal
      :open.sync="confirmModal"
      :text="confirmModalText"
      :is-overlay="confirmModalLoading"
      @confirm="confirmModalAction"
    />
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { genericRequest } from '@/api/modules';
import { isError } from '@/api/errors';
import { toAttributes } from '@/mappers/form';

export default {
	name: 'FunctionalityGroup',
	props: {
		form: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	data () {
		return {
			toAttributes,
			loaded: false,
			loading: true,
			functionalities: {},
			areas: [],
			services: [],
			group: {},
			confirmModal: false,
			confirmModalLoading: false,
			confirmModalText: '',
			itemToDel: null,
			textRules: [(v) => !!v || 'Campo requerido']
		};
	},
	computed: {
		meta: get('route/meta'),
		params: get('route/params'),
		customTitle: sync('app/customTitle'),
		actions(){
			return this.form?.steps?.[0]?.actions || this.form.actions;
		},
		id () {
			return this.params?.id;
		},
		urlFunctionalities () {
			return `groups/${this.id}/functionalities`;
		}
	},
	mounted () {
		this.getData();
	},
	methods: {
		clickOutside () {
			Object.keys(this.$refs).forEach((val) => {
				if (this?.$refs?.[val]?.[0]?.resetValidation) {
					this.$refs[val][0].resetValidation();
				}
			});
		},
		async getData () {
			// await this.getAccesses()
			return Promise.all([this.getFunctionalities(), this.getGroup()]).then(
				() => {
					this.loading = false;
					this.loaded = true;
				}
			);
		},
		async getGroup () {
			try {
				const { resource } = await genericRequest({
					url: `/groups/${this.id}`,
					method: 'get'
				});
				if (resource) {
					this.group = resource;
					this.customTitle = resource.name;
					this.fillFunctionalities(resource.functionalities);
				}
			} catch {
				console.log('error');
			}
		},

		async getFunctionalities () {
			try {
				const { resources } = await genericRequest({
					url: `/functionalities/${this.id}`,
					method: 'get'
				});
				if (resources?.length) {
					resources.forEach(area => {
						const modules = area.modules;

						if (modules?.length) {
							modules.forEach(module => {
								const services = module.services;
								if (services?.length) {
									services.forEach(service => {
										const accesses = Object.values(module.accesses);
										const isServiceAdd = accesses.find(find => find.module_service_uuid === service.module_service_uuid);
										service.disabled = !!isServiceAdd;
									});
								}
							});
						}
					});
					this.areas = resources;
				}
			} catch {
				console.log('error');
			}
		},

		async getScopes (item, servicio, form) {
			this.$set(item, 'loading', true);
			this.$set(item, 'scopesList', []);
			const accessesModule = Object.values(item.accesses);

			if (servicio?.url) {
				try {
					const data = await genericRequest({
						url: `${servicio.url}/oauth/scopes`,
						method: 'get'
					});
					if (data?.length) {
						const dataMapped = data.map((scope) => {
							return {
								...scope,
								disabled: !!accessesModule.find((accesse) => {
									return (
										accesse?.module_service_uuid ===
                      servicio.module_service_uuid && accesse.scopes === scope.description
									);
								})
							};
						});

						this.$set(item, 'scopesList', dataMapped);
					}
				} catch {
					console.log('error');
				} finally {
					this.$set(item, 'loading', false);
				}
			}
			this.$set(item, 'loading', false);
			this.$refs[form][0].resetValidation();
		},

		fillFunctionalities (functionalities) {
			this.functionalities = {};
			const functionalitiesMapped = {};
			if (functionalities) {
				Object.values(functionalities).forEach((functionality) => {
					if (functionality?.uuid) {
						functionalitiesMapped[functionality.uuid] = functionality.uuid;
					}
				});
			}
			this.functionalities = functionalitiesMapped;
		},

		handleDel (item) {
			this.confirmModalText = `Confirma la eliminación del servicio <b>${item.service.name}</b> del módulo <b>${item.module.name}</b> para el rol <b>${item.scopes}</b>`;
			this.confirmModal = true;
			this.itemToDel = item;
		},
		async confirmModalAction () {
			this.confirmModalLoading = true;
			const response = await genericRequest({
				url: `/groups/${this.id}/accesses/${this.itemToDel.uuid}`,
				method: 'delete'
			});
			if (!isError(response.response?.status)) {
				await this.getData();
				this.clickOutside();
			}
			this.confirmModalLoading = false;
			this.itemToDel = null;
			this.confirmModal = false;
		},

		async handleClickAdd (item, event, form) {
			event.stopPropagation();

			if (this.$refs?.[form][0].validate()) {
				item.loading = true;

				const params = {
					scopes: item.scopeSelected.id,
					module_service_uuid: item.serviceSelected.module_service_uuid
				};

				const response = await genericRequest({
					url: `/groups/${this.id}/accesses`,
					method: 'post',
					params
				});

				if (!isError(response.response?.status)) {
					this.$refs[form][0].reset();

					await this.getData();
					this.clickOutside();
				}
				item.loading = false;
			}
		},
		async handleFunctionalitiesChange (value, data) {
			this.loading = true;
			const params = { functionality_uuid: data.uuid };
			const url = value
				? this.urlFunctionalities
				: `${this.urlFunctionalities}/${data.uuid}`;
			await genericRequest({
				url,
				method: value ? 'post' : 'delete',
				params
			});
			this.loading = false;
		}
	}
};
</script>

<style>
.v-data-table.row-height-dense td {
  height: 30px !important;
}
</style>
