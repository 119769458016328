<template>
  <VDialog
    v-model="modal"
    content-class="modal"
    :persistent="isOverlay"
    width="700"
  >
    <VCard class="overflow-hidden transition-fast pa-3">
      <VCardTitle class="darkBg--text text-h6 transition-fast">
        Confirmar
      </VCardTitle>
      <VContainer fluid>
        <VRow
          class="w-100"
          justify="center"
        >
          <VCol cols="11">
            <span v-html="text" />
          </VCol>
        </VRow>
        <VCardActions class="pt-6">
          <VSpacer />
          <VBtn
            class="
              px-4
              darkTxt--text
              transition-fast
              font-weight-negrita
              text-subtitle-1
              text-none
            "
            color="darkTxt--text"
            :loading="isOverlay"
            text
            @click="$emit('update:open', false)"
          >
            Cancelar
          </VBtn>
          <VBtn
            class="
              px-4
              ml-md-7
              font-weight-medium font-weight-negrita
              text-subtitle-1
              text-none
            "
            color="success"
            :loading="isOverlay"
            @click="$emit('confirm')"
          >
            Confirmar
          </VBtn>
        </VCardActions>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>

export default {
	name: 'ConfirmModal',

	components: {},
	props: {
		text: {
			type: String,
			default: ''
		},
		open: {
			type: Boolean,
			default: false
		},
		isOverlay: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return { modal: false };
	},
	computed: {

	},
	watch: {
		open: function (val) {
			if (val) {
				this.modal = true;
			} else {
				this.modal = false;
			}
		},
		modal: function (val) {
			if (!val) {
				this.$emit('update:open', false);
			}
		}
	},

	async mounted () {},

	methods: {

	}
};
</script>

<style></style>
