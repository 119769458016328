<template>
  <VRow align="center" justify="start">
    <VCol cols="12" class="">
      <VForm ref="prestamoForm" class="pt-4">
        <VRow>
          <!-- -->
          <VCol cols="12" class="">
            <v-select
              v-model="localForm.type"
              :items="destinations"
              item-text="name"
              item-value="uuid"
              label="Destino turístico"
              outlined
              hide-details="auto"
              dense
              :rules="[rules('required')]"
              return-object
            >
              <template
                #item="data"
              >
                {{ data.item.name }} {{ data.item.description }}
              </template>
            </v-select>
          </VCol>
          <template v-if="localForm.type">
            <VCol cols="6" class="">
              <custom-input
                :model="localForm.type"
                :field="{
                  name:'from',
                  labeling:{
                    label:'Inicio'
                  },
                  title:'Inicio',
                  type:'date',
                  required:true
                }"
                @input="changeDate('from',$event)"
              />
            </VCol>
                
            <VCol cols="6" class="">
              <custom-input
                :model="localForm.type"
                :field="{
                  name:'fin',
                  labeling:{
                    label:'Fin'
                  },
                  title:'Fin',
                  type:'date',
                  required:true, 
                  style:{
                    attributes:['min:from']
                  }}"
                @input="changeDate('fin',$event)"
              />
            </VCol>
            <VCol cols="12" class="">
              <v-switch
                v-model="localForm.type.acompanante"
                class=""
                hide-details="auto"
                label="Viaja con acompañantes"
              />
            </VCol>

            <template v-if="localForm.type.acompanante">
              <VCol cols="12" class="py-0">
                <v-switch
                  v-model="localForm.type.viajaAfiliado"
                  class=""
                  hide-details="auto"
                  label="Viaja el titular"
                />
              </VCol>
              <VCol cols="12" class="d-flex justify-end aling-center pt-0">
                <custom-btn
                  :attributes="{
                    'outlined':true,
                    'elevation':5,
                    'icon':true,
                    'color':'primary',
                    'prepend-icon':'plus'
                  }
                  "
                  tooltip="Agregar acompañante"
                  icon="plus"
                  @handleClick="showAddModal"
                />
              </VCol>
              <VCol cols="12" class="">
                <v-autocomplete
                  v-model="localForm.type.acompanantes"
                  :items="dependants"
                  item-text="name"
                  item-value="uuid"
                  label="Acompañantes"
                  outlined
                  small-chips
                  hide-details="auto"
                  dense
                  multiple
                  :rules="[rules('array')]"
                  no-data-text="Seleccione acompañantes"
                  return-object
                >
                  <template
                    #selection="data"
                  >
                    <v-chip
                      v-if="data.item"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      small
                      class="mt-2"
                      @click="data.select"
                      @click:close="remove('acompanantes',data.item)"
                    >
                      {{ data.item.name }}  {{ data.item.lastname }}
                    </v-chip>
                  </template>

                  <template
                    #item="data"
                  >
                    <div v-if="data.item">
                      {{ data.item.name }} {{ data.item.lastname }}
                    </div>
                  </template>
                </v-autocomplete>
              </VCol>
            </template>
            <VCol v-if="localForm.type.admin || localForm.type.carlosPaz" cols="12" class="">
              <v-autocomplete
                v-model="localForm.type.rooms"
                :items="rooms"
                item-text="name"
                item-value="uuid"
                label="Tipo de habitaciones"
                multiple
                outlined
                small-chips
                hide-details="auto"
                dense
                :rules="[rules('array')]"
                no-data-text="Seleccione habitaciones"
                return-object
              >
                <template
                  #selection="data"
                >
                  <v-chip
                    v-if="data.item"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    small
                    class="mt-2"
                    @click="data.select"
                    @click:close="remove('rooms',data.item)"
                  >
                    {{ data.item.name }} - (Disponible: {{ data.item.quantity }})
                  </v-chip>
                </template>

                <template
                  #item="data"
                >
                  <div v-if="data.item">
                    {{ data.item.name }} - (Disponible: {{ data.item.quantity }})
                  </div>
                </template>
              </v-autocomplete>
            </VCol>
            <VCol v-if="localForm.type.admin &&(localForm.type.viajaAfiliado || (localForm.type.acompanantes && localForm.type.acompanantes.length)) && localForm.type.rooms && localForm.type.rooms.length">
              <CustomRoomSelector :data="localForm.type" />
            </VCol>
            <VCol v-if="localForm.type.carlosPaz &&(localForm.type.viajaAfiliado || (localForm.type.acompanantes && localForm.type.acompanantes.length)) && localForm.type.rooms && localForm.type.rooms.length" cols="12">
              <CustomRoomPersonSelector :data="localForm.type" :dependants="dependantsAddList" :parent-data="parentData" />
            </VCol>
            <VCol cols="12" class="">
              <v-switch
                v-model="localForm.type.cargo"
                class=""
                hide-details="auto"
                label="Con cargo"
              />
            </VCol>
          </template>
        </VRow>
        <VRow
          v-if="
            form && form.actions && form.actions.length
          "
          class="custom-footer"
          justify="center"
        >
          <VCol class="d-flex align-center pb-6 pt-12" cols="12">
            <VRow justify="space-between" style="width: 100%">
              <VCol
                v-for="(action, index) in form.actions"
                :key="`action-${index}`"
                cols="12"
                md="auto"
                class="pb-1"
              >
                <custom-btn
                  ref="button"
                  :attributes="toAttributes(action.style)"
                  :custom-class="`text-none`"
                  :label="action.label"
                  :tooltip="action.title"
                  @handleClick="handleClick(action)"
                />
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VForm>
    </VCol>
    <form-modal
      :open.sync="addModal"
      :form="formAgregarAcompanante"
      persistent
      max-width="700px"
      @confirm="handleAddSubmit"
    />
  </VRow>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import formatter from '@/mixins/formatter';
import jsons from '@/mixins/jsons';
import rules from '@/util/rules';
import { pdfController } from '@/pdf';
import { wait } from '@/util/helpers';
//import {simuladorCuotas} from '@/util/prestamos';
import { toAttributes } from '@/mappers/form';

export default {
	name: 'RegistroReservasForm',
	mixins: [formatter, jsons],
	props: {
		form: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		},
		parentData: {
			type: [Boolean, Object],
			default: function () {
				return false;
			},
		}
	},
	data() {
		return {
			toAttributes,
			rules,
			localForm:{},
			addModal:false,
			formAgregarAcompanante:null,
			destinations:[
				{name:'Carlos Paz',uuid:1,carlosPaz:true,viajaAfiliado:true,cargo:true},
				{name:'Misiones',uuid:2,admin:true,viajaAfiliado:true, description:'(Administración de habitaciones)',cargo:true},
				{name:'Mendoza',from:'2023-09-21',fin:'2023-10-11',uuid:3,viajaAfiliado:true,cargo:true},
				{name:'Mar del Plata',from:'2023-09-21',fin:'2023-10-11',uuid:4,viajaAfiliado:true,cargo:true}
			],
			// eslint-disable-next-line vue/no-reserved-keys
			dependantsList:[
				{name:'Dependiente', lastname:'Uno', uuid:1,acompanantes:[],rooms:[]},
				{name:'Dependiente', lastname:'Dos', uuid:2,acompanantes:[],rooms:[]},
				{name:'Dependiente', lastname:'Tres', uuid:3,acompanantes:[],rooms:[]},
				{name:'Dependiente', lastname:'Cuatro', uuid:4,acompanantes:[],rooms:[]}
			],
			rooms:[				
				{name:'Habitación simple', uuid:1, quantity:6, persons:1},
				{name:'Habitación doble', uuid:4, quantity:3, persons:2},
				{name:'Habitación triple', uuid:5, quantity:2, persons:3}
			],
			addDependants:[]
		};
	},
	computed: {
		meta: get('route/meta'),
		alertTxt: sync('app/alertTxt'),
		appLoading: sync('app/loading'),
		route: get('route'),
		alert: sync('app/alert'),
		dependants(){
			return [...this.dependantsList,...this.addDependants];
		},
		dependantsAddList(){
			const acompantes=this.localForm?.type?.acompanantes || [];

			const titular = this.localForm?.type?.viajaAfiliado?[this.parentData] : [];
			return [...acompantes,...this.addDependants,...titular];
		}
	},
	watch: {
		'localForm.type.acompanante'(value) {
			if(!value){
				this.$set(this.localForm.type,'viajaAfiliado',true);
        
			}

		},
    
	},
	mounted() {

		
	},
	methods: {
		changeDate(item,date){
			this.$set(this.localForm.type,item,date);
		},
		remove (items,item) {
			const index = this.localForm.type[items].findIndex(e=>e.uuid===item.uuid);
			// eslint-disable-next-line vue/no-mutating-props
			if (index >= 0) this.localForm.type[items].splice(index, 1);
		},

		handleClick(action){
			this.$emit(action.event.action);
			if(action.event.action==='save'&& this.$refs?.prestamoForm?.validate()){
				this.handleSave(action);
			}
			if(action.event.action==='cancel'){
				this.handleCancel();
			}
		},
		upgradeLimitShowHandle(){
			console.log('show');
		},
		handleAddSubmit({data}){
			const uuid=this.dependants.length+1;

			this.addDependants.push({...data,uuid});

			// if(!this.form.type.acompanantes?.length){
			// 	this.form.type.acompanantes=[];
			// }
			// this.form.type.acompanantes.push(uuid);
		},
		handleSave(data){
			this.appLoading=true;
			this.handleSubmit(data);
		},
		handleCancel(){
			this.appLoading=true;
			this.init();
			if(this.route?.query?.from){
				this.$router.replace({ path: `/turismo/${this.route.query.from}` });
			}
			this.appLoading=false;
	
		},
		checkCuotas(){
			// if(this.form.quantity && this.form.amount){
			// 	const quantity=Number(this.form.quantity);
			// 	const amount=Number(this.form.amount);

			// 	this.$emit('changeAmount', simuladorCuotas({amount,quantity}));
			// }else{
			// 	this.$emit('changeAmount', false);
			// }

		},
		showAddModal(){
			const form=this.importJson(
				'turismo/turismo/agregar-acompanante.json'
			);
			this.formAgregarAcompanante = form;
			this.addModal=true;
		},


		init() {
			if(this.$refs.prestamoForm){
				this.$refs.prestamoForm.resetValidation();
			}
			this.addDependants=[];
			this.localForm={};
		},


		async handleSubmit() {
			if(this.$refs?.prestamoForm?.validate()){

				const title = 'Reserva de Turismo';
				await pdfController.dowloadTurismo(
					{
						title,
						fileName:'Reserva'
					},
					'download'
				);

				if(this.localForm.type.cargo){
					await pdfController.dowloadTurismo(
						{
							title:'Datos transferencia',
							fileName:'Transferencia'
						},
						'download'
					);
				}

				this.alertTxt = {
					timeout: 3700,
					type: 'success',
					txt: 'Guardado con éxito',
				};
				this.alert = true;
				await wait(1500);

				this.init();
				if(this.route?.query?.from){
					this.$router.replace({ path: `/turismo/${this.route.query.from}` });
				}
				
				this.appLoading=false;
			}
		},

	},
};
</script>

<style>

</style>
