<template>
  <div>
    <custom-loading
      :loading="loading"
      z-index="16"
    />
    <form-modal
      :open.sync="addLock"
      :form="formAdd"
      persistent
      :data-parent-model="data"
      @confirm="confirmAddLock"
    />
    <form-modal
      :open.sync="editLockModal"
      :form="formEdit"
      persistent
      :data-source="currentEdit"
      :data-parent-model="data"
      @confirm="confirmAddLock"
      @cancel="currentEdit={}"
    />
    <form-modal
      :open.sync="dialogBrowse"
      :form="formHistory"
      persistent
      max-width="800px"
      :items="data.locks"
      :data-parent-model="data"
    />
    <confirm-modal
      :open.sync="confirmDel"
      :is-overlay="loading"
      text="Confirma eliminar el bloqueo"
      @confirm="handleDelConfirm"
    />
    <v-navigation-drawer
      id="drawer-blocks"
      v-model="drawerBlocks"
      fixed
      right
      app
      temporary
      :permanent="addLock || dialogBrowse || confirmDel || editLockModal"
      width="400px"
    >
      <v-sheet
        width="100%"
        height="100%"
        class="px-4 py-2"
      >
        <VRow>
          <VCol
            cols="12"
            class="text-subtitle-1"
          >
            Prestaciones bloqueadas
          </VCol>
        </VRow>
        <VRow
          align="end"
          justify="start"
        >
          <VCol>
            <VRow>
              <VCol
                v-if="activeLocks.length===0"
                cols="12"
              >
                No se encontraron bloqueos activos
              <!-- Bloqueo activo -->
              </VCol>

              <VCol
                v-for="(lock,keyLock) in activeLocks"
                v-else
                :key="`${keyLock}`"
                cols="12"
                class="pl-2"
              >
                <div class="text-subtitle-2 font-weight-negrita d-flex justify-start align-center">
                  <v-btn
                    v-if="!readonly"
                    icon
                    class="pa-0"
                    small
                    @click="delLock(lock)"
                  >
                    <v-icon
                      color="red"
                      dense
                      small
                    >
                      mdi-close-circle
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!readonly"
                    icon
                    class="pa-0"
                    small
                    @click="editLock(lock)"
                  >
                    <v-icon
                      color="black"
                      dense
                      small
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <span class="mx-1">
                    Desde: {{ lock.from | shortDate }}
                  </span>
                  <span v-if="lock.to">
                    - Hasta: {{ lock.to | shortDate }}
                  </span>
                </div>
                <div class="text-subtitle-2 font-weight-negrita pl-9">
                  Motivo:
                </div>
                <div class="text-subtitle-2 font-weight-regular pl-9">
                  {{ lock.reason }}
                </div>
                <div class="text-subtitle-2 font-weight-negrita pl-9">
                  Prestaciones:
                </div>
                <VRow class="pt-3 pl-8">
                  <VCol
                    v-for="(benefitLock,keyBenefitLock) in lock.lock_benefits"
                    :key="`${keyLock}_${keyBenefitLock}`"
                    cols="12"
                    class="py-0 font-weight-regular"
                  >
                    <v-icon
                      color="blue-grey darken-3"
                      x-small
                    >
                      mdi-circle
                    </v-icon>
                    {{ benefitLock.benefit_type.name }}
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
          </VCol>
          <VCol
            cols="12"
            class="d-flex justify-center"
          >
            <custom-btn
              :attributes="{icon:true,'small':true,'prepend-icon':'history'}"
              tooltip="Ver histórico"
              label="Ver histórico"
              @handleClick="dialogBrowse=true"
            />
          </VCol>
        </VRow>
      </v-sheet>
      <template
        v-if="!readonly"
        #append
      >
        <VForm class="px-2 py-4">
          <VRow>
            <VCol
              cols="12"
              class="pt-0"
            >
              <v-btn
                block
                small
                dark
                @click="handleAddLocks"
              >
                Modificar bloqueos
              </v-btn>
            </VCol>
          </VRow>
        </VForm>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import tableMapper from '@/mappers/tables';
import { shortDate } from '@/util/helpers';
import { sync } from 'vuex-pathify';
import { genericRequest } from '@/api/modules';

export default {
	name: 'PersonBlocks',
	components: {},
	filters: { shortDate },
	props: {
		data: {
			type: Object,
			default: function () {
				return {};
			}
		},
		widget: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	data: () => ({
		currentEdit: {},
		editLockModal: false,
		formEdit: require('@/jsons/afiliaciones/locks/edit.json'),
		formAdd: require('@/jsons/afiliaciones/locks/add.json'),
		formHistory: tableMapper(require('@/jsons/afiliaciones/locks/history.json')),
		dialogBrowse: false,
		addLock: false,
		confirmDel: false,
		lockDel: null,
		loading: false
	}),
	computed: {
		...sync('app', ['drawerBlocks']),
		readonly () {
			return String(this.widget?.readonly) === 'true';
		},
		activeLocks () {
			if (this.data?.locks) {
				return this.data.locks.filter(element => !element.deleted_at && element.active === 1);
			}
			return [];
		}
	},
	mounted () {
	},
	methods: {
		handleAddLocks (event) {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}
			this.addLock = true;
		},
		confirmAddLock (data) {
			this.$emit('reload-form', { ...data, field: { name: 'locks' } });
		},
		showHistoryEvent () {
			this.items = this.value;
		},
		delLock (lock) {
			this.confirmDel = true;
			this.lockDel = lock;
		},
		editLock (lock) {
			this.currentEdit = { ...lock, person_uuid: this.data.uuid };
			this.editLockModal = true;
		},
		async handleDelConfirm () {
			this.loading = true;
			const id = this.lockDel.uuid;

			const response = await genericRequest({
				url: `/persons/${this.data.uuid}/locks/${id}`,
				method: 'DELETE',
				service: 'afiliaciones'
			});

			this.$emit('reload-form', { response, field: { name: 'locks' } });
			this.loading = false;
			this.confirmDel = false;
			this.lockDel = null;
		}
	}
};
</script>

  <style>
    #drawer-blocks{
      z-index:15;
    }
  </style>
