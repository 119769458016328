var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"justify":"start"}},[_vm._l((_vm.data.rooms),function(type){return [_c('v-col',{key:`${type.uuid}_quantity`,staticClass:"pb-0",attrs:{"cols":"7"}},[_c('custom-input',{attrs:{"model":type,"field":{
          name:'quantitySelected',
          labeling:{
            label:'Cantidad'
          },
          title:'Cantidad',
          type:'number',
          required:true, 
          style:{
            attributes:[`prefix:${type.name} -    `,'hide-datails:auto']
          },
          customValidations:
        
            [
              {
                validation:'lessThan',
                fields:[
                  'quantity'
                ],
                message:`La ${type.name} cuenta con ${type.quantity} unidades disponibles`
              }
            ]
        },"value":0,"validate":"","tooltip":'Cantidad'}})],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }