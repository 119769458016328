var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"click":_vm.clickOutside}},[_c('VRow',{attrs:{"justify":"center"}},[_c('custom-loading',{attrs:{"loading":_vm.loading}}),(_vm.loaded)?_vm._l((_vm.areas),function(area,key){return _c('VCol',{key:`area-${key}`,attrs:{"cols":"11"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(area.name))]),_c('VRow',{staticClass:"py-5",attrs:{"justify":"center"}},[(area.modules && area.modules.length)?_vm._l((area.modules),function(moduleArea,keyModule){return _c('VCol',{key:`module-${key}-${keyModule}`,attrs:{"cols":"11"}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(moduleArea.name))]),_c('VRow',{staticClass:"my-3",attrs:{"justify":"center"}},[(
                    moduleArea.functionalities &&
                      moduleArea.functionalities.length
                  )?_c('VCol',{attrs:{"cols":"11","xl":"10"}},[_c('VForm',{ref:`functionalities-${area.uuid}-${moduleArea.url}`,refInFor:true,attrs:{"disabled":_vm.loading}},[_c('VRow',{attrs:{"justify":"start"}},_vm._l((moduleArea.functionalities),function(moduleAreaFunctionalities,keyModuleAreaFunctionalities){return _c('VCol',{key:`module-${key}-${keyModule}-${keyModuleAreaFunctionalities}`,attrs:{"cols":"6","md":"3"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"true-value":moduleAreaFunctionalities.uuid,"label":moduleAreaFunctionalities.name,"color":_vm.meta.color},on:{"change":function($event){return _vm.handleFunctionalitiesChange(
                              $event,
                              moduleAreaFunctionalities
                            )}},model:{value:(
                            _vm.functionalities[
                              `${moduleAreaFunctionalities.uuid}`
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.functionalities, 
                              `${moduleAreaFunctionalities.uuid}`
                            , $$v)},expression:"\n                            functionalities[\n                              `${moduleAreaFunctionalities.uuid}`\n                            ]\n                          "}})],1)}),1)],1)],1):_vm._e(),_c('VCol',{attrs:{"cols":"11","xl":"10"}},[_c('div',{staticClass:"text-subtitle-2 pb-4"},[_vm._v(" Servicios ")]),_c('VForm',{ref:`services${moduleArea.name}${key}`,refInFor:true,attrs:{"lazy-validation":"","disabled":_vm.loading}},[_c('VRow',{attrs:{"justify":"start"}},[_c('VCol',{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('v-select',{attrs:{"items":moduleArea.services,"label":"Servicio","item-text":"name","return-object":"","no-data-text":"Sin datos","dense":"","outlined":"","clearable":"","rules":_vm.textRules,"loading":moduleArea.loading,"disabled":moduleArea.loading},on:{"click":function($event){return $event.stopPropagation()},"change":function($event){return _vm.getScopes(
                              moduleArea,
                              $event,
                              `services${moduleArea.name}${key}`
                            )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "),(data.item.disabled)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"green","small":""}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "),(data.item.disabled)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"green","small":""}},[_vm._v(" mdi-check ")]):_vm._e()]}}],null,true),model:{value:(moduleArea.serviceSelected),callback:function ($$v) {_vm.$set(moduleArea, "serviceSelected", $$v)},expression:"moduleArea.serviceSelected"}})],1),_c('VCol',{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('v-select',{attrs:{"items":moduleArea.scopesList &&
                              moduleArea.scopesList.length
                              ? moduleArea.scopesList
                              : [],"label":"Rol","item-text":"description","no-data-text":"Sin datos","dense":"","return-object":"","clearable":"","outlined":"","loading":moduleArea.loading,"disabled":moduleArea.loading,"rules":_vm.textRules},on:{"click":function($event){return $event.stopPropagation()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.description)+" "),(data.item.disabled)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"green","small":""}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.description)+" "),(data.item.disabled)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"green","small":""}},[_vm._v(" mdi-check ")]):_vm._e()]}}],null,true),model:{value:(moduleArea.scopeSelected),callback:function ($$v) {_vm.$set(moduleArea, "scopeSelected", $$v)},expression:"moduleArea.scopeSelected"}})],1),_c('VCol',{staticClass:"d-flex justify-end align-center d-md-block",attrs:{"cols":"12","md":"2","lg":"4"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleClickAdd(
                              moduleArea,
                              $event,
                              `services${moduleArea.name}${key}`
                            )}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),_c('VCol',{staticClass:"pt-0",attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-data-table',{staticClass:"elevation-4 row-height-dense",attrs:{"no-data-text":"No se encontraron resultados","no-results-text":"No se encontraron resultados","dense":"","headers":[
                            { text: 'Servicio', value: 'service.name' },
                            { text: 'Rol', value: 'scopes' },
                            {
                              text: '',
                              value: 'actions',
                              cellClass: 'text-end',
                            },
                          ],"items":Object.values(moduleArea.accesses),"item-key":"key","hide-default-footer":"","loading-text":"Cargando","loading":moduleArea.loading},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{staticClass:"text-none font-weight-regular text-caption",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.handleDel(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)}):_c('VCol',{attrs:{"cols":"11"}},[_vm._v(" No existen módulos para el área ")])],2)],1)}):_vm._e()],2),(_vm.actions && _vm.actions.length)?_c('VRow',{staticClass:"custom-footer",attrs:{"justify":"center"}},[_c('VCol',{staticClass:"d-flex align-center pb-6 pt-12",attrs:{"cols":"11"}},[_c('VRow',{staticStyle:{"width":"100%"},attrs:{"justify":"space-between"}},_vm._l((_vm.actions),function(action,index){return _c('VCol',{key:`action-${index}`,staticClass:"pb-1",attrs:{"cols":"12","md":action.col ? action.col : 'auto'}},[_c('custom-btn',{ref:"button",refInFor:true,attrs:{"attributes":_vm.toAttributes(action.style),"custom-class":"text-none","loading":_vm.loading || !_vm.loaded,"label":action.label,"tooltip":action.title},on:{"handleClick":function($event){return _vm.handleClick(action)}}})],1)}),1)],1)],1):_vm._e(),_c('confirm-modal',{attrs:{"open":_vm.confirmModal,"text":_vm.confirmModalText,"is-overlay":_vm.confirmModalLoading},on:{"update:open":function($event){_vm.confirmModal=$event},"confirm":_vm.confirmModalAction}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }