// Utilities
import { make } from 'vuex-pathify';
import { setToken, getToken,removeToken } from '@/util/auth';
import { genericRequest } from '@/api/modules';
const mockModulesAdmin = require('./mocks/modules/admin.json');
// const mockModulesTurismo= require('./mocks/modules/turismo.json');
const defaultAvatar = require('@/assets/avatar.png');
import LocalStorageService from '@/services/localStorageService';
import { getTokenData } from '@/util/helpers';
import router from '@/router';

const state = {
	dark:
		(getToken('ssp_dark_mode') || process.env.VUE_APP_DARK_MODE_DEFAULT) &&
		String(process.env.VUE_APP_BTN_DARK_MODE) === 'true',
	scopes: [],
	defaultAvatar,
	firstname: '',
	lastname: '',
	dataUser:{},
	currentModule:{}
};

const mutations = make.mutations(state);
const getters = {
	isLogged() {
		return 	!!getToken()?.access_token;
	}
};

const actions = {
	// get user info
	async getUserInfo({ state,commit },token) {
		try {
			if (token?.access_token) {
				const scopesData=getTokenData(token.access_token);

				if(scopesData?.scopes){
					commit('SET_SCOPES', scopesData.scopes);
				}
			}
			
			const data = await genericRequest({ url: 'profile', method: 'GET' });
			const {
				resource: { groups, user }
			} = data;

			let modulesGroup = [];

			if(state.scopes.includes('rbac-admin')){
				const _mockModulesAdmin=mockModulesAdmin.map(element => {
					return {...element,services:[{name:'rbac', url:process.env.VUE_APP_BASE_API_RBAC}]};
				});
				modulesGroup=_mockModulesAdmin;
			}

			window.localStorage.setItem(
				'ssp_user',
				JSON.stringify(user)
			);

			groups.forEach((group) => {
				const {name,uuid}=group;
				Object.values(group.modules).forEach((val) => {
					let functionalities = [];
					if (val?.functionalities) {
						functionalities=Object.values(val.functionalities).map(functionality => functionality.reference);
					}

					val.functionalitiesList = functionalities;

					val.group={name,uuid};
					modulesGroup.push(val);
				});
				return true;
			});

			// modulesGroup=[...modulesGroup,...mockModulesTurismo];
			await LocalStorageService.setModules(modulesGroup);
		} catch(e) {
			console.log(e);
		}
	},
	async getModules({ state }) {
		const modulesList = await LocalStorageService.getModules();
		const isRbacAdmin = state.scopes?.length && state.scopes.includes('rbac-admin');
		if (!isRbacAdmin && modulesList?.length) {
			return modulesList.filter((element) => { return element.scope !== 'rbac-admin'; });
		}
		return modulesList || [];
	},

	async setCurrentModule({ commit },data) {
		await LocalStorageService.setCurrentModule(data);
		commit('SET_CURRENT_MODULE', data);
		
		return true;
	},
	async getCurrentModule({  }) {
		return await LocalStorageService.getCurrentModule();
	},
	
	changeUserInfo({ commit }, data) {
		window.localStorage.setItem(
			'ssp_user',
			JSON.stringify(data)
		);
		commit('SET_DATA_USER', data);
	},
	getUser({ commit }) {
		let sspUser = localStorage.getItem('ssp_user');
		if (sspUser) {
			sspUser = JSON.parse(sspUser);
			commit('SET_DATA_USER', sspUser);
		} else {
			sspUser = {};
		}
		return sspUser;
	},
	changeDark({ commit, state }) {
		commit('SET_DARK', !state.dark);
		setToken(state.dark, 'ssp_dark_mode');
	},
	async login({ }, data) {
		await setToken(data);
	},
	async removeToken({rootState}) {
		await removeToken();
		rootState.app.constants={};
		window.localStorage.clear();
	},
	async logout({dispatch}) {
		await genericRequest({ url: 'logout', method: 'POST' });
		dispatch('removeToken');
	},
	async checkTokenStatus({dispatch}) {

		const data = await genericRequest({
			url: 'validate-token',
			method: 'POST'
		});

		if (data?.refresh) {
			await setToken(data);
		}
		const success=data?.success;
		if(success){
			return data?.success;
		}
		await dispatch('logout');
		router.push({ path: '/ingresar' }).catch((e) => { console.log(e); });
		return false;
	
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
