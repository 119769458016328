// Pathify
import { make } from 'vuex-pathify';
import { genericRequest } from '@/api/modules';

// Data
const state = {
	drawer: null,
	mini: String(process.env.VUE_APP_DRAWER_MINI) === 'true',
	darkMode: String(process.env.VUE_APP_BTN_DARK_MODE) === 'true',
	goHome: String(process.env.VUE_APP_BTN_GO_HOME) === 'true',
	loading: false,
	alert: false,
	alertTxt: { timeout: 500, type: 'success', txt: 'Cambios guardados' },
	modalChangePassword: false,
	customTitle: null,
	drawerComentarios: false,
	drawerBlocks: false,
	constants:{ }
};

const mutations = make.mutations(state);

const customMutations={
	SET_CONSTANTS(state, { service,resources }) {
		if(service){
			state.constants[service] = resources;
		}else{
			state.constants = {};
		}
		
	},
};

const getters = {
	constants(state) {
		return 	state.constants;
	}
};
const actions = {
	...make.actions(state),

	async getConstants({ commit},service) {
		
		const {resources}= await genericRequest({
			url: 'constants',
			method: 'get',
			service
		});
		commit('SET_CONSTANTS', { service,resources });
	},

};

export default {
	namespaced: true,
	state,
	mutations:{...mutations,...customMutations},
	actions,
	getters
};
