// Vue
import Vue from 'vue';
import Vuex from 'vuex';
import pathify from './vuex-pathify';

// Modules
import * as modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules,
	plugins: [pathify.plugin]
});

export default store;

export const ROOT_DISPATCH = Object.freeze({ root: true });
