/* eslint-disable no-unused-vars */
/**
 *
 * @author Héctor Gabriel Alegre <gabriel.h.alegre@gmail.com>
 * @date 6/10/2020
 *
 */
import rules from '@/util/rules';
import store from '@/store';
import { getConstant } from '@/util/constantsHelpers';
import { isSet } from 'lodash';

export const toFullVuetifyField = (field) => {
	const isText =
		field.includes('string') ||
		field.includes('password') ||
		field.includes('email') ||
		field.includes('url') ||
		field.includes('number') ||
		field.includes('otp');

	const isDate = field.includes('date');

	let component = field;
	let counter = null;
	let counterMin = null;
	let type = null;

	if (isText) {
		type = !field.includes('password') ? 'string' : field;
		counter = field.split(':')[1] ? Number(field.split(':')[1]) : null;
		const counterMax = field.split(':')[2] ? Number(field.split(':')[2]) : null;
		if (counterMax) {
			counterMin = counter;
			counter = counterMax;
		}
		component = field.includes('otp') ? 'otp' : 'textField';
	}

	let formatDate = 'date';
	if (isDate) {
		const dateType = field.split(':')[1];
		if (dateType && dateType !== '') {
			formatDate = 'month';
		}
		component = 'date';
	}

	const FIELDS = {
		'radio-group': { element: 'v-radio-group', subElement: 'v-radio' },
		text: { element: 'v-textarea', subElement: false, counter, counterMin },
		textField: {
			element: 'v-text-field',
			subElement: false,
			counter,
			counterMin,
			type
		},
		textarea: {
			element: 'v-textarea',
			subElement: false,
			counter,
			counterMin,
			type
		},
		chip: {
			element: 'v-chip',
			subElement: false,
			isArray: false,
			items: false
		},
		'checkbox-group': {
			element: 'v-checkbox',
			subElement: false,
			isArray: true
		},
		select: {
			element: 'v-select',
			subElement: false,
			isArray: false,
			items: true
		},
		search: {
			element: 'v-autocomplete',
			subElement: false,
			isArray: false,
			items: true
		},
		selectSearch: {
			element: 'select-search',
			subElement: false,
			isArray: false,
			items: true
		},
		apiSearch: {
			element: 'api-search',
			subElement: false,
			isArray: false,
			items: false
		},
		file: {
			element: 'upload-file',
			subElement: false,
			isArray: false,
			items: true
		},
		editBrowse: { element: 'custom-input-edit-browse', subElement: false },
		historyBrowse: { element: 'custom-input-history-browse', subElement: false },
		browse: { element: 'custom-input-browse', subElement: false },
		date: { element: 'date-picker', subElement: false, formatDate },
		time: { element: 'time-picker', subElement: false },
		label: { element: 'div', subElement: false },
		switch: { element: 'v-switch', subElement: false },
		color: { element: 'custom-input-color', subElement: false },
		checkbox: { element: 'v-checkbox', subElement: false },
		disabledInput: { element: 'disabled-input', subElement: false },
		customLabel: { element: 'custom-label', subElement: false },
		disabledPhoto: { element: 'disabled-photo', subElement: false },
		fileDownload: { element: 'file-download', subElement: false },
		btnDownloadFile: { element: 'btn-download-file', subElement: false },
		benefitSelector: { element: 'custom-benefit-selector', subElement: false, items: true },
		benefitTypeSelector: { element: 'custom-benefit-type-selector', subElement: false, items: true },
		list: { element: 'custom-list' },
		otp: { element: 'v-otp-input', subElement: false, counter, type: 'otp' },
		boolean: { type: 'boolean' },
		link: { element: 'custom-link' },
		editBrowseLink: { element: 'custom-input-edit-browse-link', subElement: false },
	};

	return FIELDS[component];
};

export const toCounter = (field) => {
	const isText =
		field.includes('string') ||
		field.includes('password') ||
		field.includes('email') ||
		field.includes('url') ||
		field.includes('number') ||
		field.includes('otp');

	let counter = false;
	if (isText) {
		counter = field.split(':')[1] ? Number(field.split(':')[1]) : false;
		const counterMax = field.split(':')[2] ? Number(field.split(':')[2]) : null;
		if (counterMax) {
			counter = counterMax;
		}
	}

	return counter;
};

export const toVuetifyField = (field) => {

	const isText =
		field &&
		(field.includes('string') ||
			field.includes('password') ||
			field.includes('email') ||
			field.includes('url') ||
			field.includes('number'));

	const isDate = field.includes('date');

	let component = field || 'div';
	if (isText) {
		component = 'textField';
	}

	if (isDate) {
		component = 'date';
	}

	if (field.includes('otp')) {
		component = 'otp';
	}

	const FIELDS = {
		textField: 'v-text-field',
		textarea: 'v-textarea',
		color: 'custom-input-color',
		chip: 'v-chip',
		select: 'v-select',
		search: 'v-autocomplete',
		apiSearch: 'api-search',
		date: 'date-picker',
		editBrowse: 'custom-input-edit-browse',
		historyBrowse: 'custom-input-history-browse',
		browse: 'custom-input-browse',
		file: 'upload-file',
		switch: 'v-switch',
		disabledInput: 'disabled-input',
		customLabel: 'custom-label',
		disabledPhoto: 'disabled-photo',
		fileDownload: 'file-download',
		checkbox: 'checkbox',
		btnDownloadFile: 'btn-download-file',
		benefitSelector: 'custom-benefit-selector',
		benefitTypeSelector: 'custom-benefit-type-selector',
		list: 'custom-list',
		otp: 'v-otp-input',
		time: 'time-picker',
		link: 'custom-link',
		editBrowseLink: 'custom-input-edit-browse-link',
		selectSearch: 'select-search'
	};

	return FIELDS[component];
};

export const toAttributes = (data) => {
	const response = {};

	if (data?.attributes?.length) {
		data.attributes.forEach((element) => {
			const elements = element ? element.split(':') : [];
			response[elements[0]] = elements[1] ? elements[1] : true;
		});
	} else if (data?.length) {
		data.forEach((element) => {
			const elements = element ? element.split(':') : [];
			response[elements[0]] = elements[1] ? elements[1] : true;
		});
	} else if (data) {
		if (data.icon) {
			response['prepend-icon'] = data.icon;
		}
		if (data.type) {
			response['color'] = data.type;
		}
	}

	return response;
};

export const toType = (field) => {
	const isText =
		field.includes('string') ||
		field.includes('email') ||
		field.includes('url') ||
		field.includes('number');

	let type = field;

	if (isText) {
		type = 'text';
	}

	return type;
};

export const toVuetifyValidate = (field, dataModel) => {
	const vuetifyField = toFullVuetifyField(field.type);
	const rulesMapped = [];
	if (String(field.required) === 'true') {
		rulesMapped.push(rules('required'));
	}
	if (vuetifyField?.counter && Number(vuetifyField.counter)) {
		rulesMapped.push((value) => {
			if (!value && String(field.required) !== 'true') {
				return true; // Si no es requerido y está vacío, pasa la validación
			}

			if (vuetifyField?.counterMin && Number(vuetifyField.counterMin)) {
				if (Number(vuetifyField.counterMin) === Number(vuetifyField.counter)) {
					return rules('exactly', Number(vuetifyField.counterMin))(value);
				} else {
					const minValidation = rules('min', Number(vuetifyField.counterMin))(value);
					const maxValidation = rules('max', Number(vuetifyField.counter))(value);
					return minValidation === true && maxValidation === true ? true : minValidation || maxValidation;
				}
			} else {
				return rules('max', Number(vuetifyField.counter))(value);
			}
		});
	}
	if (field.type === 'url') {
		rulesMapped.push((value) => {
			if (!value && String(field.required) !== 'true') {
				return true; // Si no es requerido y está vacío, pasa la validación
			}
			return rules('url')(value); // Si tiene un valor, aplica la validación de email
		});
	}
	if (field.type === 'email') {
		rulesMapped.push((value) => {
			if (!value && String(field.required) !== 'true') {
				return true; // Si no es requerido y está vacío, pasa la validación
			}
			return rules('email')(value); // Si tiene un valor, aplica la validación de email
		});
	}

	if (field.type === 'number') {
		rulesMapped.push(rules('number'));
	}
	if (String(field.required) === 'true' && String(field.multiple) === 'true') {
		rulesMapped.push(rules('array'));
	}

	if (field?.customValidations?.length) {
		field.customValidations.forEach((element) => {
			rulesMapped.push(
				rules(element.validation, element, dataModel, field)
			);
		});
	}

	return rulesMapped;
};

export const paramMapped = (params, dataToSend, dataForm = [], originalData, submit) => {
	const response = {};
	const data = { ...dataToSend, ...store.state.route.query };
	if (params) {
		const entries = Object.entries(params);
		entries.forEach(async (element) => {
			const field = dataForm.find(e => e.name === element[1]);
			if (!data[element[1]]) {
				if (field && 'falseValue' in field) {
					data[element[1]] = field.falseValue;
				} else if (field && 'constant' in field) {
					// Parse the decoded value as JSON if possible
					try {
						let valueJson = field.constant;

						if (typeof field.constant === 'string' || field.constant instanceof String) {
							const replacedString = field.constant.replace(/'/g, '"');
							valueJson = JSON.parse(replacedString);
						}

						if (valueJson?.field) {
							const { field, service, keyToReturn } = valueJson;
							valueJson = getConstant({ field, service, keyToReturn });
						}
						data[element[1]] = valueJson;
					} catch (error) {
						console.log(error);
						// If parsing fails, store the decoded value as a string
						data[element[1]] = '';
					}

				} else if (field && 'object' in field) {
					data[element[1]] = { [field.object]: String(field?.multiple) ? [] : '' };
				} else {
					data[element[1]] = '';
				}
			}
			if (element[0] === '_method' || element[0] === 'token' || !originalData?.[element[0]] || (originalData?.[element[0]] && data[element[1]] !== originalData[element[0]]) || String(field.hidden) === 'true' || String(field.sendData) === 'true') {
				if (data[element[1]] || submit?.method !== 'POST') {
					response[element[0]] = data[element[1]];
				}
			}
		});
	} else {
		const entries = Object.keys(dataForm);
		entries.forEach(async (element) => {

			if (!data[dataForm[element].name]) {
				if ('falseValue' in dataForm[element]) {
					data[dataForm[element].name] = dataForm[element].falseValue;
				} else if ('constant' in dataForm[element]) {

					try {
						let valueJson = dataForm[element].constant;

						if (typeof dataForm[element].constant === 'string' || dataForm[element].constant instanceof String) {
							const replacedString = dataForm[element].constant.replace(/'/g, '"');
							valueJson = JSON.parse(replacedString);
						}

						if (valueJson?.field) {
							const { field, service, keyToReturn } = valueJson;
							valueJson = getConstant({ field, service, keyToReturn });
						}
						data[dataForm[element].name] = valueJson;
					} catch (error) {
						console.log(error);
						// If parsing fails, store the decoded value as a string
						data[dataForm[element].name] = '';
					}


				} else if ('object' in dataForm[element]) {
					data[dataForm[element].name] = { [dataForm[element].object]: String(dataForm[element]?.multiple) ? [] : null };
				} else {
					data[dataForm[element].name] = '';
				}
			}
			if (dataForm[element].name === '_method' || dataForm[element].name === 'token' || !originalData[dataForm[element].name] || (originalData[dataForm[element].name] && data[dataForm[element].name] !== originalData[dataForm[element].name]) || String(dataForm[element].hidden) === 'true' || String(dataForm[element].sendData) === 'true') {
				if (data[dataForm[element].name] || submit?.method !== 'POST') {
					response[dataForm[element].name] = data[dataForm[element].name];
				}
			}
		});
	}

	return response;
};
