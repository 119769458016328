<template>
  <div>
    <VRow class="" justify="center">
      <VCol cols="12" md="6">
        <VForm ref="users-group" class="" :disabled="loading">
          <VRow class="" justify="center">
            <VCol cols="8">
              <VAutocomplete
                v-model="user"
                :items="usersList"
                label="Usuarios"
                item-text="firstname"
                item-value="uuid"
                no-data-text="Sin datos"
                dense
                outlined
                :rules="textRules"
                :filter="customFilter"
              >
                <template #selection="data">
                  {{ data.item.firstname }} {{ data.item.lastname }}
                  <v-icon
                    v-if="data.item.disabled"
                    color="green"
                    small
                    class="ml-2"
                  >
                    mdi-check
                  </v-icon>
                </template>

                <template #item="data">
                  {{ data.item.firstname }} {{ data.item.lastname }}
                  <v-icon
                    v-if="data.item.disabled"
                    color="green"
                    small
                    class="ml-2"
                  >
                    mdi-check
                  </v-icon>
                </template>
              </VAutocomplete>
            </VCol>
            <VCol cols="auto">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" icon v-on="on" @click="handleClickAdd">
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Agregar usuario</span>
              </v-tooltip>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
      <VCol cols="12" md="8" class="pt-0">
        <simple-table
          :form="{ headers }"
          :items="usersGroup"
          :loading="loadingTable || !loaded || loading"
          item-key="uuid"
          @delete="deleteUser"
        />
      </VCol>
    </VRow>
    <VRow
      v-if="actions && actions.length"
      class="custom-footer"
      justify="center"
    >
      <VCol class="d-flex align-center pb-6 pt-12" cols="11">
        <VRow justify="space-between" style="width: 100%">
          <VCol
            v-for="(action, index) in actions"
            :key="`action-${index}`"
            cols="12"
            :md="action.col ? action.col : 'auto'"
            class="pb-1"
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              custom-class="text-none"
              :loading="loading || !loaded"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="handleClick(action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { genericRequest } from '@/api/modules';
import { toAttributes } from '@/mappers/form';
export default {
	name: 'EditUserGroup',
	props: {
		form: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	data() {
		return {
			toAttributes,
			loaded: false,
			loading: true,
			loadingTable: true,
			users: [],
			user: null,
			textRules: [(v) => !!v || 'Campo requerido'],
			headers: [
				{ text: 'Nombre', value: 'firstname' },
				{ text: 'Apellido', value: 'lastname' },
				{ text: 'Email', value: 'email' },
				{
					text: '',
					value: 'actions',
					cellClass: 'text-end',
					data: [
						{
							title: 'Eliminar usuario',
							confirmation: {
								message: 'Vas a borrar el usuario {firstname} {lastname}',
							},
							style: {
								attributes: [     
									'small',
									'icon',
									'prepend-icon:delete-outline',
									'color:danger'
								],
							},
							event: {
								action: 'delete',
							},
							col: '12',
						},
					],
				},
			],
			usersGroup: [],
		};
	},
	computed: {
		meta: get('route/meta'),
		params: get('route/params'),
		customTitle: sync('app/customTitle'),
		id() {
			return this.params?.id;
		},
		actions(){
			return this.form?.steps?.[0]?.actions || this.form.actions;
		},
		usersList() {
			const users = this.users;
			return users.map((user) => {
				return {
					...user,
					disabled: !!this.usersGroup.find((userGroup) => {
						return userGroup?.uuid === user.uuid;
					}),
				};
			});
		},
	},
	mounted() {
		this.getData();
		this.loading = false;
	},
	methods: {
		customFilter(item, queryText) {
			const textOne = item.firstname.toLowerCase();
			const textTwo = item.lastname.toLowerCase();
			const searchText = queryText.toLowerCase();
			return (
				textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
			);
		},
		async getData() {
			await this.getUsers();
			await this.getGroup();
			this.loaded = true;
		},
		async getUsers() {
			const { resources } = await genericRequest({
				url: '/users?per_page=200',
				method: 'get',
			});
			if (resources?.length) {
				this.users = resources;
			}
		},

		async getGroup() {
			this.loadingTable = true;
			const { resource } = await genericRequest({
				url: `groups/${this.id}`,
				method: 'get',
			});
			this.customTitle = resource.name;
			this.usersGroup = resource?.users?.length ? resource.users : [];
			this.loadingTable = false;
		},
		async deleteUser(data) {
			if (data?.item?.uuid) {
				this.loading = true;
				await genericRequest({
					url: `/groups/${this.id}/users/${data.item.uuid}`,
					method: 'delete',
				});

				this.$refs['users-group'].reset();
				this.loading = false;
				this.getGroup();
			}
		},

		async handleClickAdd() {
			if (this.$refs?.['users-group'].validate()) {
				this.loading = true;
				await genericRequest({
					url: `/groups/${this.id}/users`,
					method: 'post',
					params: { user_uuid: this.user },
				});

				this.$refs['users-group'].reset();
				this.loading = false;
				this.getGroup();
			}
		},
		handleClick(action) {
			if (!action?.submit && action?.redirect?.href) {
				if (action?.redirect?.service === 'local') {
					this.$router
						.push({ path: action.redirect.href || '/' })
						.catch((error) => {
							console.log(error.message);
						});
				}
			} else if (action.event?.action) {
				if (action.event.reset) {
					this.$refs['users-group'].reset();
				}

				this.$emit(action.event.action, action);
			}
		},
	},
};
</script>

<style>
.v-data-table.row-height-dense td {
  height: 30px !important;
}
</style>
