<template>
  <div>
    <div class="text-headline">
      {{ label }}
    </div>
    <div>
      <v-btn
        text
        :href="linkValue"
        color="blue"
        class="text-headline font-weight-negrita text-none pa-0"
      >
        {{ textValue }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { generateStringFromVariables } from '@/util/helpers';
export default {
	name: 'CustomLink',
	props: {
		value: {
			type: [Number, String, Boolean, Array,Object],
			default: function () {
				return '';
			}
		},
		field: {
			required: true,
			type: Object,
			default: function () {
				return {};
			}
		},
		label: {
			type: [String, null],
			default: ''
		},

		model: {
			type: Object,
			default: function () {
				return {};
			}
		}

	},
	data () {
		return {

		};
	},
	computed: {
		textValue () {
			let txt='';

			if (this.field?.data?.label) {
				txt = generateStringFromVariables({ title: this.field.data.label, data: this.value });
			}

			return txt;
		},
		linkValue () {
			let txt='';

			if (this.field?.data?.link) {
				txt = generateStringFromVariables({ title: this.field.data.link, data: this.value });
			}

			return txt;
		}
	},
	methods: {

	}
};

</script>

<style>

</style>
