
export default (data = { }) => {
	const items = [{
		text: [
			{ text: 'Prestación: ', fontSize: 11, bold: true },
			{ text: data.benefit_subtype.name, fontSize: 11, bold: false }
		],
		margin: [0, 5, 0, 10],
		color: '#000000'
	}];

	return items;
};
